import {
    PLACE_BASKET_ORDER,
    PLACE_BASKET_ORDER_FAIL,
    PROCESS_PLACE_BASKET_ORDER,
    RESET_PLACE_BASKET_ORDER,
    PLACE_CART_ORDER,
    PLACE_CART_ORDER_FAIL,
    PROCESS_PLACE_CART_ORDER,
    RESET_PLACE_CART_ORDER,
    ORDER_LIST,
    ORDER_LIST_FAIL,
    RESET_ORDER_LIST,
    PROCESS_ORDER_LIST,
    ORDER_DETAIL,
    ORDER_DETAIL_FAIL,
    RESET_ORDER_DETAIL,
    PROCESS_ORDER_DETAIL,
    INVALID_API_URL,
    RESET_INVALID_API_URL,
    PAGE_NOT_FOUND,
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
    _placeBasketOrderProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _placeCartOrderProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _orderListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _orderDetailProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
}
export default function placeOrder(state = initialState, action) {
    switch (action.type) {
        case PLACE_BASKET_ORDER:
            return {
                ...state,
                _placeBasketOrderProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case PLACE_BASKET_ORDER_FAIL:
            return {
                ...state,
                _placeBasketOrderProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_PLACE_BASKET_ORDER:
            return {
                ...state,
                _placeBasketOrderProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_PLACE_BASKET_ORDER:
            return {
                ...state,
                _placeBasketOrderProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case PLACE_CART_ORDER:
            return {
                ...state,
                _placeCartOrderProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case PLACE_CART_ORDER_FAIL:
            return {
                ...state,
                _placeCartOrderProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_PLACE_CART_ORDER:
            return {
                ...state,
                _placeCartOrderProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_PLACE_CART_ORDER:
            return {
                ...state,
                _placeCartOrderProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case ORDER_LIST:
            return {
                ...state,
                _orderListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case ORDER_LIST_FAIL:
            return {
                ...state,
                _orderListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_ORDER_LIST:
            return {
                ...state,
                _orderListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_ORDER_LIST:
            return {
                ...state,
                _orderListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case ORDER_DETAIL:
            return {
                ...state,
                _orderDetailProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case ORDER_DETAIL_FAIL:
            return {
                ...state,
                _orderDetailProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_ORDER_DETAIL:
            return {
                ...state,
                _orderDetailProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_ORDER_DETAIL:
            return {
                ...state,
                _orderDetailProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case PAGE_NOT_FOUND: {
            return {
                ...state,
                _orderDetailProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}