import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
	UPDATE_SUCCESS_MESSAGE,
	DELETE_ASSEET_MESSAGE,
	ADD_PROPERTY_INQUIRY_MESSAGE,
	DELETE_PROPERTY_INQUIRY_MESSAGE,
	UPDATE_PROPERTY_INQUIRY_MESSAGE,
	DELETE_ASSEET_IMAGE_MESSAGE
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';
const initialState = {
	reLoad: false,
	reLoadproperty: false,
	reLoadFavouriteAsset: false,
	progressCount: 0,
	_addAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchIndividualAssetPropertyProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndividualAssetPropertyProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndividualAssetDetailProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndividualAssetLocationProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_deleteAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndividualAssetIsPublicProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchIndividualAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndividualAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchBriefAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchAssetProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchAssetsListProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchDealListFromAssetsProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_deleteDealFromAssetsProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_addAssetImageProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchAssetImagesProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_deleteAssetImageProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_addDefaultAssetsImageProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchSearchedAssetsProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchPublicAssetDetailsProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchFavouriteAssetsProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateAgricultureAmenities: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateResidentialAmenities: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateCommercialAmenities: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndustrialAmenities: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateAgricultureFeatures: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateResidentialFeatures: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateCommercialFeatures: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateIndustrialFeatures: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_addAssetInquiryProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_fetchAssetInquiries: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_updateAssetInquiryProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_deleteAssetInquiryProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_invalidToken: {
		status: ProcessTypes.IDLE,
		data: {}
	},
	_invalidApiUrl: {
		status: ProcessTypes.IDLE,
		data: {}
	},
};

export default function assets(state = initialState, action) {
	switch (action.type) {
		case CallerConstants.ADD_ASSETS:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				reLoad: true,
				reLoadproperty: true,
				_addAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.ADD_ASSETS_FAIL:
			return {
				...state,
				_addAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_ADD_ASSETS:
			return {
				...state,
				_addAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.PROCESS_ADD_ASSETS:
			return {
				...state,
				_addAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.FETCH_INDIVIDUAL_ASSETS_PROPERTY:
			return {
				...state,
				_fetchIndividualAssetPropertyProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_INDIVIDUAL_ASSETS_PROPERTY_FAIL:
			return {
				...state,
				_fetchIndividualAssetPropertyProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_INDIVIDUAL_ASSETS_PROPERTY:
			return {
				...state,
				_fetchIndividualAssetPropertyProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_INDIVIDUAL_ASSETS_PROPERTY:
			return {
				...state,
				_fetchIndividualAssetPropertyProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_PROPERTY:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateIndividualAssetPropertyProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_PROPERTY_FAIL:
			return {
				...state,
				_updateIndividualAssetPropertyProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_INDIVIDUAL_ASSETS_PROPERTY:
			return {
				...state,
				_updateIndividualAssetPropertyProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_ASSETS_PROPERTY:
			return {
				...state,
				_updateIndividualAssetPropertyProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC: {
			if (state._fetchBriefAssetProcess.data.features !== undefined) {
				state._fetchBriefAssetProcess.data.features.map((data, index) => {
					if (data.id === action.payload.id) {
						state._fetchBriefAssetProcess.data.features[index].properties.is_public = action.payload.is_public;
					}
					return null;
				});
			}
			return {
				...state,
				_updateIndividualAssetIsPublicProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				},
			};
		}
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC_FAIL:
			return {
				...state,
				_updateIndividualAssetIsPublicProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC:
			return {
				...state,
				_updateIndividualAssetIsPublicProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC:
			return {
				...state,
				_updateIndividualAssetIsPublicProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};


		case CallerConstants.DELETE_ASSETS:
			toast.success(DELETE_ASSEET_MESSAGE);
			return {
				...state,
				reLoad: true,
				reLoadproperty: true,
				_deleteAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.DELETE_ASSETS_FAIL:
			return {
				...state,
				_deleteAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_DELETE_ASSETS:
			return {
				...state,
				_deleteAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_DELETE_ASSETS:
			return {
				...state,
				_deleteAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.FETCH_INDIVIDUAL_ASSETS:
			return {
				...state,
				_fetchIndividualAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_INDIVIDUAL_ASSETS_FAIL:
			return {
				...state,
				_fetchIndividualAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_INDIVIDUAL_ASSETS:
			return {
				...state,
				_fetchIndividualAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_INDIVIDUAL_ASSETS:
			return {
				...state,
				_fetchIndividualAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS:
			return {
				...state,
				reLoad: true,
				reLoadproperty: true,
				_updateIndividualAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_FAIL:
			return {
				...state,
				_updateIndividualAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_INDIVIDUAL_ASSETS:
			return {
				...state,
				_updateIndividualAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_ASSETS:
			return {
				...state,
				_updateIndividualAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_DETAIL:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateIndividualAssetDetailProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_DETAIL_FAIL:
			return {
				...state,
				_updateIndividualAssetDetailProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_INDIVIDUAL_ASSETS_DETAIL:
			return {
				...state,
				_updateIndividualAssetDetailProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_ASSETS_DETAIL:
			return {
				...state,
				_updateIndividualAssetDetailProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_LOCATION:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateIndividualAssetLocationProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_INDIVIDUAL_ASSETS_LOCATION_FAIL:
			return {
				...state,
				_updateIndividualAssetLocationProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_INDIVIDUAL_ASSETS_LOCATION:
			return {
				...state,
				_updateIndividualAssetLocationProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_ASSETS_LOCATION:
			return {
				...state,
				_updateIndividualAssetLocationProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.FETCH_BRIEF_ASSETS:
			return {
				...state,
				reLoad: false,
				_fetchBriefAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_BRIEF_ASSETS_FAIL:
			return {
				...state,
				_fetchBriefAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_BRIEF_ASSETS:
			return {
				...state,
				_fetchBriefAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_BRIEF_ASSETS:
			return {
				...state,
				_fetchBriefAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.FETCH_ASSETS:
			return {
				...state,
				_fetchAssetProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_ASSETS_FAIL:
			return {
				...state,
				_fetchAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_ASSETS:
			return {
				...state,
				_fetchAssetProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_ASSETS:
			return {
				...state,
				_fetchAssetProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.FETCH_ASSETS_LIST:
			return {
				...state,
				_fetchAssetsListProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_ASSETS_LIST_FAIL:
			return {
				...state,
				_fetchAssetsListProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_ASSETS_LIST:
			return {
				...state,
				_fetchAssetsListProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_ASSETS_LIST:
			return {
				...state,
				_fetchAssetsListProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.FETCH_ASSETS_DEALS:
			return {
				...state,
				_fetchDealListFromAssetsProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.FETCH_ASSETS_DEALS_FAIL:
			return {
				...state,
				_fetchDealListFromAssetsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_FETCH_ASSETS_DEALS:
			return {
				...state,
				_fetchDealListFromAssetsProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_ASSETS_DEALS:
			return {
				...state,
				_fetchDealListFromAssetsProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.DELETE_ASSETS_DEAL:
			return {
				...state,
				_deleteDealFromAssetsProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				},
			};
		case CallerConstants.DELETE_ASSETS_DEAL_FAIL:
			return {
				...state,
				_deleteDealFromAssetsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_DELETE_ASSETS_DEAL:
			return {
				...state,
				_deleteDealFromAssetsProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_DELETE_ASSETS_DEAL:
			return {
				...state,
				_deleteDealFromAssetsProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPLOAD_ASSET_IMAGE: {
			return {
				...state,
				_addAssetImageProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.UPLOAD_ASSET_IMAGE_FAIL: {
			return {
				...state,
				_addAssetImageProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RESET_UPLOAD_ASSET_IMAGE: {
			return {
				...state,
				_addAssetImageProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		}
		case CallerConstants.PROCESS_ADD_ASSETS_IMAGE:
			return {
				...state,
				_addAssetImageProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPLOAD_DEFAULT_ASSET_IMAGE:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_addDefaultAssetsImageProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPLOAD_DEFAULT_ASSET_IMAGE_FAIL:
			return {
				...state,
				_addDefaultAssetsImageProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPLOAD_DEFAULT_ASSET_IMAGE:
			return {
				...state,
				_addDefaultAssetsImageProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_ADD_ASSETS_DEFAULT_IMAGE:
			return {
				...state,
				_addDefaultAssetsImageProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.FETCH_ASSET_IMAGES:
			return {
				...state,
				_fetchAssetImagesProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};

		case CallerConstants.FETCH_ASSET_IMAGES_FAIL:
			return {
				...state,
				_fetchAssetImagesProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};

		case CallerConstants.RESET_FETCH_ASSET_IMAGES:
			return {
				...state,
				_fetchAssetImagesProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_FETCH_ASSET_IMAGES:
			return {
				...state,
				_fetchAssetImagesProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.DELETE_ASSET_IMAGE: {
			toast.success(DELETE_ASSEET_IMAGE_MESSAGE);
			return {
				...state,
				reLoad: true,
				_deleteAssetImageProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				},
			};
		}
		case CallerConstants.DELETE_ASSET_IMAGE_FAIL: {
			return {
				...state,
				_deleteAssetImageProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RESET_DELETE_ASSET_IMAGE: {
			return {
				...state,
				_deleteAssetImageProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.PROCESS_DELETE_ASSET_IMAGE:
			return {
				...state,
				_deleteAssetImageProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.PUBLIC_ASSETS_SEARCH: {
			return {
				...state,
				reLoadproperty: false,
				_fetchSearchedAssetsProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.PUBLIC_ASSETS_SEARCH_FAIL: {
			return {
				...state,
				_fetchSearchedAssetsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RESET_PUBLIC_ASSETS_SEARCH: {
			return {
				...state,
				_fetchSearchedAssetsProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.PROCESS_PUBLIC_ASSETS_SEARCH:
			return {
				...state,
				_fetchSearchedAssetsProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.UPDATE_FAVOURITE_ASSET:
			if (state._fetchSearchedAssetsProcess.data.results !== undefined)
				state._fetchSearchedAssetsProcess.data.results.map(data => {
					if (data.id.toString() === action.payload.id.toString()) {
						if (action.payload.response === 204) {
							data.starred = false;
						}
						else if (action.payload.response === 200) {
							data.starred = true;
						}
					}
					return null
				})
			if (state._fetchFavouriteAssetsProcess.data.results !== undefined)
				state._fetchFavouriteAssetsProcess.data.results.map((data, index) => {
					if (data.id.toString() === action.payload.id.toString()) {
						state._fetchFavouriteAssetsProcess.data.results.splice(index, 1);
					}
					return null
				})
			return {
				...state
			};

		case CallerConstants.FETCH_PUBLIC_ASSET_DETAILS: {
			return {
				...state,
				_fetchPublicAssetDetailsProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.FETCH_PUBLIC_ASSET_DETAILS_FAIL: {
			return {
				...state,
				_fetchPublicAssetDetailsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RESET_FETCH_PUBLIC_ASSET_DETAILS: {
			return {
				...state,
				_fetchPublicAssetDetailsProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.PROCESS_FETCH_PUBLIC_ASSET_DETAILS: {
			return {
				...state,
				_fetchPublicAssetDetailsProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		}
		case CallerConstants.FAVOURITE_ASSETS_SEARCH: {
			return {
				...state,
				reLoadFavouriteAsset: false,
				_fetchFavouriteAssetsProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.FAVOURITE_ASSETS_SEARCH_FAIL: {
			return {
				...state,
				_fetchFavouriteAssetsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RESET_FAVOURITE_ASSETS_SEARCH: {
			return {
				...state,
				_fetchFavouriteAssetsProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.PROCESS_FAVOURITE_ASSETS_SEARCH:
			return {
				...state,
				_fetchFavouriteAssetsProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		//Asset Amenities
		case CallerConstants.UPDATE_ASSETS_AGRICULTURE_AMENITIES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateAgricultureAmenities: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_AGRICULTURE_AMENITIES_FAIL:
			return {
				...state,
				_updateAgricultureAmenities: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_AGRICULTURE_AMENITIES:
			return {
				...state,
				_updateAgricultureAmenities: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_AGRICULTURE_AMENITIES:
			return {
				...state,
				_updateAgricultureAmenities: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.UPDATE_ASSETS_RESIDENTIAL_AMENITIES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateResidentialAmenities: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_RESIDENTIAL_AMENITIES_FAIL:
			return {
				...state,
				_updateResidentialAmenities: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};

		case CallerConstants.RESET_UPDATE_ASSETS_RESIDENTIAL_AMENITIES:
			return {
				...state,
				_updateResidentialAmenities: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_RESIDENTIAL_AMENITIES:
			return {
				...state,
				_updateResidentialAmenities: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_ASSETS_COMMERCIAL_AMENITIES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateCommercialAmenities: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_COMMERCIAL_AMENITIES_FAIL:
			return {
				...state,
				_updateCommercialAmenities: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_COMMERCIAL_AMENITIES:
			return {
				...state,
				_updateCommercialAmenities: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_COMMERCIAL_AMENITIES:
			return {
				...state,
				_updateCommercialAmenities: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_ASSETS_INDUSTRIAL_AMENITIES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateIndustrialAmenities: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_INDUSTRIAL_AMENITIES_FAIL:
			return {
				...state,
				_updateIndustrialAmenities: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_INDUSTRIAL_AMENITIES:
			return {
				...state,
				_updateIndustrialAmenities: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_INDUSTRIAL_AMENITIES:
			return {
				...state,
				_updateIndustrialAmenities: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		//Asset Features

		case CallerConstants.UPDATE_ASSETS_AGRICULTURE_FEATURES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateAgricultureFeatures: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_AGRICULTURE_FEATURES_FAIL:
			return {
				...state,
				_updateAgricultureFeatures: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_AGRICULTURE_FEATURES:
			return {
				...state,
				_updateAgricultureFeatures: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_AGRICULTURE_FEATURES:
			return {
				...state,
				_updateAgricultureFeatures: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};

		case CallerConstants.UPDATE_ASSETS_RESIDENTIAL_FEATURES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateResidentialFeatures: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_RESIDENTIAL_FEATURES_FAIL:
			return {
				...state,
				_updateResidentialFeatures: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};

		case CallerConstants.RESET_UPDATE_ASSETS_RESIDENTIAL_FEATURES:
			return {
				...state,
				_updateResidentialFeatures: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_RESIDENTIAL_FEATURES:
			return {
				...state,
				_updateResidentialFeatures: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_ASSETS_COMMERCIAL_FEATURES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateCommercialFeatures: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_COMMERCIAL_FEATURES_FAIL:
			return {
				...state,
				_updateCommercialFeatures: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_COMMERCIAL_FEATURES:
			return {
				...state,
				_updateCommercialFeatures: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_COMMERCIAL_FEATURES:
			return {
				...state,
				_updateCommercialFeatures: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_ASSETS_INDUSTRIAL_FEATURES:
			toast.success(UPDATE_SUCCESS_MESSAGE);
			return {
				...state,
				_updateIndustrialFeatures: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.UPDATE_ASSETS_INDUSTRIAL_FEATURES_FAIL:
			return {
				...state,
				_updateIndustrialFeatures: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case CallerConstants.RESET_UPDATE_ASSETS_INDUSTRIAL_FEATURES:
			return {
				...state,
				_updateIndustrialFeatures: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case CallerConstants.PROCESS_UPDATE_ASSETS_INDUSTRIAL_FEATURES:
			return {
				...state,
				_updateIndustrialFeatures: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_ASSET:
			if (state._fetchPublicAssetDetailsProcess.data !== undefined)
				if (state._fetchPublicAssetDetailsProcess.data.id.toString() === action.payload.id.toString()) {
					if (action.payload.response === 204) {
						state._fetchPublicAssetDetailsProcess.data.starred = false;
						state._fetchPublicAssetDetailsProcess.data.stars = state._fetchPublicAssetDetailsProcess.data.stars - 1;
					}
					else if (action.payload.response === 200) {
						state._fetchPublicAssetDetailsProcess.data.starred = true;
						state._fetchPublicAssetDetailsProcess.data.stars = state._fetchPublicAssetDetailsProcess.data.stars + 1;
					}
				}
			return {
				...state
			};
		case CallerConstants.PAGE_NOT_FOUND: {
			return {
				...state,
				_fetchPublicAssetDetailsProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				},
				_fetchIndividualAssetProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		}
		case CallerConstants.RELOAD_ASSET: {
			return {
				...state,
				reLoad: true
			}
		}
		case CallerConstants.RELOAD_FAVOURITE_ASSET: {
			return {
				...state,
				reLoadFavouriteAsset: true
			}
		}

		case CallerConstants.ADD_ASSET_INQUIRY: {
			toast.success(ADD_PROPERTY_INQUIRY_MESSAGE);
			return {
				...state,
				_addAssetInquiryProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.ADD_ASSET_INQUIRY_FAIL: {
			return {
				...state,
				_addAssetInquiryProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.RESET_ADD_ASSET_INQUIRY: {
			return {
				...state,
				_addAssetInquiryProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			}
		}
		case CallerConstants.PROCESS_ADD_ASSET_INQUIRY: {
			return {
				...state,
				_addAssetInquiryProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			}
		}
		case CallerConstants.FETCH_ASSET_INQUIRIES: {
			return {
				...state,
				_fetchAssetInquiries: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.FETCH_ASSET_INQUIRIES_FAIL: {
			return {
				...state,
				_fetchAssetInquiries: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.RESET_FETCH_ASSET_INQUIRIES: {
			return {
				...state,
				_fetchAssetInquiries: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			}
		}
		case CallerConstants.PROCESS_FETCH_ASSET_INQUIRIES: {
			return {
				...state,
				_fetchAssetInquiries: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			}
		}
		case CallerConstants.UPDATE_ASSET_INQUIRY: {
			toast.success(UPDATE_PROPERTY_INQUIRY_MESSAGE);
			return {
				...state,
				_updateAssetInquiryProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.UPDATE_ASSET_INQUIRY_FAIL: {
			return {
				...state,
				_updateAssetInquiryProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.RESET_UPDATE_ASSET_INQUIRY: {
			return {
				...state,
				_updateAssetInquiryProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			}
		}
		case CallerConstants.PROCESS_UPDATE_ASSET_INQUIRY: {
			return {
				...state,
				_updateAssetInquiryProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			}
		}
		case CallerConstants.DELETE_ASSET_INQUIRY: {
			toast.success(DELETE_PROPERTY_INQUIRY_MESSAGE);
			return {
				...state,
				_deleteAssetInquiryProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.DELETE_ASSET_INQUIRY_FAIL: {
			return {
				...state,
				_deleteAssetInquiryProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			}
		}
		case CallerConstants.RESET_DELETE_ASSET_INQUIRY: {
			return {
				...state,
				_deleteAssetInquiryProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			}
		}
		case CallerConstants.PROCESS_DELETE_ASSET_INQUIRY: {
			return {
				...state,
				_deleteAssetInquiryProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			}
		}
		case CallerConstants.INVALID_TOKEN: {
			return {
				...state,
				_invalidToken: {
					status: ProcessTypes.INVALID_TOKEN,
					loader: false,
					data: {}
				}
			};
		}
		case CallerConstants.RESET_INVALID_TOKEN: {
			return {
				...state,
				_invalidToken: {
					status: ProcessTypes.RESET_INVALID_TOKEN,
					loader: false,
					data: {}
				}
			};
		}

		case CallerConstants.INVALID_API_URL: {
			return {
				...state,
				_invalidApiUrl: {
					status: ProcessTypes.INVALID_API_URL,
					loader: false,
					data: {}
				}
			};
		}
		case CallerConstants.RESET_INVALID_API_URL: {
			return {
				...state,
				_invalidApiUrl: {
					status: ProcessTypes.RESET_INVALID_API_URL,
					loader: false,
					data: {}
				}
			};
		}
		default:
			return state;
	}
}