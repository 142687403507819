import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import { toast } from 'react-toastify';
const initialState = {
    _addCompanyStoreProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateCompanyStoreProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCompanyStoreProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserCompanyStoreProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _followCompanyStoreProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _uploadCompanyProfileImage: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _uploadCompanyCoverImage: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserCompanyFollowListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _fetchCompanyStoreMemberShipProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCompanyListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
};

export default function CompanyStore(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.FETCH_COMPANY_STORE_DETAILS:
            return {
                ...state,
                _fetchCompanyStoreProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_COMPANY_STORE_DETAILS_FAIL:
            return {
                ...state,
                _fetchCompanyStoreProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_COMPANY_STORE_DETAILS:
            return {
                ...state,
                _fetchCompanyStoreProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_COMPANY_STORE_DETAILS:
            return {
                ...state,
                _fetchCompanyStoreProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_COMPANY_STORE:
            toast.success('Store updated successfully', { icon: false });
            return {
                ...state,
                _updateCompanyStoreProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_COMPANY_STORE_FAIL:
            return {
                ...state,
                _updateCompanyStoreProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_COMPANY_STORE:
            return {
                ...state,
                _updateCompanyStoreProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_COMPANY_STORE:
            return {
                ...state,
                _updateCompanyStoreProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_COMPANY_STORE:
            toast.success('company created successfully', { icon: false });
            return {
                ...state,
                _addCompanyStoreProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_COMPANY_STORE_FAIL:
            return {
                ...state,
                _addCompanyStoreProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_COMPANY_STORE:
            return {
                ...state,
                _addCompanyStoreProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_COMPANY_STORE:
            return {
                ...state,
                _addCompanyStoreProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.INVALID_TOKEN: {
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.RESET_INVALID_TOKEN: {
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.INVALID_API_URL: {
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.RESET_INVALID_API_URL: {
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.FETCH_USER_COMPANY_STORE:
            return {
                ...state,
                _fetchUserCompanyStoreProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_COMPANY_STORE_FAIL:
            return {
                ...state,
                _fetchUserCompanyStoreProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_COMPANY_STORE:
            return {
                ...state,
                _fetchUserCompanyStoreProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_COMPANY_STORE:
            return {
                ...state,
                _fetchUserCompanyStoreProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPLOAD_COMPANY_STORE_PROFILE_IMAGE:
            return {
                ...state,
                _uploadCompanyProfileImage: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPLOAD_COMPANY_STORE_PROFILE_IMAGE_FAIL:
            return {
                ...state,
                _uploadCompanyProfileImage: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPLOAD_COMPANY_STORE_PROFILE_IMAGE:
            return {
                ...state,
                _uploadCompanyProfileImage: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPLOAD_COMPANY_STORE_PROFILE_IMAGE:
            return {
                ...state,
                _uploadCompanyProfileImage: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPLOAD_COMPANY_STORE_COVER_IMAGE:
            return {
                ...state,
                _uploadCompanyCoverImage: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPLOAD_COMPANY_STORE_COVER_IMAGE_FAIL:
            return {
                ...state,
                _uploadCompanyCoverImage: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPLOAD_COMPANY_STORE_COVER_IMAGE:
            return {
                ...state,
                _uploadCompanyCoverImage: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPLOAD_COMPANY_STORE_COVER_IMAGE:
            return {
                ...state,
                _uploadCompanyCoverImage: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FOLLOW_COMPANY_STORE:
            state._fetchCompanyStoreProcess.data.following = !state._fetchCompanyStoreProcess.data.following;
            return {
                ...state,
                _followCompanyStoreProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FOLLOW_COMPANY_STORE_FAIL:
            return {
                ...state,
                _followCompanyStoreProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FOLLOW_COMPANY_STORE:
            return {
                ...state,
                _followCompanyStoreProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FOLLOW_COMPANY_STORE:
            return {
                ...state,
                _followCompanyStoreProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_USER_COMPANY_FOLLOWES_LIST:
            return {
                ...state,
                _fetchUserCompanyFollowListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_COMPANY_FOLLOWES_LIST_FAIL:
            return {
                ...state,
                _fetchUserCompanyFollowListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_COMPANY_FOLLOWES_LIST:
            return {
                ...state,
                _fetchUserCompanyFollowListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_COMPANY_FOLLOWES_LIST:
            return {
                ...state,
                _fetchUserCompanyFollowListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_COMPANY_STORE_MEMBERSHIP_LIST:
            return {
                ...state,
                _fetchCompanyStoreMemberShipProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_COMPANY_STORE_MEMBERSHIP_LIST_FAIL:
            return {
                ...state,
                _fetchCompanyStoreMemberShipProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_COMPANY_STORE_MEMBERSHIP_LIST:
            return {
                ...state,
                _fetchCompanyStoreMemberShipProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_COMPANY_STORE_MEMBERSHIP_LIST:
            return {
                ...state,
                _fetchCompanyStoreMemberShipProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_COMPANY_LIST:
            return {
                ...state,
                _fetchCompanyListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_COMPANY_LIST_FAIL:
            return {
                ...state,
                _fetchCompanyListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_COMPANY_LIST:
            return {
                ...state,
                _fetchCompanyListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_COMPANY_LIST:
            return {
                ...state,
                _fetchCompanyListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        default:
            return state;
    }
}