import {
    FETCH_IP2DEFAULTS,
    FETCH_IP2DEFAULTS_FAIL,
    RESET_FETCH_IP2DEFAULTS,
    PROCESS_FETCH_IP2DEFAULTS
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    _fetchIp2DefaultProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    }
}
export default function ip2Default(state = initialState, action) {
    switch (action.type) {
        case FETCH_IP2DEFAULTS:
        return {
                ...state,
                _fetchIp2DefaultProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case FETCH_IP2DEFAULTS_FAIL:
            return {
                ...state,
                _fetchIp2DefaultProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_FETCH_IP2DEFAULTS:
            return {
                ...state,
                _fetchIp2DefaultProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_FETCH_IP2DEFAULTS:
            return {
                ...state,
                _fetchIp2DefaultProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        default:
            return state;
    }
}