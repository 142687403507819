import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import { toast } from 'react-toastify';
import { UPDATE_SUCCESS_MESSAGE } from '../../constants/ToastConstant';
const initialState = {
    _fetchTradeBriefListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchTradeDetails: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addTradeProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateTradeProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateTradePublicProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteTradeProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteTradeImageProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateTradePropertyProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchFavoriteTradeProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _uploadTradeImageProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserTradeImageProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchTradeImageProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserTradeDetails: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserTradeList: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addTradeInquiry: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
};

export default function Trade(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.FETCH_BRIEF_TRADE_LIST:
            return {
                ...state,
                _fetchTradeBriefListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_BRIEF_TRADE_LIST_FAIL:
            return {
                ...state,
                _fetchTradeBriefListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_BRIEF_TRADE_LIST:
            return {
                ...state,
                _fetchTradeBriefListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_BRIEF_TRADE_LIST:
            return {
                ...state,
                _fetchTradeBriefListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_TRADE_DETAIL:
            return {
                ...state,
                _fetchTradeDetails: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_TRADE_DETAIL_FAIL:
            return {
                ...state,
                _fetchTradeDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_TRADE_DETAIL:
            return {
                ...state,
                _fetchTradeDetails: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_TRADE_DETAIL:
            return {
                ...state,
                _fetchTradeDetails: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_TRADE:
            toast.success(UPDATE_SUCCESS_MESSAGE);
            return {
                ...state,
                _addTradeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_TRADE_FAIL:
            return {
                ...state,
                _addTradeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_TRADE:
            return {
                ...state,
                _addTradeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_ADD_TRADE:
            return {
                ...state,
                _addTradeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_TRADE:
            toast.success(UPDATE_SUCCESS_MESSAGE);
            return {
                ...state,
                _updateTradeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_TRADE_FAIL:
            return {
                ...state,
                _updateTradeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_TRADE:
            return {
                ...state,
                _updateTradeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_UPDATE_TRADE:
            return {
                ...state,
                _updateTradeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_TRADE:
            return {
                ...state,
                _deleteTradeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_TRADE_FAIL:
            return {
                ...state,
                _deleteTradeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_TRADE:
            return {
                ...state,
                _deleteTradeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_DELETE_TRADE:
            return {
                ...state,
                _deleteTradeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_TRADE_IMAGE:
            return {
                ...state,
                _deleteTradeImageProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_TRADE_IMAGE_FAIL:
            return {
                ...state,
                _deleteTradeImageProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_TRADE_IMAGE:
            return {
                ...state,
                _deleteTradeImageProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_DELETE_TRADE_IMAGE:
            return {
                ...state,
                _deleteTradeImageProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_TRADE_PUBLIC:
            if (state._fetchUserTradeList.data.features !== undefined) {
                state._fetchUserTradeList.data.features.map((data, index) => {
                    if (data.id === action.payload.id) {
                        state._fetchUserTradeList.data.features[index].properties.is_public = action.payload.is_public;
                    }
                    return null;
                });
            }
            return {
                ...state,
                _updateTradePublicProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            }
        case CallerConstants.UPDATE_TRADE_PUBLIC_FAIL:
            return {
                ...state,
                _updateTradePublicProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_TRADE_PUBLIC:
            return {
                ...state,
                _updateTradePublicProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_UPDATE_TRADE_PUBLIC:
            return {
                ...state,
                _updateTradePublicProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_TRADE_PROPERTY:
            toast.success(UPDATE_SUCCESS_MESSAGE);
            return {
                ...state,
                _updateTradePropertyProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_TRADE_PROPERTY_FAIL:
            return {
                ...state,
                _updateTradePropertyProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_TRADE_PROPERTY:
            return {
                ...state,
                _updateTradePropertyProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_UPDATE_TRADE_PROPERTY:
            return {
                ...state,
                _updateTradePropertyProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_FAVORITE_TRADE:
            return {
                ...state,
                _fetchFavoriteTradeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_FAVORITE_TRADE_FAIL:
            return {
                ...state,
                _fetchFavoriteTradeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_FAVORITE_TRADE:
            return {
                ...state,
                _fetchFavoriteTradeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_FAVORITE_TRADE:
            return {
                ...state,
                _fetchFavoriteTradeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPLOAD_TRADE_IMAGE:
            return {
                ...state,
                _uploadTradeImageProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPLOAD_TRADE_IMAGE_FAIL:
            return {
                ...state,
                _uploadTradeImageProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPLOAD_TRADE_IMAGE:
            return {
                ...state,
                _uploadTradeImageProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_UPLOAD_TRADE_IMAGE:
            return {
                ...state,
                _uploadTradeImageProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_USER_TRADE_IMAGE:
            return {
                ...state,
                _fetchUserTradeImageProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_TRADE_IMAGE_FAIL:
            return {
                ...state,
                _fetchUserTradeImageProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_TRADE_IMAGE:
            return {
                ...state,
                _fetchUserTradeImageProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_TRADE_IMAGE:
            return {
                ...state,
                _fetchUserTradeImageProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_TRADE_IMAGE:
            return {
                ...state,
                _fetchTradeImageProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_TRADE_IMAGE_FAIL:
            return {
                ...state,
                _fetchTradeImageProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_TRADE_IMAGE:
            return {
                ...state,
                _fetchTradeImageProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_TRADE_IMAGE:
            return {
                ...state,
                _fetchTradeImageProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_USER_TRADE_DETAIL:
            return {
                ...state,
                _fetchUserTradeDetails: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_TRADE_DETAIL_FAIL:
            return {
                ...state,
                _fetchUserTradeDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_TRADE_DETAIL:
            return {
                ...state,
                _fetchUserTradeDetails: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_TRADE_DETAIL:
            return {
                ...state,
                _fetchUserTradeDetails: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_USER_TRADE_LIST:
            return {
                ...state,
                _fetchUserTradeList: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_TRADE_LIST_FAIL:
            return {
                ...state,
                _fetchUserTradeList: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_TRADE_LIST:
            return {
                ...state,
                _fetchUserTradeList: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_TRADE_LIST:
            return {
                ...state,
                _fetchUserTradeList: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_TRADE_INQUIRY:
            return {
                ...state,
                _addTradeInquiry: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_TRADE_INQUIRY_FAIL:
            return {
                ...state,
                _addTradeInquiry: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_TRADE_INQUIRY:
            return {
                ...state,
                _addTradeInquiry: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.PROCESS_ADD_TRADE_INQUIRY:
            return {
                ...state,
                _addTradeInquiry: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_TRADE: {
            if (state._fetchTradeDetails.data.unique_code !== undefined) {
                if (state._fetchTradeDetails.data.unique_code.toString() === action.payload.id.toString()) {
                    // if (action.payload.response === 204) {
                    //     state._fetchTradeDetails.data.starred = false;
                    //     state._fetchTradeDetails.data.stars = state._fetchTradeDetails.data.stars - 1;
                    // }
                    // else
                    if (action.payload.response === 200) {
                        if (!state._fetchTradeDetails.data.starred) {
                            state._fetchTradeDetails.data.starred = true;
                            state._fetchTradeDetails.data.stars = state._fetchTradeDetails.data.stars + 1;
                        }
                        else {
                            state._fetchTradeDetails.data.starred = false;
                            state._fetchTradeDetails.data.stars = state._fetchTradeDetails.data.stars - 1;
                        }
                    }
                }
            }
            // if (state._fetchUserTradeDetails.data.id !== undefined) {
            //     if (state._fetchUserTradeDetails.data.unique_code.toString() === action.payload.id.toString()) {
            //         if (action.payload.response === 204) {
            //             state._fetchUserTradeDetails.data.starred = false;
            //             state._fetchUserTradeDetails.data.stars = state._fetchUserTradeDetails.data.stars - 1;
            //         }
            //         else if (action.payload.response === 200) {
            //             state._fetchUserTradeDetails.data.starred = true;
            //             state._fetchUserTradeDetails.data.stars = state._fetchUserTradeDetails.data.stars + 1;
            //         }
            //     }
            // }
            return {
                ...state
            };
        }
        case CallerConstants.UPDATE_FAVOURITE_LIST_TRADE: {
            if (state._fetchTradeBriefListProcess.data.results !== undefined)
                state._fetchTradeBriefListProcess.data.results.features.map(data => {
                    if (data.properties.unique_code.toString() === action.payload.id.toString()) {
                        if (action.payload.response === 204) {
                            data.properties.starred = false;
                        }
                        else if (action.payload.response === 200) {
                            data.properties.starred = true;
                        }
                    }
                    return null
                })
            if (state._fetchFavoriteTradeProcess.data.results !== undefined)
                state._fetchFavoriteTradeProcess.data.results.features.map((data, index) => {
                    if (data.properties.unique_code.toString() === action.payload.id.toString()) {
                        state._fetchFavoriteTradeProcess.data.results.features.splice(index, 1);
                    }
                    return null
                })
            return {
                ...state
            };
        }
        case CallerConstants.PAGE_NOT_FOUND: {
            return {
                ...state,
                _fetchTradeDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                },
                _fetchUserTradeDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.INVALID_API_URL: {
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.RESET_INVALID_API_URL: {
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        }
        default:
            return state;
    }
}