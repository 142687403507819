import {
    USER_LAT_LONG,
    USER_LAT_LONG_FAIL,
    PROCESS_USER_LAT_LONG,
    RESET_USER_LAT_LONG
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
    _fetchGeocodingProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    }
}

export default function fetchGeocoding(state = initialState, action) {
    switch (action.type) {
        case USER_LAT_LONG:
            return {
                ...state,
                _fetchGeocodingProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };

        case USER_LAT_LONG_FAIL:
            return {
                ...state,
                _fetchGeocodingProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };

        case RESET_USER_LAT_LONG:
            return {
                ...state,
                _fetchGeocodingProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };

        case PROCESS_USER_LAT_LONG:
            return {
                ...state,
                _fetchGeocodingProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        default:
            return state;
    }
}