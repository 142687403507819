export const UPDATE_SUCCESS_MESSAGE = 'Saved successfully';
export const DELETE_ASSEET_IMAGE_MESSAGE = 'Property image successfully deleted';

export const DELETE_ASSEET_MESSAGE = 'Property successfully deleted';
export const ADD_PROPERTY_INQUIRY_MESSAGE = 'Inquiry successfully sended to the contact';
export const UPDATE_PROPERTY_INQUIRY_MESSAGE = 'Inquiry successfully updated';
export const DELETE_PROPERTY_INQUIRY_MESSAGE = 'Inquiry successfully deleted';

export const CHANGE_PASSWORD_MESSAGE = 'Password successfully changed';
export const UPDATE_USER_PROFILE_MESSAGE = 'Profile successfully updated';
export const UPDATE_USER_PROFILE_IMAGE_MESSAGE = 'Profile image successfully uploaded';
export const DELETE_USER_PROFILE_IMAGE_MESSAGE = 'Profile image successfully deleted';

export const ADD_CONTACT_MESSAGE = 'Contact successfully added';
export const UPDATE_CONTACT_MESSAGE = 'Contact successfully updated';
export const DELETE_CONTACT_MESSAGE = 'Contact successfully deleted';
export const DELETE_CONTACT_IMAGE_MESSAGE = 'Contact image successfully deleted';

export const ADD_COMPANY_MESSAGE = 'Company successfully added';
export const UPDATE_COMPANY_MESSAGE = 'Company successfully updated';
export const DELETE_COMPANY_MESSAGE = 'Company successfully deleted';
export const DELETE_COMPANY_IMAGE_MESSAGE = 'Company image successfully deleted';

export const ADD_DEAL_MESSAGE = 'Deal successfully added';
export const DELETE_DEAL_MESSAGE = 'Deal successfully deleted';
export const UPDATE_DEAL_MESSAGE = 'Deal successfully updated';

export const ADD_DEAL_TASK_MESSAGE = 'Deal task successfully added';
export const DELETE_DEAL_TASK_MESSAGE = 'Deal task successfully deleted';
export const UPDATE_DEAL_TASK_MESSAGE = 'Deal task successfully updated';

export const ADD_DEAL_NOTE_MESSAGE = 'Deal note successfully added';
export const DELETE_DEAL_NOTE_MESSAGE = 'Deal note successfully deleted';
export const UPDATE_DEAL_NOTE_MESSAGE = 'Deal note successfully updated';

export const SELECT_DEFAULT_PM_MESSAGE = 'Default public market successfully selected';
export const SELECT_DEFAULT_SUBDISTRICT_MESSAGE = 'Default subdistrict successfully selected';

export const DELETE_USER_ACCOUNT_MESSAGE = 'Account successfully deleted';