import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    reLoad: false,
    reLoadFavouriteNotice: false,
    _fetchNoticeListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualNoticeProcess: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _fetchNoticeCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addNoticeCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteNoticeCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateNoticeCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchFavouriteNoticeProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
};

export default function fetchNotes(state = initialState, action) {
    switch (action.type) {

        case CallerConstants.FETCH_NOTICES_LIST: {
            return {
                ...state,
                _fetchNoticeListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_NOTICES_LIST_FAIL: {
            return {
                ...state,
                _fetchNoticeListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_NOTICES_LIST: {
            return {
                ...state,
                _fetchNoticeListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_NOTICES_LIST:
            return {
                ...state,
                _fetchNoticeListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_FAVOURITE_NOTICE:
            if (state._fetchNoticeListProcess.data.results !== undefined)
                state._fetchNoticeListProcess.data.results.map(data => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        if (action.payload.response === 204) {
                            data.starred = false;
                        }
                        else if (action.payload.response === 200) {
                            data.starred = true;
                        }
                    }
                    return null
                })
            if (state._fetchFavouriteNoticeProcess.data.results !== undefined)
                state._fetchFavouriteNoticeProcess.data.results.map((data, index) => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        state._fetchFavouriteNoticeProcess.data.results.splice(index, 1);
                    }
                    return null
                })
            return {
                ...state
            };

        case CallerConstants.FETCH_INDIVIDUAL_NOTICE: {
            return {
                ...state,
                _fetchIndividualNoticeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_INDIVIDUAL_NOTICE_FAIL: {
            return {
                ...state,
                _fetchIndividualNoticeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_INDIVIDUAL_NOTICE: {
            return {
                ...state,
                _fetchIndividualNoticeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_INDIVIDUAL_NOTICE: {
            return {
                ...state,
                _fetchIndividualNoticeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_NOTICE:
        if (state._fetchIndividualNoticeProcess.data !== undefined)
                if (state._fetchIndividualNoticeProcess.data.id.toString() === action.payload.id.toString()) {
                    if (action.payload.response === 204) {
                        state._fetchIndividualNoticeProcess.data.starred = false;
                        state._fetchIndividualNoticeProcess.data.stars = state._fetchIndividualNoticeProcess.data.stars - 1;
                    }
                    else if (action.payload.response === 200) {
                        state._fetchIndividualNoticeProcess.data.starred = true;
                        state._fetchIndividualNoticeProcess.data.stars = state._fetchIndividualNoticeProcess.data.stars + 1;
                    }
                }
            return {
                ...state
            };
        case CallerConstants.PAGE_NOT_FOUND: {
            return {
                ...state,
                _fetchIndividualNoticeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }

        // comment

        case CallerConstants.FETCH_NOTICE_COMMENTS_LIST: {
            return {
                ...state,
                reLoad: false,
                _fetchNoticeCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_NOTICE_COMMENTS_LIST_FAIL: {
            return {
                ...state,
                _fetchNoticeCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_NOTICE_COMMENTS_LIST: {
            return {
                ...state,
                _fetchNoticeCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_NOTICE_COMMENTS_LIST: {
            return {
                ...state,
                _fetchNoticeCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.ADD_NOTICE_COMMENTS: {
            state._fetchIndividualNoticeProcess.data.comments = state._fetchIndividualNoticeProcess.data.comments + 1;
            return {
                ...state,
                reLoad: true,
                _addNoticeCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.ADD_NOTICE_COMMENTS_FAIL: {
            return {
                ...state,
                _addNoticeCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_ADD_NOTICE_COMMENTS: {
            return {
                ...state,
                _addNoticeCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_ADD_NOTICE_COMMENTS: {
            return {
                ...state,
                _addNoticeCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }

        case CallerConstants.DELETE_NOTICE_COMMENTS: {
            state._fetchIndividualNoticeProcess.data.comments = state._fetchIndividualNoticeProcess.data.comments - 1;
            return {
                ...state,
                reLoad: true,
                _deleteNoticeCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.DELETE_NOTICE_COMMENTS_FAIL: {
            return {
                ...state,
                _deleteNoticeCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_DELETE_NOTICE_COMMENTS: {
            return {
                ...state,
                _deleteNoticeCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_DELETE_NOTICE_COMMENTS: {
            return {
                ...state,
                _deleteNoticeCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }


        case CallerConstants.UPDATE_NOTICE_COMMENTS: {
            return {
                ...state,
                reLoad: true,
                _updateNoticeCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.UPDATE_NOTICE_COMMENTS_FAIL: {
            return {
                ...state,
                _updateNoticeCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_UPDATE_NOTICE_COMMENTS: {
            return {
                ...state,
                _updateNoticeCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_UPDATE_NOTICE_COMMENTS: {
            return {
                ...state,
                _updateNoticeCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }

        case CallerConstants.FAVOURITE_NOTICES_SEARCH: {
            return {
                ...state,
                reLoadFavouriteNotice: false,

                _fetchFavouriteNoticeProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FAVOURITE_NOTICES_SEARCH_FAIL: {
            return {
                ...state,
                _fetchFavouriteNoticeProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FAVOURITE_NOTICES_SEARCH: {
            return {
                ...state,
                _fetchFavouriteNoticeProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.PROCESS_FAVOURITE_NOTICES_SEARCH:
            return {
                ...state,
                _fetchFavouriteNoticeProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.RELOAD_FAVOURITE_NOTICE: {
            return {
                ...state,
                reLoadFavouriteNotice: true
            }
        }

        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}
