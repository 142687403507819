import {
    FETCH_ALL_TYPES,
    FETCH_ALL_TYPES_FAIL,
    PROCESS_FETCH_ALL_TYPES,
    RESET_FETCH_ALL_TYPES,
    INVALID_API_URL,
    RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    _fetchTypesProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },

    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
}
export default function fetchTypes(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_TYPES:
            return {
                ...state,
                _fetchTypesProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case FETCH_ALL_TYPES_FAIL:
            return {
                ...state,
                _fetchTypesProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_FETCH_ALL_TYPES:
            return {
                ...state,
                _fetchTypesProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_FETCH_ALL_TYPES:
            return {
                ...state,
                _fetchTypesProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}