import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import 'lazysizes/lazysizes.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import App from './App';
import * as serviceWorker from './serviceWorker';
import myApp from './common/reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
//import logger from 'redux-logger';
let store = createStore(myApp, applyMiddleware(thunk));

//Develop
// Sentry.init({ dsn: "https://51131160a3334b608fa67f0672040a1b@o406444.ingest.sentry.io/5273879" });

//Production
Sentry.init({ dsn: "https://229cda10832c4310be5c62dd4c3c788a@o406444.ingest.sentry.io/5277094" });
ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));
serviceWorker.unregister();
