import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    _addCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _saveForLaterProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchSaveForLaterProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteSaveForLaterProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _moveSaveForLaterProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _checkOutBasketProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _checkOutCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateCheckOutBasketProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateCheckOutCartProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
}

export default function cart(state = initialState, action) {
    switch (action.type) {

        case CallerConstants.ADD_CART:
            return {
                ...state,
                _addCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_CART_FAIL:
            return {
                ...state,
                _addCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_CART:
            return {
                ...state,
                _addCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_CART:
            return {
                ...state,
                _addCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_CART:
            return {
                ...state,
                _fetchCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_CART_FAIL:
            return {
                ...state,
                _fetchCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_CART:
            return {
                ...state,
                _fetchCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_CART:
            return {
                ...state,
                _fetchCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_CART:
            return {
                ...state,
                _deleteCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_CART_FAIL:
            return {
                ...state,
                _deleteCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_CART:
            return {
                ...state,
                _deleteCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_CART:
            return {
                ...state,
                _deleteCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_CART:
            return {
                ...state,
                _updateCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_CART_FAIL:
            return {
                ...state,
                _updateCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_CART:
            return {
                ...state,
                _updateCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_CART:
            return {
                ...state,
                _updateCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.SAVE_FOR_LATER:
            return {
                ...state,
                _saveForLaterProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.SAVE_FOR_LATER_FAIL:
            return {
                ...state,
                _saveForLaterProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_SAVE_FOR_LATER:
            return {
                ...state,
                _saveForLaterProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_SAVE_FOR_LATER:
            return {
                ...state,
                _saveForLaterProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_SAVE_FOR_LATER:
            return {
                ...state,
                _fetchSaveForLaterProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_SAVE_FOR_LATER_FAIL:
            return {
                ...state,
                _fetchSaveForLaterProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_SAVE_FOR_LATER:
            return {
                ...state,
                _fetchSaveForLaterProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_SAVE_FOR_LATER:
            return {
                ...state,
                _fetchSaveForLaterProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.MOVE_SAVE_FOR_LATER:
            return {
                ...state,
                _moveSaveForLaterProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.MOVE_SAVE_FOR_LATER_FAIL:
            return {
                ...state,
                _moveSaveForLaterProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_MOVE_SAVE_FOR_LATER:
            return {
                ...state,
                _moveSaveForLaterProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_MOVE_SAVE_FOR_LATER:
            return {
                ...state,
                _moveSaveForLaterProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_SAVE_FOR_LATER:
            return {
                ...state,
                _deleteSaveForLaterProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_SAVE_FOR_LATER_FAIL:
            return {
                ...state,
                _deleteSaveForLaterProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_SAVE_FOR_LATER:
            return {
                ...state,
                _deleteSaveForLaterProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_SAVE_FOR_LATER:
            return {
                ...state,
                _deleteSaveForLaterProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.BASKET_CHECKOUT:
            return {
                ...state,
                _checkOutBasketProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.BASKET_CHECKOUT_FAIL:
            return {
                ...state,
                _checkOutBasketProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_BASKET_CHECKOUT:
            return {
                ...state,
                _checkOutBasketProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_BASKET_CHECKOUT:
            return {
                ...state,
                _checkOutBasketProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.CART_CHECKOUT:
            return {
                ...state,
                _checkOutCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.CART_CHECKOUT_FAIL:
            return {
                ...state,
                _checkOutCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_CART_CHECKOUT:
            return {
                ...state,
                _checkOutCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_CART_CHECKOUT:
            return {
                ...state,
                _checkOutCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_BASKET_CHECKOUT:
            return {
                ...state,
                _updateCheckOutBasketProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_BASKET_CHECKOUT_FAIL:
            return {
                ...state,
                _updateCheckOutBasketProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_BASKET_CHECKOUT:
            return {
                ...state,
                _updateCheckOutBasketProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_BASKET_CHECKOUT:
            return {
                ...state,
                _updateCheckOutBasketProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.UPDATE_CART_CHECKOUT:
            return {
                ...state,
                _updateCheckOutCartProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_CART_CHECKOUT_FAIL:
            return {
                ...state,
                _updateCheckOutCartProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_CART_CHECKOUT:
            return {
                ...state,
                _updateCheckOutCartProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_CART_CHECKOUT:
            return {
                ...state,
                _updateCheckOutCartProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}