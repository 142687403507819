import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import { CHANGE_PASSWORD_MESSAGE, UPDATE_USER_PROFILE_IMAGE_MESSAGE, DELETE_USER_PROFILE_IMAGE_MESSAGE, DELETE_USER_ACCOUNT_MESSAGE } from '../../constants/ToastConstant';
import { toast } from 'react-toastify';

const initialState = {
  _fetchLoginProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _addUserProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchUserProfile: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchUserDetails: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateUserProfile: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateUserContact: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateUserDetail: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateUserIntroduction: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteUserAccount: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _changePasswordProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _recoveryPasswordProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _logout: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _addUserProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteUserProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _getUserAccount: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _resendEmailVerification: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _sendEmailVerification: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  },
};

export default function addUser(state = initialState, action) {
  switch (action.type) {
    case CallerConstants.ADD_USER:
      return {
        ...state,
        _addUserProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.ADD_USER_FAIL:
      return {
        ...state,
        _addUserProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_ADD_USER:
      return {
        ...state,
        _addUserProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: state._addUserProcess.data
        }
      };
    case CallerConstants.PROCESS_ADD_USER:
      return {
        ...state,
        _: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.LOGIN:
      return {
        ...state,
        _fetchLoginProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        },
      };
    case CallerConstants.LOGIN_FAIL:
      return {
        ...state,
        _fetchLoginProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_LOGIN:
      return {
        ...state,
        _fetchLoginProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: state._fetchLoginProcess.data
        }
      };
    case CallerConstants.PROCESS_LOGIN:
      return {
        ...state,
        _fetchLoginProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.MAX_LOGIN:
      return {
        ...state,
        _fetchLoginProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_USER_PROFILE:
      return {
        ...state,
        _fetchUserProfile: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_USER_PROFILE_FAIL:
      return {
        ...state,
        _fetchUserProfile: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_FETCH_USER_PROFILE:
      return {
        ...state,
        _fetchUserProfile: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.FETCH_USER_DETAIL:
      return {
        ...state,
        _fetchUserDetails: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_USER_DETAIL_FAIL:
      return {
        ...state,
        _fetchUserDetails: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_FETCH_USER_DETAIL:
      return {
        ...state,
        _fetchUserDetails: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.UPDATE_USER_PROFILE:
      return {
        ...state,
        _updateUserProfile: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        _updateUserProfile: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_UPDATE_USER_PROFILE:
      return {
        ...state,
        _updateUserProfile: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.UPDATE_USER_DETAIL:
      return {
        ...state,
        _updateUserDetail: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_USER_DETAIL_FAIL:
      return {
        ...state,
        _updateUserDetail: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_USER_INTRODUCTION:
      return {
        ...state,
        _updateUserIntroduction: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_USER_INTRODUCTION_FAIL:
      return {
        ...state,
        _updateUserIntroduction: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPDATE_USER_INTRODUCTION:
      return {
        ...state,
        _updateUserIntroduction: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.RESET_USER_PROFILE: {
      return {
        ...state,
        _fetchUserDetails: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        },
        _fetchUserProfile: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        },
        _updateUserProfile: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        },
        _updateUserDetail: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    }
    case CallerConstants.LOGOUT:
      return {
        ...state,
        _logout: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload,
        }
      };
    case CallerConstants.LOGOUT_FAIL:
      return {
        ...state,
        _logout: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_LOGOUT:
      return {
        ...state,
        _logout: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_LOGOUT:
      return {
        ...state,
        _logout: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.CHANGE_PASSWORD:
      toast.success(CHANGE_PASSWORD_MESSAGE, { icon: false });
      return {
        ...state,
        _changePasswordProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        _changePasswordProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        _changePasswordProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_CHANGE_PASSWORD:
      return {
        ...state,
        _changePasswordProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.RECOVERY_PASSWORD:
      return {
        ...state,
        _recoveryPasswordProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RECOVERY_PASSWORD_FAIL:
      return {
        ...state,
        _recoveryPasswordProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_RECOVERY_PASSWORD:
      return {
        ...state,
        _recoveryPasswordProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_RECOVERY_PASSWORD:
      return {
        ...state,
        _recoveryPasswordProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.UPLOAD_USER_PROFILE_IMAGE:
      toast.success(UPDATE_USER_PROFILE_IMAGE_MESSAGE, { icon: false });
      return {
        ...state,
        _addUserProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPLOAD_USER_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _addUserProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPLOAD_USER_PROFILE_IMAGE:
      return {
        ...state,
        _addUserProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_UPLOAD_USER_PROFILE_IMAGE:
      return {
        ...state,
        _addUserProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.DELETE_USER_PROFILE_IMAGE:
      toast.success(DELETE_USER_PROFILE_IMAGE_MESSAGE, { icon: false });
      return {
        ...state,
        _deleteUserProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.DELETE_USER_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _deleteUserProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_USER_PROFILE_IMAGE:
      return {
        ...state,
        _deleteUserProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_DELETE_USER_PROFILE_IMAGE:
      return {
        ...state,
        _deleteUserProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.GET_USER_ACCOUNT:
      return {
        ...state,
        _getUserAccount: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.GET_USER_ACCOUNT_FAIL:
      return {
        ...state,
        _getUserAccount: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_GET_USER_ACCOUNT:
      return {
        ...state,
        _getUserAccount: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_GET_USER_ACCOUNT:
      return {
        ...state,
        _getUserAccount: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.SEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _sendEmailVerification: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.SEND_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        _sendEmailVerification: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_SEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _sendEmailVerification: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_SEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _sendEmailVerification: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _resendEmailVerification: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESEND_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        _resendEmailVerification: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _resendEmailVerification: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_RESEND_EMAIL_VERIFICATION:
      return {
        ...state,
        _resendEmailVerification: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.DELETE_USER_ACCOUNT:
      toast.success(DELETE_USER_ACCOUNT_MESSAGE, { icon: false });
      return {
        ...state,
        _deleteUserAccount: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload,
        }
      };
    case CallerConstants.DELETE_USER_ACCOUNT_FAIL:
      return {
        ...state,
        _deleteUserAccount: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_USER_ACCOUNT:
      return {
        ...state,
        _deleteUserAccount: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.PROCESS_DELETE_USER_ACCOUNT:
      return {
        ...state,
        _deleteUserAccount: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          data: {}
        }
      };
    }
    case CallerConstants.RESET_INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          data: {}
        }
      };
    }
    case CallerConstants.INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    case CallerConstants.RESET_INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    default:
      return state;
  }
}