import {
    FETCH_API_STATUS,
    FETCH_API_STATUS_FAIL,
    RESET_FETCH_API_STATUS,
    INVALID_API_URL,
    RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
    _fetchAPIStatus: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
}
export default function fetchAPIStatus(state = initialState, action) {
    switch (action.type) {
        case FETCH_API_STATUS:
            return {
                ...state,
                _fetchAPIStatus: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case FETCH_API_STATUS_FAIL:
            return {
                ...state,
                _fetchAPIStatus: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_FETCH_API_STATUS:
            return {
                ...state,
                _fetchAPIStatus: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}