import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import { toast } from 'react-toastify';
import { SELECT_DEFAULT_PM_MESSAGE, SELECT_DEFAULT_SUBDISTRICT_MESSAGE } from '../../constants/ToastConstant';

const initialState = {
    // _fetchCommodityPrimaryList: {
    //     status: ProcessTypes.IDLE,
    //     loader: false,
    //     data: {}
    // },
    // _fetchCommodityVariety: {
    //     status: ProcessTypes.IDLE,
    //     loader: false,
    //     data: {}
    // },
    _fetchPublicMarketList: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchPublicMarketDetail: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchPublicMarketComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addPublicMarketComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updatePublicMarketComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deletePublicMarketComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCVbyPM: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCVbyPMDetails: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addCVbyPMComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchCVbyPMComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteCVbyPMComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateCVbyPMComments: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchFavoritePublicMarketProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchFavoriteCommodityProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addDefaultMarket: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchDefaultMarket: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteDefaultMarket: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addDefaultSubdistrict: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchDefaultSubdistrict: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteDefaultSubdistrict: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
}
export default function fetchCommodity(state = initialState, action) {
    switch (action.type) {
        // case CallerConstants.FETCH_COMMODITY_PRIMARY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityPrimaryList: {
        //             status: ProcessTypes.SUCCESS,
        //             loader: false,
        //             data: action.payload
        //         }
        //     };
        // case CallerConstants.FETCH_COMMODITY_PRIMARY_LIST_FAIL:
        //     return {
        //         ...state,
        //         _fetchCommodityPrimaryList: {
        //             status: ProcessTypes.FAIL,
        //             loader: false,
        //             data: action.payload
        //         }
        //     };
        // case CallerConstants.RESET_FETCH_COMMODITY_PRIMARY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityPrimaryList: {
        //             status: ProcessTypes.IDLE,
        //             loader: false,
        //             data: {}
        //         }
        //     };
        // case CallerConstants.PROCESS_FETCH_COMMODITY_PRIMARY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityPrimaryList: {
        //             status: ProcessTypes.PROCESSING,
        //             loader: true,
        //             data: {}
        //         }
        //     };

        // case CallerConstants.FETCH_COMMODITY_VARIETY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityVariety: {
        //             status: ProcessTypes.SUCCESS,
        //             loader: false,
        //             data: action.payload
        //         }
        //     };
        // case CallerConstants.FETCH_COMMODITY_VARIETY_LIST_FAIL:
        //     return {
        //         ...state,
        //         _fetchCommodityVariety: {
        //             status: ProcessTypes.FAIL,
        //             loader: false,
        //             data: action.payload
        //         }
        //     };
        // case CallerConstants.RESET_FETCH_COMMODITY_VARIETY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityVariety: {
        //             status: ProcessTypes.IDLE,
        //             loader: false,
        //             data: {}
        //         }
        //     };
        // case CallerConstants.PROCESS_FETCH_COMMODITY_VARIETY_LIST:
        //     return {
        //         ...state,
        //         _fetchCommodityVariety: {
        //             status: ProcessTypes.PROCESSING,
        //             loader: true,
        //             data: {}
        //         }
        //     };
        case CallerConstants.FETCH_PUBLIC_MARKET_LIST:
            return {
                ...state,
                _fetchPublicMarketList: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_PUBLIC_MARKET_LIST_FAIL:
            return {
                ...state,
                _fetchPublicMarketList: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_PUBLIC_MARKET_LIST:
            return {
                ...state,
                _fetchPublicMarketList: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_PUBLIC_MARKET_LIST:
            return {
                ...state,
                _fetchPublicMarketList: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_PUBLIC_MARKET_DETAILS:
            return {
                ...state,
                _fetchPublicMarketDetail: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_PUBLIC_MARKET_DETAILS_FAIL:
            return {
                ...state,
                _fetchPublicMarketDetail: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_PUBLIC_MARKET_DETAILS:
            return {
                ...state,
                _fetchPublicMarketDetail: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_PUBLIC_MARKET_DETAILS:
            return {
                ...state,
                _fetchPublicMarketDetail: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _fetchPublicMarketComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_PUBLIC_MARKET_COMMENTS_FAIL:
            return {
                ...state,
                _fetchPublicMarketComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _fetchPublicMarketComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _fetchPublicMarketComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _addPublicMarketComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_PUBLIC_MARKET_COMMENTS_FAIL:
            return {
                ...state,
                _addPublicMarketComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _addPublicMarketComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _addPublicMarketComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _updatePublicMarketComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_PUBLIC_MARKET_COMMENTS_FAIL:
            return {
                ...state,
                _updatePublicMarketComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _updatePublicMarketComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _updatePublicMarketComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _deletePublicMarketComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_PUBLIC_MARKET_COMMENTS_FAIL:
            return {
                ...state,
                _deletePublicMarketComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _deletePublicMarketComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_PUBLIC_MARKET_COMMENTS:
            return {
                ...state,
                _deletePublicMarketComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_PUBLICMARKET:
            if (state._fetchPublicMarketDetail.data !== undefined)
                if (state._fetchPublicMarketDetail.data.id.toString() === action.payload.id.toString()) {
                    if (action.payload.response === 204) {
                        state._fetchPublicMarketDetail.data.starred = false;
                        state._fetchPublicMarketDetail.data.stars = state._fetchPublicMarketDetail.data.stars - 1;
                    }
                    else if (action.payload.response === 200) {
                        state._fetchPublicMarketDetail.data.starred = true;
                        state._fetchPublicMarketDetail.data.stars = state._fetchPublicMarketDetail.data.stars + 1;
                    }
                }
            return {
                ...state
            };
        case CallerConstants.FETCH_CV_BY_PM:
            return {
                ...state,
                _fetchCVbyPM: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_CV_BY_PM_FAIL:
            return {
                ...state,
                _fetchCVbyPM: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_CV_BY_PM:
            return {
                ...state,
                _fetchCVbyPM: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_CV_BY_PM:
            return {
                ...state,
                _fetchCVbyPM: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_CV_BY_PM_DETAILS:
            return {
                ...state,
                _fetchCVbyPMDetails: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_CV_BY_PM_DETAILS_FAIL:
            return {
                ...state,
                _fetchCVbyPMDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_CV_BY_PM_DETAILS:
            return {
                ...state,
                _fetchCVbyPMDetails: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_CV_BY_PM_DETAILS:
            return {
                ...state,
                _fetchCVbyPMDetails: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _addCVbyPMComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _addCVbyPMComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_CV_BY_PM_COMMENTS_FAIL:
            return {
                ...state,
                _addCVbyPMComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _addCVbyPMComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _fetchCVbyPMComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _fetchCVbyPMComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_CV_BY_PM_COMMENTS_FAIL:
            return {
                ...state,
                _fetchCVbyPMComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _fetchCVbyPMComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.DELETE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _deleteCVbyPMComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_CV_BY_PM_COMMENTS_FAIL:
            return {
                ...state,
                _deleteCVbyPMComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _deleteCVbyPMComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _deleteCVbyPMComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.UPDATE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _updateCVbyPMComments: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_CV_BY_PM_COMMENTS_FAIL:
            return {
                ...state,
                _updateCVbyPMComments: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _updateCVbyPMComments: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_CV_BY_PM_COMMENTS:
            return {
                ...state,
                _updateCVbyPMComments: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_FAVORITE_PUBLIC_MARKET:
            return {
                ...state,
                _fetchFavoritePublicMarketProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_FAVORITE_PUBLIC_MARKET_FAIL:
            return {
                ...state,
                _fetchFavoritePublicMarketProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_FAVORITE_PUBLIC_MARKET:
            return {
                ...state,
                _fetchFavoritePublicMarketProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_FAVORITE_PUBLIC_MARKET:
            return {
                ...state,
                _fetchFavoritePublicMarketProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_FAVOURITE_LIST_PUBLICMARKET:
            if (state._fetchPublicMarketList.data.results !== undefined)
                state._fetchPublicMarketList.data.results.map(data => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        if (action.payload.response === 204) {
                            data.starred = false;
                        }
                        else if (action.payload.response === 200) {
                            data.starred = true;
                        }
                    }
                    return null
                })
            if (state._fetchFavoritePublicMarketProcess.data.results !== undefined)
                state._fetchFavoritePublicMarketProcess.data.results.map((data, index) => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        state._fetchFavoritePublicMarketProcess.data.results.splice(index, 1);
                    }
                    return null
                })
            return {
                ...state
            };
        case CallerConstants.FETCH_FAVORITE_COMMODITY:
            return {
                ...state,
                _fetchFavoriteCommodityProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_FAVORITE_COMMODITY_FAIL:
            return {
                ...state,
                _fetchFavoriteCommodityProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_FAVORITE_COMMODITY:
            return {
                ...state,
                _fetchFavoriteCommodityProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_FAVORITE_COMMODITY:
            return {
                ...state,
                _fetchFavoriteCommodityProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.PAGE_NOT_FOUND: {
            return {
                ...state,
                _fetchPublicMarketDetail: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                },
                _fetchCVbyPMDetails: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_CVBYPM:
            if (state._fetchCVbyPMDetails.data !== undefined)
                if (state._fetchCVbyPMDetails.data.id.toString() === action.payload.id.toString()) {
                    if (action.payload.response === 204) {
                        state._fetchCVbyPMDetails.data.starred = false;
                        state._fetchCVbyPMDetails.data.stars = state._fetchCVbyPMDetails.data.stars - 1;
                    }
                    else if (action.payload.response === 200) {
                        state._fetchCVbyPMDetails.data.starred = true;
                        state._fetchCVbyPMDetails.data.stars = state._fetchCVbyPMDetails.data.stars + 1;
                    }
                }
            return {
                ...state
            };
        case CallerConstants.UPDATE_FAVOURITE_LIST_CVBYPM:
            if (state._fetchCVbyPM.data.results !== undefined)
                state._fetchCVbyPM.data.results.map(data => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        if (action.payload.response === 204) {
                            data.starred = false;
                        }
                        else if (action.payload.response === 200) {
                            data.starred = true;
                        }
                    }
                    return null
                })
            if (state._fetchFavoriteCommodityProcess.data.results !== undefined)
                state._fetchFavoriteCommodityProcess.data.results.map((data, index) => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        state._fetchFavoriteCommodityProcess.data.results.splice(index, 1);
                    }
                    return null
                })
            return {
                ...state
            };
        case CallerConstants.ADD_DEFAULT_MARKET:
            toast.success(SELECT_DEFAULT_PM_MESSAGE, { icon: false });
            return {
                ...state,
                _addDefaultMarket: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_DEFAULT_MARKET_FAIL:
            return {
                ...state,
                _addDefaultMarket: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_DEFAULT_MARKET:
            return {
                ...state,
                _addDefaultMarket: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_DEFAULT_MARKET:
            return {
                ...state,
                _addDefaultMarket: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_DEFAULT_MARKET:
            return {
                ...state,
                _fetchDefaultMarket: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEFAULT_MARKET_FAIL:
            return {
                ...state,
                _fetchDefaultMarket: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEFAULT_MARKET:
            return {
                ...state,
                _fetchDefaultMarket: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEFAULT_MARKET:
            return {
                ...state,
                _fetchDefaultMarket: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_DEFAULT_MARKET:
            return {
                ...state,
                _deleteDefaultMarket: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_DEFAULT_MARKET_FAIL:
            return {
                ...state,
                _deleteDefaultMarket: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_DEFAULT_MARKET:
            return {
                ...state,
                _deleteDefaultMarket: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_DEFAULT_MARKET:
            return {
                ...state,
                _deleteDefaultMarket: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_DEFAULT_SUBDISTRICT:
            toast.success(SELECT_DEFAULT_SUBDISTRICT_MESSAGE, { icon: false });
            return {
                ...state,
                _addDefaultSubdistrict: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_DEFAULT_SUBDISTRICT_FAIL:
            return {
                ...state,
                _addDefaultSubdistrict: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _addDefaultSubdistrict: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _addDefaultSubdistrict: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _fetchDefaultSubdistrict: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEFAULT_SUBDISTRICT_FAIL:
            return {
                ...state,
                _fetchDefaultSubdistrict: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _fetchDefaultSubdistrict: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _fetchDefaultSubdistrict: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.DELETE_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _deleteDefaultSubdistrict: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_DEFAULT_SUBDISTRICT_FAIL:
            return {
                ...state,
                _deleteDefaultSubdistrict: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _deleteDefaultSubdistrict: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_DEFAULT_SUBDISTRICT:
            return {
                ...state,
                _deleteDefaultSubdistrict: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        default:
            return state;
    }
}