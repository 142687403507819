//  API not found
export const INVALID_API_URL = 'INVALID_API_URL';
export const RESET_INVALID_API_URL = 'RESET_INVALID_API_URL';

//  Invalid Token
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const RESET_INVALID_TOKEN = 'RESET_INVALID_TOKEN';

// Reset Reload
export const RELOAD_CONTACT = 'RELOAD_CONTACT';
export const RELOAD_DEAL = 'RELOAD_DEAL';
export const RELOAD_ASSET = 'RELOAD_ASSET';
export const RELOAD_COMPANY = 'RELOAD_COMPANY';
export const RELOAD_FAVOURITE_ASSET = 'RELOAD_FAVOURITE_ASSET';
export const RELOAD_FAVOURITE_NEWS = 'RELOAD_FAVOURITE_NEWS';
export const RELOAD_FAVOURITE_NOTICE = 'RELOAD_FAVOURITE_NOTICE';

// Page Not Found
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';

//API status
export const FETCH_API_STATUS = 'FETCH_API_STATUS';
export const FETCH_API_STATUS_FAIL = 'FETCH_API_STATUS_FAIL';
export const RESET_FETCH_API_STATUS = 'RESET_FETCH_API_STATUS';

//  Otp APi Cases
export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL';
export const RESET_SEND_OTP = 'RESET_SEND_OTP';
export const PROCESS_SEND_OTP = 'PROCESS_SEND_OTP';

export const CHECK_OTP = 'CHECK_OTP';
export const CHECK_OTP_FAIL = 'CHECK_OTP_FAIL';
export const RESET_CHECK_OTP = 'RESET_CHECK_OTP';
export const PROCESS_CHECK_OTP = 'PROCESS_CHECK_OTP';

export const PASSWORD_SEND_OTP = 'PASSWORD_SEND_OTP';
export const PASSWORD_SEND_OTP_FAIL = 'PASSWORD_SEND_OTP_FAIL';
export const RESET_PASSWORD_SEND_OTP = 'RESET_PASSWORD_SEND_OTP';
export const PROCESS_PASSWORD_SEND_OTP = 'PROCESS_PASSWORD_SEND_OTP';

export const PASSWORD_CHECK_OTP = 'PASSWORD_CHECK_OTP';
export const PASSWORD_CHECK_OTP_FAIL = 'PASSWORD_CHECK_OTP_FAIL';
export const RESET_PASSWORD_CHECK_OTP = 'RESET_PASSWORD_CHECK_OTP';
export const PROCESS_PASSWORD_CHECK_OTP = 'PROCESS_PASSWORD_CHECK_OTP';

//  User Api Cases 
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const RESET_ADD_USER = 'RESET_ADD_USER';
export const PROCESS_ADD_USER = 'PROCESS_ADD_USER';

export const LOGIN = 'LOGIN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const RESET_LOGIN = 'RESET_LOGIN';
export const PROCESS_LOGIN = 'PROCESS_LOGIN';
export const MAX_LOGIN = 'MAX_LOGIN';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const RESET_LOGOUT = 'RESET_LOGOUT';
export const PROCESS_LOGOUT = 'PROCESS_LOGOUT';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const RESET_CHANGE_PASSWORD = 'RESET_CHANGE_PASSWORD';
export const PROCESS_CHANGE_PASSWORD = 'PROCESS_CHANGE_PASSWORD';

export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const RECOVERY_PASSWORD_FAIL = 'RECOVERY_PASSWORD_FAIL';
export const RESET_RECOVERY_PASSWORD = 'RESET_RECOVERY_PASSWORD';
export const PROCESS_RECOVERY_PASSWORD = 'PROCESS_RECOVERY_PASSWORD';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_FAIL = 'FETCH_USER_PROFILE_FAIL';
export const RESET_USER_PROFILE = 'RESET_FETCH_USER_PROFILE';
export const PROCESS_FETCH_USER_PROFILE = 'PROCESS_FETCH_USER_PROFILE';

export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const FETCH_USER_DETAIL_FAIL = 'FETCH_USER_DETAIL_FAIL';
export const RESET_USER_DETAIL = 'RESET_FETCH_USER_DETAIL';
export const PROCESS_FETCH_USER_DETAIL = 'PROCESS_FETCH_USER_DETAIL';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const RESET_UPDATE_USER_PROFILE = 'RESET_UPDATE_USER_PROFILE';
export const PROCESS_UPDATE_USER_PROFILE = 'PROCESS_UPDATE_USER_PROFILE';

export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const UPDATE_USER_DETAIL_FAIL = 'UPDATE_USER_DETAIL_FAIL';
export const RESET_UPDATE_USER_DETAIL = 'RESET_UPDATE_USER_DETAIL';
export const PROCESS_UPDATE_USER_DETAIL = 'PROCESS_UPDATE_USER_DETAIL';

export const UPDATE_USER_INTRODUCTION = 'UPDATE_USER_INTRODUCTION';
export const UPDATE_USER_INTRODUCTION_FAIL = 'UPDATE_USER_INTRODUCTION_FAIL';
export const RESET_UPDATE_USER_INTRODUCTION = 'RESET_UPDATE_USER_INTRODUCTION';
export const PROCESS_UPDATE_USER_INTRODUCTION = 'PROCESS_UPDATE_USER_INTRODUCTION';

export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
export const GET_USER_ACCOUNT_FAIL = 'GET_USER_ACCOUNT_FAIL';
export const RESET_GET_USER_ACCOUNT = 'RESET_GET_USER_ACCOUNT';
export const PROCESS_GET_USER_ACCOUNT = 'PROCESS_GET_USER_ACCOUNT';

//  DELETE USER ACCOUNT
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT_FAIL = 'DELETE_USER_ACCOUNT_FAIL';
export const RESET_DELETE_USER_ACCOUNT = 'RESET_DELETE_USER_ACCOUNT';
export const PROCESS_DELETE_USER_ACCOUNT = 'PROCESS_DELETE_USER_ACCOUNT';

//  CODE API CASES [POI, Types, star & Location] 
export const FETCH_IP2DEFAULTS = 'FETCH_IP2DEFAULTS';
export const FETCH_IP2DEFAULTS_FAIL = 'FETCH_IP2DEFAULTS_FAIL';
export const RESET_FETCH_IP2DEFAULTS = 'FETCH_RESET_IP2DEFAULTS';
export const PROCESS_FETCH_IP2DEFAULTS = 'FETCH_PROCESS_IP2DEFAULTS';

export const COUNTRY_LIST = 'COUNTRY_LIST';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';
export const RESET_COUNTRY_LIST = 'RESET_COUNTRY_LIST';
export const PROCESS_COUNTRY_LIST = 'PROCESS_COUNTRY_LIST';

export const STATE_LIST = 'STATE_LIST';
export const STATE_LIST_FAIL = 'STATE_LIST_FAIL';
export const RESET_STATE_LIST = 'RESET_STATE_LIST';
export const PROCESS_STATE_LIST = 'PROCESS_STATE_LIST';

export const DISTRICTS_LIST = 'DISTRICTS_LIST';
export const DISTRICTS_LIST_FAIL = 'DISTRICTS_LIST_FAIL';
export const RESET_DISTRICTS_LIST = 'RESET_DISTRICTS_LIST';
export const PROCESS_DISTRICTS_LIST = 'PROCESS_DISTRICTS_LIST';

export const SUBDISTRICTS_LIST = 'SUBDISTRICTS_LIST';
export const SUBDISTRICTS_LIST_FAIL = 'SUBDISTRICTS_LIST_FAIL';
export const RESET_SUBDISTRICTS_LIST = 'RESET_SUBDISTRICTS_LIST';
export const PROCESS_SUBDISTRICTS_LIST = 'PROCESS_SUBDISTRICTS_LIST';

export const FETCH_ALL_TYPES = 'FETCH_ALL_TYPES';
export const FETCH_ALL_TYPES_FAIL = 'FETCH_ALL_TYPES_FAIL';
export const RESET_FETCH_ALL_TYPES = 'RESET_FETCH_ALL_TYPES';
export const PROCESS_FETCH_ALL_TYPES = 'PROCESS_FETCH_ALL_TYPES';

export const LOCATION_AUTO_COMPALITE = 'LOCATION_AUTO_COMPALITE';
export const LOCATION_AUTO_COMPALITE_FAIL = 'LOCATION_AUTO_COMPALITE_FAIL';
export const RESET_LOCATION_AUTO_COMPALITE = 'RESET_LOCATION_AUTO_COMPALITE';
export const PROCESS_LOCATION_AUTO_COMPALITE = 'PROCESS_LOCATION_AUTO_COMPALITE';

export const FAVOURITE = 'FAVOURITE';
export const FAVOURITE_FAIL = 'FAVOURITE_FAIL';
export const RESET_FAVOURITE = 'RESET_FAVOURITE';
export const PROCESS_FAVOURITE = 'PROCESS_FAVOURITE';

export const UPDATE_FAVOURITE_ASSET = 'UPDATE_FAVOURITE_ASSET';
export const UPDATE_FAVOURITE_NEWS = 'UPDATE_FAVOURITE_NEWS';
export const UPDATE_FAVOURITE_NOTICE = 'UPDATE_FAVOURITE_NOTICE';

export const UPDATE_FAVOURITE_INDIVIDUAL_ASSET = 'UPDATE_FAVOURITE_INDIVIDUAL_ASSET';
export const UPDATE_FAVOURITE_INDIVIDUAL_NEWS = 'UPDATE_FAVOURITE_INDIVIDUAL_NEWS';
export const UPDATE_FAVOURITE_INDIVIDUAL_NOTICE = 'UPDATE_FAVOURITE_INDIVIDUAL_NOTICE';

export const UPDATE_FAVOURITE_INDIVIDUAL_PUBLICMARKET = 'UPDATE_FAVOURITE_INDIVIDUAL_PUBLICMARKET';
export const UPDATE_FAVOURITE_INDIVIDUAL_CVBYPM = 'UPDATE_FAVOURITE_INDIVIDUAL_CVBYPM';
export const UPDATE_FAVOURITE_INDIVIDUAL_TRADE = 'UPDATE_FAVOURITE_INDIVIDUAL_TRADE';
export const UPDATE_FAVOURITE_LIST_TRADE = 'UPDATE_FAVOURITE_LIST_TRADE';
export const UPDATE_FAVOURITE_LIST_CVBYPM = 'UPDATE_FAVOURITE_LIST_CVBYPM';
export const UPDATE_FAVOURITE_LIST_PUBLICMARKET = 'UPDATE_FAVOURITE_LIST_PUBLICMARKET';

//  CONTACTS API CASES
export const FETCH_ALL_CONTACTS = 'FETCH_ALL_CONTACTS';
export const FETCH_ALL_CONTACTS_FAIL = 'FETCH_ALL_CONTACTS_FAIL';
export const RESET_ALL_CONTACTS = 'RESET_ALL_CONTACTS';
export const PROCESS_FETCH_ALL_CONTACTS = 'PROCESS_FETCH_ALL_CONTACTS';

export const FETCH_BRIEF_CONTACTS = 'FETCH_BRIEF_CONTACTS';
export const FETCH_BRIEF_CONTACTS_FAIL = 'FETCH_BRIEF_CONTACTS_FAIL';
export const RESET_BRIEF_CONTACTS = 'RESET_BRIEF_CONTACTS';
export const PROCESS_FETCH_BRIEF_CONTACTS = 'PROCESS_FETCH_BRIEF_CONTACTS';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
export const RESET_DELETE_CONTACT = 'RESET_DELETE_CONTACT';
export const PROCESS_DELETE_CONTACT = 'PROCESS_DELETE_CONTACT';

export const FETCH_INDIVIDUAL_CONTACT = 'FETCH_INDIVIDUAL_CONTACT';
export const FETCH_INDIVIDUAL_CONTACT_FAIL = 'FETCH_INDIVIDUAL_CONTACT_FAIL';
export const RESET_FETCH_INDIVIDUAL_CONTACT = 'RESET_FETCH_INDIVIDUAL_CONTACT';
export const PROCESS_FETCH_INDIVIDUAL_CONTACT = 'PROCESS_FETCH_INDIVIDUAL_CONTACT';

export const ADD_CONTACTS = 'ADD_CONTACTS';
export const ADD_CONTACTS_FAIL = 'ADD_CONTACTS_FAIL';
export const RESET_ADD_CONTACTS = 'RESET_ADD_CONTACTS';
export const PROCESS_ADD_CONTACT = 'PROCESS_ADD_CONTACT';

export const UPDATE_INDIVIDUAL_CONTACT = 'UPDATE_INDIVIDUAL_CONTACT';
export const UPDATE_INDIVIDUAL_CONTACT_FAIL = 'UPDATE_INDIVIDUAL_CONTACT_FAIL';
export const RESET_UPDATE_INDIVIDUAL_CONTACT = 'RESET_UPDATE_INDIVIDUAL_CONTACT';
export const PROCESS_UPDATE_INDIVIDUAL_CONTACT = 'PROCESS_UPDATE_INDIVIDUAL_CONTACT';

export const FETCH_INDIVIDUAL_CONTACT_TIMELINE = 'FETCH_INDIVIDUAL_CONTACT_TIMELINE';
export const FETCH_INDIVIDUAL_CONTACT_TIMELINE_FAIL = 'FETCH_INDIVIDUAL_CONTACT_TIMELINE_FAIL';
export const RESET_FETCH_INDIVIDUAL_CONTACT_TIMELINE = 'RESET_FETCH_INDIVIDUAL_CONTACT_TIMELINE';
export const PROCESS_FETCH_INDIVIDUAL_CONTACT_TIMELINE = 'PROCESS_FETCH_INDIVIDUAL_CONTACT_TIMELINE';

// COMPANY API CASES
export const FETCH_ALL_COMPANY = 'FETCH_ALL_COMPANY';
export const FETCH_ALL_COMPANY_FAIL = 'FETCH_ALL_COMPANY_FAIL';
export const RESET_FETCH_ALL_COMPANY = 'RESET_FETCH_ALL_COMPANY';
export const PROCESS_FETCH_ALL_COMPANY = 'PROCESS_FETCH_ALL_COMPANY';

export const FETCH_BRIEF_COMPANY = 'FETCH_BRIEF_COMPANY';
export const FETCH_BRIEF_COMPANY_FAIL = 'FETCH_BRIEF_COMPANY_FAIL';
export const RESET_FETCH_BRIEF_COMPANY = 'RESET_FETCH_BRIEF_COMPANY';
export const PROCESS_FETCH_BRIEF_COMPANY = 'PROCESS_FETCH_BRIEF_COMPANY';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const RESET_DELETE_COMPANY = 'RESET_DELETE_COMPANY';
export const PROCESS_DELETE_COMPANY = 'PROCESS_DELETE_COMPANY';

export const FETCH_INDIVIDUAL_COMPANY = 'FETCH_INDIVIDUAL_COMPANY';
export const FETCH_INDIVIDUAL_COMPANY_FAIL = 'FETCH_INDIVIDUAL_COMPANY_FAIL';
export const RESET_FETCH_INDIVIDUAL_COMPANY = 'RESET_FETCH_INDIVIDUAL_COMPANY';
export const PROCESS_FETCH_INDIVIDUAL_COMPANY = 'PROCESS_FETCH_INDIVIDUAL_COMPANY';

export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const RESET_ADD_COMPANY = 'RESET_ADD_COMPANY';
export const PROCESS_ADD_COMPANY = 'PROCESS_ADD_COMPANY';

export const UPDATE_INDIVIDUAL_COMPANY = 'UPDATE_INDIVIDUAL_COMPANY';
export const UPDATE_INDIVIDUAL_COMPANY_FAIL = 'UPDATE_INDIVIDUAL_COMPANY_FAIL';
export const RESET_UPDATE_INDIVIDUAL_COMPANY = 'RESET_UPDATE_INDIVIDUAL_COMPANY';
export const PROCESS_UPDATE_INDIVIDUAL_COMPANY = 'PROCESS_UPDATE_INDIVIDUAL_COMPANY';

// DEALS API CASES
export const ADD_DEAL = 'ADD_DEAL';
export const ADD_DEAL_FAIL = 'ADD_DEAL_FAIL';
export const RESET_ADD_DEAL = 'RESET_ADD_DEAL';
export const PROCESS_ADD_DEAL = 'PROCESS_ADD_DEAL';

export const DELETE_DEAL = 'DELETE_DEAL';
export const DELETE_DEAL_FAIL = 'DELETE_DEAL_FAIL';
export const RESET_DELETE_DEAL = 'RESET_DELETE_DEAL';
export const PROCESS_DELETE_DEAL = 'PROCESS_DELETE_DEAL';

export const FETCH_BRIEF_DEAL = 'FETCH_BRIEF_DEAL';
export const FETCH_BRIEF_DEAL_FAIL = 'FETCH_BRIEF_DEAL_FAIL';
export const RESET_FETCH_BRIEF_DEAL = 'RESET_FETCH_BRIEF_DEAL';
export const PROCESS_FETCH_BRIEF_DEAL = 'PROCESS_FETCH_BRIEF_DEAL';

export const FETCH_INDIVIDUAL_DEAL = 'FETCH_INDIVIDUAL_DEAL';
export const FETCH_INDIVIDUAL_DEAL_FAIL = 'FETCH_INDIVIDUAL_DEAL_FAIL';
export const RESET_FETCH_INDIVIDUAL_DEAL = 'RESET_FETCH_INDIVIDUAL_DEAL';
export const PROCESS_FETCH_INDIVIDUAL_DEAL = 'PROCESS_FETCH_INDIVIDUAL_DEAL';

export const UPDATE_INDIVIDUAL_DEAL = 'UPDATE_INDIVIDUAL_DEAL';
export const UPDATE_INDIVIDUAL_DEAL_FAIL = 'UPDATE_INDIVIDUAL_DEAL_FAIL';
export const RESET_UPDATE_INDIVIDUAL_DEAL = 'RESET_UPDATE_INDIVIDUAL_DEAL';
export const PROCESS_UPDATE_INDIVIDUAL_DEAL = 'PROCESS_UPDATE_INDIVIDUAL_DEAL';

export const FETCH_CONTACTS_MIN = 'FETCH_CONTACTS_MIN';
export const FETCH_CONTACTS_MIN_FAIL = 'FETCH_CONTACTS_MIN_FAIL';
export const RESET_FETCH_CONTACTS_MIN = 'RESET_FETCH_CONTACTS_MIN';
export const PROCESS_FETCH_CONTACTS_MIN = 'PROCESS_FETCH_CONTACTS_MIN';

export const FETCH_COMPANIES_MIN = 'FETCH_COMPANIES_MIN';
export const FETCH_COMPANIES_MIN_FAIL = 'FETCH_COMPANIES_MIN_FAIL';
export const RESET_FETCH_COMPANIES_MIN = 'RESET_FETCH_COMPANIES_MIN';
export const PROCESS_FETCH_COMPANIES_MIN = 'PROCESS_FETCH_COMPANIES_MIN';

export const FETCH_ASSETS_LIST = 'FETCH_ASSETS_LIST';
export const FETCH_ASSETS_LIST_FAIL = 'FETCH_ASSETS_LIST_FAIL';
export const RESET_FETCH_ASSETS_LIST = 'RESET_FETCH_ASSETS_LIST';
export const PROCESS_FETCH_ASSETS_LIST = 'PROCESS_FETCH_ASSETS_LIST';

// DEALS NOTES CASES
export const ADD_DEAL_NOTES = 'ADD_DEAL_NOTES';
export const ADD_DEAL_NOTES_FAIL = 'ADD_DEAL_NOTES_FAIL';
export const RESET_ADD_DEAL_NOTES = 'RESET_ADD_DEAL_NOTES';
export const PROCESS_ADD_DEAL_NOTES = 'PROCESS_ADD_DEAL_NOTES';

export const DELETE_DEAL_NOTES = 'DELETE_DEAL_NOTES';
export const DELETE_DEAL_NOTES_FAIL = 'DELETE_DEAL_NOTES_FAIL';
export const RESET_DELETE_DEAL_NOTES = 'RESET_DELETE_DEAL_NOTES';
export const PROCESS_DELETE_DEAL_NOTES = 'PROCESS_DELETE_DEAL_NOTES';

export const FETCH_DEAL_ALL_NOTES = 'FETCH_DEAL_ALL_NOTES';
export const FETCH_DEAL_ALL_NOTES_FAIL = 'FETCH_DEAL_ALL_NOTES_FAIL';
export const RESET_FETCH_DEAL_ALL_NOTES = 'RESET_FETCH_DEAL_ALL_NOTES';
export const PROCESS_FETCH_DEAL_ALL_NOTES = 'PROCESS_FETCH_DEAL_ALL_NOTES';

export const FETCH_DEAL_INDIVIDUAL_NOTES = 'FETCH_DEAL_INDIVIDUAL_NOTES';
export const FETCH_DEAL_INDIVIDUAL_NOTES_FAIL = 'FETCH_DEAL_INDIVIDUAL_NOTES_FAIL';
export const RESET_FETCH_DEAL_INDIVIDUAL_NOTES = 'RESET_FETCH_DEAL_INDIVIDUAL_NOTES';
export const PROCESS_FETCH_DEAL_INDIVIDUAL_NOTES = 'PROCESS_FETCH_DEAL_INDIVIDUAL_NOTES';

export const UPDATE_DEAL_INDIVIDUAL_NOTES = 'UPDATE_DEAL_INDIVIDUAL_NOTES';
export const UPDATE_DEAL_INDIVIDUAL_NOTES_FAIL = 'UPDATE_DEAL_INDIVIDUAL_NOTES_FAIL';
export const RESET_UPDATE_DEAL_INDIVIDUAL_NOTES = 'RESET_UPDATE_DEAL_INDIVIDUAL_NOTES';
export const PROCESS_UPDATE_DEAL_INDIVIDUAL_NOTES = 'PROCESS_UPDATE_DEAL_INDIVIDUAL_NOTES';

export const FETCH_NOTES_LIST = 'FETCH_NOTES_LIST';
export const FETCH_NOTES_LIST_FAIL = 'FETCH_NOTES_LIST_FAI';
export const RESET_FETCH_NOTES_LIST = 'RESET_FETCH_NOTES_LIST';
export const PROCESS_FETCH_NOTES_LIST = 'PROCESS_FETCH_NOTES_LIST';

// ASSETS API CASES 
export const ADD_ASSETS = 'ADD_ASSETS';
export const ADD_ASSETS_FAIL = 'ADD_ASSETS_FAIL';
export const RESET_ADD_ASSETS = 'RESET_ADD_ASSETS';
export const PROCESS_ADD_ASSETS = 'PROCESS_ADD_ASSETS';

export const FETCH_INDIVIDUAL_ASSETS_PROPERTY = 'FETCH_INDIVIDUAL_ASSETS_PROPERTY';
export const FETCH_INDIVIDUAL_ASSETS_PROPERTY_FAIL = 'FETCH_INDIVIDUAL_ASSETS_PROPERTY_FAIL';
export const RESET_FETCH_INDIVIDUAL_ASSETS_PROPERTY = 'RESET_FETCH_INDIVIDUAL_ASSETS_PROPERTY';
export const PROCESS_FETCH_INDIVIDUAL_ASSETS_PROPERTY = 'PROCESS_FETCH_INDIVIDUAL_ASSETS_PROPERTY';

export const UPDATE_INDIVIDUAL_ASSETS_PROPERTY = 'UPDATE_INDIVIDUAL_ASSETS_PROPERTY';
export const UPDATE_INDIVIDUAL_ASSETS_PROPERTY_FAIL = 'UPDATE_INDIVIDUAL_ASSETS_PROPERTY_FAIL';
export const RESET_UPDATE_INDIVIDUAL_ASSETS_PROPERTY = 'RESET_UPDATE_INDIVIDUAL_ASSETS_PROPERTY';
export const PROCESS_UPDATE_INDIVIDUAL_ASSETS_PROPERTY = 'PROCESS_UPDATE_INDIVIDUAL_ASSETS_PROPERTY';

export const UPDATE_INDIVIDUAL_ASSETS_LOCATION = 'UPDATE_INDIVIDUAL_ASSETS_LOCATION';
export const UPDATE_INDIVIDUAL_ASSETS_LOCATION_FAIL = 'UPDATE_INDIVIDUAL_ASSETS_LOCATION_FAIL';
export const RESET_UPDATE_INDIVIDUAL_ASSETS_LOCATION = 'RESET_UPDATE_INDIVIDUAL_ASSETS_LOCATION';
export const PROCESS_UPDATE_INDIVIDUAL_ASSETS_LOCATION = 'PROCESS_UPDATE_INDIVIDUAL_ASSETS_LOCATION';

export const FETCH_BRIEF_ASSETS = 'FETCH_BRIEF_ASSETS';
export const FETCH_BRIEF_ASSETS_FAIL = 'FETCH_BRIEF_ASSETS_FAIL';
export const RESET_FETCH_BRIEF_ASSETS = 'RESET_FETCH_BRIEF_ASSETS';
export const PROCESS_FETCH_BRIEF_ASSETS = 'PROCESS_FETCH_BRIEF_ASSETS';

export const FETCH_ASSETS = 'FETCH_ASSETS';
export const FETCH_ASSETS_FAIL = 'FETCH_ASSETS_FAIL';
export const RESET_FETCH_ASSETS = 'RESET_FETCH_ASSETS';
export const PROCESS_FETCH_ASSETS = 'PROCESS_FETCH_ASSETS';

export const FETCH_INDIVIDUAL_ASSETS = 'FETCH_INDIVIDUAL_ASSETS';
export const FETCH_INDIVIDUAL_ASSETS_FAIL = 'FETCH_INDIVIDUAL_ASSETS_FAIL';
export const RESET_FETCH_INDIVIDUAL_ASSETS = 'RESET_FETCH_INDIVIDUAL_ASSETS';
export const PROCESS_FETCH_INDIVIDUAL_ASSETS = 'PROCESS_FETCH_INDIVIDUAL_ASSETS';

export const UPDATE_INDIVIDUAL_ASSETS = 'UPDATE_INDIVIDUAL_ASSETS';
export const UPDATE_INDIVIDUAL_ASSETS_FAIL = 'UPDATE_INDIVIDUAL_ASSETS_FAIL';
export const RESET_UPDATE_INDIVIDUAL_ASSETS = 'RESET_UPDATE_INDIVIDUAL_ASSETS';
export const PROCESS_UPDATE_INDIVIDUAL_ASSETS = 'PROCESS_UPDATE_INDIVIDUAL_ASSETS';

export const UPDATE_INDIVIDUAL_ASSETS_DETAIL = 'UPDATE_INDIVIDUAL_ASSETS_DETAIL';
export const UPDATE_INDIVIDUAL_ASSETS_DETAIL_FAIL = 'UPDATE_INDIVIDUAL_ASSETS_DETAIL_FAIL';
export const RESET_UPDATE_INDIVIDUAL_ASSETS_DETAIL = 'RESET_UPDATE_INDIVIDUAL_ASSETS_DETAIL';
export const PROCESS_UPDATE_INDIVIDUAL_ASSETS_DETAIL = 'PROCESS_UPDATE_INDIVIDUAL_ASSETS_DETAIL';

export const UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC = 'UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC';
export const UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC_FAIL = 'UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC_FAIL';
export const RESET_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC = 'RESET_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC';
export const PROCESS_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC = 'PROCESS_UPDATE_INDIVIDUAL_ASSETS_ISPUBLIC';

export const DELETE_ASSETS = 'DELETE_ASSETS';
export const DELETE_ASSETS_FAIL = 'DELETE_ASSETS_FAIL';
export const RESET_DELETE_ASSETS = 'RESET_DELETE_ASSETS';
export const PROCESS_DELETE_ASSETS = 'PROCESS_DELETE_ASSETS';

export const FETCH_ASSETS_DEALS = 'FETCH_ASSETS_DEALS';
export const FETCH_ASSETS_DEALS_FAIL = 'FETCH_ASSETS_DEALS_FAIL';
export const RESET_FETCH_ASSETS_DEALS = 'RESET_FETCH_ASSETS_DEALS';
export const PROCESS_FETCH_ASSETS_DEALS = 'PROCESS_FETCH_ASSETS_DEALS';

// Asset Amenities 
export const UPDATE_ASSETS_AGRICULTURE_AMENITIES = 'UPDATE_ASSETS_AGRICULTURE_AMENITIES';
export const UPDATE_ASSETS_AGRICULTURE_AMENITIES_FAIL = 'UPDATE_ASSETS_AGRICULTURE_AMENITIES_FAIL';
export const RESET_UPDATE_ASSETS_AGRICULTURE_AMENITIES = 'RESET_UPDATE_ASSETS_AGRICULTURE_AMENITIES';
export const PROCESS_UPDATE_ASSETS_AGRICULTURE_AMENITIES = 'PROCESS_UPDATE_ASSETS_AGRICULTURE_AMENITIES';

export const UPDATE_ASSETS_RESIDENTIAL_AMENITIES = 'UPDATE_ASSETS_RESIDENTIAL_AMENITIES';
export const UPDATE_ASSETS_RESIDENTIAL_AMENITIES_FAIL = 'UPDATE_ASSETS_RESIDENTIAL_AMENITIES_FAIL';
export const RESET_UPDATE_ASSETS_RESIDENTIAL_AMENITIES = 'RESET_UPDATE_ASSETS_RESIDENTIAL_AMENITIES';
export const PROCESS_UPDATE_ASSETS_RESIDENTIAL_AMENITIES = 'PROCESS_UPDATE_ASSETS_RESIDENTIAL_AMENITIES';

export const UPDATE_ASSETS_COMMERCIAL_AMENITIES = 'UPDATE_ASSETS_COMMERCIAL_AMENITIES';
export const UPDATE_ASSETS_COMMERCIAL_AMENITIES_FAIL = 'UPDATE_ASSETS_COMMERCIAL_AMENITIES_FAIL';
export const RESET_UPDATE_ASSETS_COMMERCIAL_AMENITIES = 'RESET_UPDATE_ASSETS_COMMERCIAL_AMENITIES';
export const PROCESS_UPDATE_ASSETS_COMMERCIAL_AMENITIES = 'PROCESS_UPDATE_ASSETS_COMMERCIAL_AMENITIES';

export const UPDATE_ASSETS_INDUSTRIAL_AMENITIES = 'UPDATE_ASSETS_INDUSTRIAL_AMENITIES';
export const UPDATE_ASSETS_INDUSTRIAL_AMENITIES_FAIL = 'UPDATE_ASSETS_INDUSTRIAL_AMENITIES_FAIL';
export const RESET_UPDATE_ASSETS_INDUSTRIAL_AMENITIES = 'RESET_UPDATE_ASSETS_INDUSTRIAL_AMENITIES';
export const PROCESS_UPDATE_ASSETS_INDUSTRIAL_AMENITIES = 'PROCESS_UPDATE_ASSETS_INDUSTRIAL_AMENITIES';

// Asset Features
export const UPDATE_ASSETS_AGRICULTURE_FEATURES = 'UPDATE_ASSETS_AGRICULTURE_FEATURES';
export const UPDATE_ASSETS_AGRICULTURE_FEATURES_FAIL = 'UPDATE_ASSETS_AGRICULTURE_FEATURES_FAIL';
export const RESET_UPDATE_ASSETS_AGRICULTURE_FEATURES = 'RESET_UPDATE_ASSETS_AGRICULTURE_FEATURES';
export const PROCESS_UPDATE_ASSETS_AGRICULTURE_FEATURES = 'PROCESS_UPDATE_ASSETS_AGRICULTURE_FEATURES';

export const UPDATE_ASSETS_RESIDENTIAL_FEATURES = 'UPDATE_ASSETS_RESIDENTIAL_FEATURES';
export const UPDATE_ASSETS_RESIDENTIAL_FEATURES_FAIL = 'UPDATE_ASSETS_RESIDENTIAL_FEATURES_FAIL';
export const RESET_UPDATE_ASSETS_RESIDENTIAL_FEATURES = 'RESET_UPDATE_ASSETS_RESIDENTIAL_FEATURES';
export const PROCESS_UPDATE_ASSETS_RESIDENTIAL_FEATURES = 'PROCESS_UPDATE_ASSETS_RESIDENTIAL_FEATURES';

export const UPDATE_ASSETS_COMMERCIAL_FEATURES = 'UPDATE_ASSETS_COMMERCIAL_FEATURES';
export const UPDATE_ASSETS_COMMERCIAL_FEATURES_FAIL = 'UPDATE_ASSETS_COMMERCIAL_FEATURES_FAIL';
export const RESET_UPDATE_ASSETS_COMMERCIAL_FEATURES = 'RESET_UPDATE_ASSETS_COMMERCIAL_FEATURES';
export const PROCESS_UPDATE_ASSETS_COMMERCIAL_FEATURES = 'PROCESS_UPDATE_ASSETS_COMMERCIAL_FEATURES';

export const UPDATE_ASSETS_INDUSTRIAL_FEATURES = 'UPDATE_ASSETS_INDUSTRIAL_FEATURES';
export const UPDATE_ASSETS_INDUSTRIAL_FEATURES_FAIL = 'UPDATE_ASSETS_INDUSTRIAL_FEATURES_FAIL';
export const RESET_UPDATE_ASSETS_INDUSTRIAL_FEATURES = 'RESET_UPDATE_ASSETS_INDUSTRIAL_FEATURES';
export const PROCESS_UPDATE_ASSETS_INDUSTRIAL_FEATURES = 'PROCESS_UPDATE_ASSETS_INDUSTRIAL_FEATURES';

export const ADD_ASSET_INQUIRY = 'ADD_ASSET_INQUIRY';
export const ADD_ASSET_INQUIRY_FAIL = 'ADD_ASSET_INQUIRY_FAIL';
export const RESET_ADD_ASSET_INQUIRY = 'RESET_ADD_ASSET_INQUIRY';
export const PROCESS_ADD_ASSET_INQUIRY = 'PROCESS_ADD_ASSET_INQUIRY';

export const FETCH_ASSET_INQUIRIES = 'FETCH_ASSET_INQUIRIES';
export const FETCH_ASSET_INQUIRIES_FAIL = 'FETCH_ASSET_INQUIRIES_FAIL';
export const RESET_FETCH_ASSET_INQUIRIES = 'RESET_FETCH_ASSET_INQUIRIES';
export const PROCESS_FETCH_ASSET_INQUIRIES = 'PROCESS_FETCH_ASSET_INQUIRIES';

export const UPDATE_ASSET_INQUIRY = 'UPDATE_ASSET_INQUIRY';
export const UPDATE_ASSET_INQUIRY_FAIL = 'UPDATE_ASSET_INQUIRY_FAIL';
export const RESET_UPDATE_ASSET_INQUIRY = 'RESET_UPDATE_ASSET_INQUIRY';
export const PROCESS_UPDATE_ASSET_INQUIRY = 'PROCESS_UPDATE_ASSET_INQUIRY';

export const DELETE_ASSET_INQUIRY = 'DELETE_ASSET_INQUIRY';
export const DELETE_ASSET_INQUIRY_FAIL = 'DELETE_ASSET_INQUIRY_FAIL';
export const RESET_DELETE_ASSET_INQUIRY = 'RESET_DELETE_ASSET_INQUIRY';
export const PROCESS_DELETE_ASSET_INQUIRY = 'PROCESS_DELETE_ASSET_INQUIRY';

// Public Asset Search Cases
export const PUBLIC_ASSETS_SEARCH = 'PUBLIC_ASSETS_SEARCH';
export const PUBLIC_ASSETS_SEARCH_FAIL = 'PUBLIC_ASSETS_SEARCH_FAIL';
export const RESET_PUBLIC_ASSETS_SEARCH = 'RESET_PUBLIC_ASSETS_SEARCH';
export const PROCESS_PUBLIC_ASSETS_SEARCH = 'PROCESS_PUBLIC_ASSETS_SEARCH';

export const FETCH_PUBLIC_ASSET_DETAILS = 'FETCH_PUBLIC_ASSET_DETAILS';
export const FETCH_PUBLIC_ASSET_DETAILS_FAIL = 'FETCH_PUBLIC_ASSET_DETAILS_FAIL';
export const RESET_FETCH_PUBLIC_ASSET_DETAILS = 'RESET_FETCH_PUBLIC_ASSET_DETAILS';
export const PROCESS_FETCH_PUBLIC_ASSET_DETAILS = 'PROCESS_FETCH_PUBLIC_ASSET_DETAILS';

export const FAVOURITE_ASSETS_SEARCH = 'FAVOURITE_ASSETS_SEARCH';
export const FAVOURITE_ASSETS_SEARCH_FAIL = 'FAVOURITE_ASSETS_SEARCH_FAIL';
export const RESET_FAVOURITE_ASSETS_SEARCH = 'RESET_FAVOURITE_ASSETS_SEARCH';
export const PROCESS_FAVOURITE_ASSETS_SEARCH = 'PROCESS_FAVOURITE_ASSETS_SEARCH';

// DEALS TASKS CASES
export const ADD_DEAL_TASKS = 'ADD_DEAL_TASKS';
export const ADD_DEAL_TASKS_FAIL = 'ADD_DEAL_TASKS_FAIL';
export const RESET_ADD_DEAL_TASKS = 'RESET_ADD_DEAL_TASKS';
export const PROCESS_ADD_DEAL_TASKS = 'PROCESS_ADD_DEAL_TASKS';

export const DELETE_DEAL_TASKS = 'DELETE_DEAL_TASKS';
export const DELETE_DEAL_TASKS_FAIL = 'DELETE_DEAL_TASKS_FAIL';
export const RESET_DELETE_DEAL_TASKS = 'RESET_DELETE_DEAL_TASKS';
export const PROCESS_DELETE_DEAL_TASKS = 'PROCESS_DELETE_DEAL_TASKS';

export const FETCH_DEAL_ALL_TASKS = 'FETCH_DEAL_ALL_TASKS';
export const FETCH_DEAL_ALL_TASKS_FAIL = 'FETCH_DEAL_ALL_TASKS_FAIL';
export const RESET_FETCH_DEAL_ALL_TASKS = 'RESET_FETCH_DEAL_ALL_TASKS';
export const PROCESS_FETCH_DEAL_ALL_TASKS = 'PROCESS_FETCH_DEAL_ALL_TASKS';

export const FETCH_DEAL_INDIVIDUAL_TASKS = 'FETCH_DEAL_INDIVIDUAL_TASKS';
export const FETCH_DEAL_INDIVIDUAL_TASKS_FAIL = 'FETCH_DEAL_INDIVIDUAL_TASKS_FAIL';
export const RESET_FETCH_DEAL_INDIVIDUAL_TASKS = 'RESET_FETCH_DEAL_INDIVIDUAL_TASKS';
export const PROCESS_FETCH_DEAL_INDIVIDUAL_TASKS = 'PROCESS_FETCH_DEAL_INDIVIDUAL_TASKS';

export const UPDATE_DEAL_INDIVIDUAL_TASKS = 'UPDATE_DEAL_INDIVIDUAL_TASKS';
export const UPDATE_DEAL_INDIVIDUAL_TASKS_FAIL = 'UPDATE_DEAL_INDIVIDUAL_TASKS_FAIL';
export const RESET_UPDATE_DEAL_INDIVIDUAL_TASKS = 'RESET_UPDATE_DEAL_INDIVIDUAL_TASKS';
export const PROCESS_UPDATE_DEAL_INDIVIDUAL_TASKS = 'PROCESS_UPDATE_DEAL_INDIVIDUAL_TASKS';

export const FETCH_TASKS_LIST = 'FETCH_TASKS_LIST';
export const FETCH_TASKS_LIST_FAIL = 'FETCH_TASKS_LIST_FAIL';
export const RESET_FETCH_TASKS_LIST = 'RESET_FETCH_TASKS_LIST';
export const PROCESS_FETCH_TASKS_LIST = 'PROCESS_FETCH_TASKS_LIST';

// DELETE FROM NOTE LIST
export const DELETE_FROM_NOTE_LIST = 'DELETE_FROM_NOTE_LIST';
export const DELETE_FROM_NOTE_LIST_FAIL = 'DELETE_FROM_NOTE_LIST_FAIL';
export const RESET_DELETE_FROM_NOTE_LIST = 'RESET_DELETE_FROM_NOTE_LIST';
export const PROCESS_DELETE_FROM_NOTE_LIST = 'PROCESS_DELETE_FROM_NOTE_LIST';

// DELETE DEAL'S NOTES & TASK 
export const DELETE_DEAL_NOTE_FROM_DEAL = 'DELETE_DEAL_NOTE_FROM_DEAL';
export const DELETE_DEAL_NOTE_FROM_DEAL_FAIL = 'DELETE_DEAL_NOTE_FROM_DEAL_FAIL';
export const RESET_DELETE_DEAL_NOTE_FROM_DEAL = 'RESET_DELETE_DEAL_NOTE_FROM_DEAL';
export const PROCESS_DELETE_DEAL_NOTE_FROM_DEAL = 'PROCESS_DELETE_DEAL_NOTE_FROM_DEAL';

export const DELETE_DEAL_TASK_FROM_DEAL = 'DELETE_DEAL_TASK_FROM_DEAL';
export const DELETE_DEAL_TASK_FROM_DEAL_FAIL = 'DELETE_DEAL_TASK_FROM_DEAL_FAIL';
export const RESET_DELETE_DEAL_TASK_FROM_DEAL = 'RESET_DELETE_DEAL_TASK_FROM_DEAL';
export const PROCESS_DELETE_DEAL_TASK_FROM_DEAL = 'PROCESS_DELETE_DEAL_TASK_FROM_DEAL';

export const DELETE_INDIVIDUAL_TASK = 'DELETE_INDIVIDUAL_TASK';
export const DELETE_INDIVIDUAL_TASK_FAIL = 'DELETE_INDIVIDUAL_TASK_FAIL';
export const RESET_DELETE_INDIVIDUAL_TASK = 'RESET_DELETE_INDIVIDUAL_TASK';
export const PROCESS_DELETE_INDIVIDUAL_TASK = 'PROCESS_DELETE_INDIVIDUAL_TASK';

// DELETE DEAL'S FROM ASSETS
export const DELETE_ASSETS_DEAL = 'DELETE_ASSETS_DEAL';
export const DELETE_ASSETS_DEAL_FAIL = 'DELETE_ASSETS_DEAL_FAIL';
export const RESET_DELETE_ASSETS_DEAL = 'RESET_DELETE_ASSETS_DEAL';
export const PROCESS_DELETE_ASSETS_DEAL = 'PROCESS_DELETE_ASSETS_DEAL';

// DELETE DEAL'S FROM DETAILS
export const DELETE_DEAL_FROM_DETAIL = 'DELETE_DEAL_FROM_DETAIL';
export const DELETE_DEAL_FROM_DETAIL_FAIL = 'DELETE_DEAL_FROM_DETAIL_FAIL';
export const RESET_DELETE_DEAL_FROM_DETAIL = 'RESET_DELETE_DEAL_FROM_DETAIL';
export const PROCESS_DELETE_DEAL_FROM_DETAIL = 'PROCESS_DELETE_DEAL_FROM_DETAIL';

// USER PROFILE IMAGE UPLOAD
export const UPLOAD_USER_PROFILE_IMAGE = 'UPLOAD_USER_PROFILE_IMAGE';
export const UPLOAD_USER_PROFILE_IMAGE_FAIL = 'UPLOAD_USER_PROFILE_IMAGE_FAIL';
export const RESET_UPLOAD_USER_PROFILE_IMAGE = 'RESET_UPLOAD_USER_PROFILE_IMAGE';
export const PROCESS_UPLOAD_USER_PROFILE_IMAGE = 'PROCESS_UPLOAD_USER_PROFILE_IMAGE';

export const DELETE_USER_PROFILE_IMAGE = 'DELETE_USER_PROFILE_IMAGE';
export const DELETE_USER_PROFILE_IMAGE_FAIL = 'DELETE_USER_PROFILE_IMAGE_FAIL';
export const RESET_DELETE_USER_PROFILE_IMAGE = 'RESET_DELETE_USER_PROFILE_IMAGE';
export const PROCESS_DELETE_USER_PROFILE_IMAGE = 'PROCESS_DELETE_USER_PROFILE_IMAGE';

// CONTACT PROFILE IMAGE UPLOAD 
export const UPLOAD_CONTACT_PROFILE_IMAGE = 'UPLOAD_CONTACT_PROFILE_IMAGE';
export const UPLOAD_CONTACT_PROFILE_IMAGE_FAIL = 'UPLOAD_CONTACT_PROFILE_IMAGE_FAIL';
export const RESET_UPLOAD_CONTACT_PROFILE_IMAGE = 'RESET_UPLOAD_CONTACT_PROFILE_IMAGE';
export const PROCESS_ADD_CONTACT_IMAGE = 'PROCESS_ADD_CONTACT_IMAGE';

export const DELETE_CONTACT_PROFILE_IMAGE = 'DELETE_CONTACT_PROFILE_IMAGE';
export const DELETE_CONTACT_PROFILE_IMAGE_FAIL = 'DELETE_CONTACT_PROFILE_IMAGE_FAIL';
export const RESET_DELETE_CONTACT_PROFILE_IMAGE = 'RESET_DELETE_CONTACT_PROFILE_IMAGE';
export const PROCESS_DELETE_CONTACT_PROFILE_IMAGE = 'PROCESS_DELETE_CONTACT_PROFILE_IMAGE';

// COMPANY PROFILE IMAGE UPLOAD 
export const UPLOAD_COMPANY_PROFILE_IMAGE = 'UPLOAD_COMPANY_PROFILE_IMAGE';
export const UPLOAD_COMPANY_PROFILE_IMAGE_FAIL = 'UPLOAD_COMPANY_PROFILE_IMAGE_FAIL';
export const RESET_UPLOAD_COMPANY_PROFILE_IMAGE = 'RESET_UPLOAD_COMPANY_PROFILE_IMAGE';
export const PROCESS_ADD_COMPANY_IMAGE = 'PROCESS_ADD_COMPANY_IMAGE';

export const DELETE_COMPANY_PROFILE_IMAGE = 'DELETE_COMPANY_PROFILE_IMAGE';
export const DELETE_COMPANY_PROFILE_IMAGE_FAIL = 'DELETE_COMPANY_PROFILE_IMAGE_FAIL';
export const RESET_DELETE_COMPANY_PROFILE_IMAGE = 'RESET_DELETE_COMPANY_PROFILE_IMAGE';
export const PROCESS_DELETE_COMPANY_PROFILE_IMAGE = 'PROCESS_DELETE_COMPANY_PROFILE_IMAGE';

// ASSETS IMAGE UPLOAD
export const UPLOAD_ASSET_IMAGE = 'UPLOAD_ASSET_IMAGE';
export const UPLOAD_ASSET_IMAGE_FAIL = 'UPLOAD_ASSET_IMAGE_FAIL';
export const RESET_UPLOAD_ASSET_IMAGE = 'RESET_UPLOAD_ASSET_IMAGE';
export const PROCESS_ADD_ASSETS_IMAGE = 'PROCESS_ADD_ASSETS_IMAGE';

export const UPLOAD_DEFAULT_ASSET_IMAGE = 'UPLOAD_DEFAULT_ASSET_IMAGE';
export const UPLOAD_DEFAULT_ASSET_IMAGE_FAIL = 'UPLOAD_DEFAULT_ASSET_IMAGE_FAIL';
export const RESET_UPLOAD_DEFAULT_ASSET_IMAGE = 'RESET_UPLOAD_DEFAULT_ASSET_IMAGE';
export const PROCESS_ADD_ASSETS_DEFAULT_IMAGE = 'PROCESS_ADD_ASSETS_DEFAULT_IMAGE';

export const FETCH_ASSET_IMAGES = 'FETCH_ASSET_IMAGES';
export const FETCH_ASSET_IMAGES_FAIL = 'FETCH_ASSET_IMAGES_FAIL';
export const RESET_FETCH_ASSET_IMAGES = 'RESET_FETCH_ASSET_IMAGES';
export const PROCESS_FETCH_ASSET_IMAGES = 'PROCESS_FETCH_ASSET_IMAGES';

export const DELETE_ASSET_IMAGE = 'DELETE_ASSET_IMAGE';
export const DELETE_ASSET_IMAGE_FAIL = 'DELETE_ASSET_IMAGE_FAIL';
export const RESET_DELETE_ASSET_IMAGE = 'RESET_DELETE_ASSET_IMAGE';
export const PROCESS_DELETE_ASSET_IMAGE = 'PROCESS_DELETE_ASSET_IMAGE';

// NEWS CASES
export const FETCH_NEWS_LIST = 'FETCH_NEWS_LIST';
export const FETCH_NEWS_LIST_FAIL = 'FETCH_NEWS_LIST_FAIL';
export const RESET_FETCH_NEWS_LIST = 'RESET_FETCH_NEWS_LIST';
export const PROCESS_FETCH_NEWS_LIST = 'PROCESS_FETCH_NEWS_LIST';

export const FETCH_INDIVIDUAL_NEWS = 'FETCH_INDIVIDUAL_NEWS';
export const FETCH_INDIVIDUAL_NEWS_FAIL = 'FETCH_INDIVIDUAL_NEWS_FAIL';
export const RESET_FETCH_INDIVIDUAL_NEWS = 'RESET_FETCH_INDIVIDUAL_NEWS';
export const PROCESS_FETCH_INDIVIDUAL_NEWS = 'PROCESS_FETCH_INDIVIDUAL_NEWS';

export const FAVOURITE_NEWS_SEARCH = 'FAVOURITE_NEWS_SEARCH';
export const FAVOURITE_NEWS_SEARCH_FAIL = 'FAVOURITE_NEWS_SEARCH_FAIL';
export const RESET_FAVOURITE_NEWS_SEARCH = 'RESET_FAVOURITE_NEWS_SEARCH';
export const PROCESS_FAVOURITE_NEWS_SEARCH = 'PROCESS_FAVOURITE_NEWS_SEARCH';

// comments
export const FETCH_NEWS_COMMENTS_LIST = 'FETCH_NEWS_COMMENTS_LIST';
export const FETCH_NEWS_COMMENTS_LIST_FAIL = 'FETCH_NEWS_COMMENTS_LIST_FAIL';
export const RESET_FETCH_NEWS_COMMENTS_LIST = 'RESET_FETCH_NEWS_COMMENTS_LIST';
export const PROCESS_FETCH_NEWS_COMMENTS_LIST = 'PROCESS_FETCH_NEWS_COMMENTS_LIST';

export const ADD_NEWS_COMMENTS = 'ADD_NEWS_COMMENTS';
export const ADD_NEWS_COMMENTS_FAIL = 'ADD_NEWS_COMMENTS_FAIL';
export const RESET_ADD_NEWS_COMMENTS = 'RESET_ADD_NEWS_COMMENTS';
export const PROCESS_ADD_NEWS_COMMENTS = 'PROCESS_ADD_NEWS_COMMENTS';

export const FETCH_NEWS_INDIVIDUAL_COMMENTS = 'FETCH_NEWS_INDIVIDUAL_COMMENTS';
export const FETCH_NEWS_INDIVIDUAL_COMMENTS_FAIL = 'FETCH_NEWS_INDIVIDUAL_COMMENTS_FAIL';
export const RESET_FETCH_NEWS_INDIVIDUAL_COMMENTS = 'RESET_FETCH_NEWS_INDIVIDUAL_COMMENTS';
export const PROCESS_FETCH_NEWS_INDIVIDUAL_COMMENTS = 'PROCESS_FETCH_NEWS_INDIVIDUAL_COMMENTS';

export const UPDATE_NEWS_COMMENTS = 'UPDATE_NEWS_COMMENTS';
export const UPDATE_NEWS_COMMENTS_FAIL = 'UPDATE_NEWS_COMMENTS_FAIL';
export const RESET_UPDATE_NEWS_COMMENTS = 'RESET_UPDATE_NEWS_COMMENTS';
export const PROCESS_UPDATE_NEWS_COMMENTS = 'PROCESS_UPDATE_NEWS_COMMENTS';

export const DELETE_NEWS_COMMENTS = 'DELETE_NEWS_COMMENTS';
export const DELETE_NEWS_COMMENTS_FAIL = 'DELETE_NEWS_COMMENTS_FAIL';
export const RESET_DELETE_NEWS_COMMENTS = 'RESET_DELETE_NEWS_COMMENTS';
export const PROCESS_DELETE_NEWS_COMMENTS = 'PROCESS_DELETE_NEWS_COMMENTS';

// NOTICE CASES
export const FETCH_NOTICES_LIST = 'FETCH_NOTICES_LIST';
export const FETCH_NOTICES_LIST_FAIL = 'FETCH_NOTICES_LIST_FAIL';
export const RESET_FETCH_NOTICES_LIST = 'RESET_FETCH_NOTICES_LIST';
export const PROCESS_FETCH_NOTICES_LIST = 'PROCESS_FETCH_NOTICES_LIST';

export const FETCH_INDIVIDUAL_NOTICE = 'FETCH_INDIVIDUAL_NOTICE';
export const FETCH_INDIVIDUAL_NOTICE_FAIL = 'FETCH_INDIVIDUAL_NOTICE_FAIL';
export const RESET_FETCH_INDIVIDUAL_NOTICE = 'RESET_FETCH_INDIVIDUAL_NOTICE';
export const PROCESS_FETCH_INDIVIDUAL_NOTICE = 'PROCESS_FETCH_INDIVIDUAL_NOTICE';

export const FAVOURITE_NOTICES_SEARCH = 'FAVOURITE_NOTICES_SEARCH';
export const FAVOURITE_NOTICES_SEARCH_FAIL = 'FAVOURITE_NOTICES_SEARCH_FAIL';
export const RESET_FAVOURITE_NOTICES_SEARCH = 'RESET_FAVOURITE_NOTICES_SEARCH';
export const PROCESS_FAVOURITE_NOTICES_SEARCH = 'PROCESS_FAVOURITE_NOTICES_SEARCH';

// comments
export const FETCH_NOTICE_COMMENTS_LIST = 'FETCH_NOTICE_COMMENTS_LIST';
export const FETCH_NOTICE_COMMENTS_LIST_FAIL = 'FETCH_NOTICE_COMMENTS_LIST_FAIL';
export const RESET_FETCH_NOTICE_COMMENTS_LIST = 'RESET_FETCH_NOTICE_COMMENTS_LIST';
export const PROCESS_FETCH_NOTICE_COMMENTS_LIST = 'PROCESS_FETCH_NOTICE_COMMENTS_LIST';

export const ADD_NOTICE_COMMENTS = 'ADD_NOTICE_COMMENTS';
export const ADD_NOTICE_COMMENTS_FAIL = 'ADD_NOTICE_COMMENTS_FAIL';
export const RESET_ADD_NOTICE_COMMENTS = 'RESET_ADD_NOTICE_COMMENTS';
export const PROCESS_ADD_NOTICE_COMMENTS = 'PROCESS_ADD_NOTICE_COMMENTS';

export const FETCH_NOTICE_INDIVIDUAL_COMMENTS = 'FETCH_NOTICE_INDIVIDUAL_COMMENTS';
export const FETCH_NOTICE_INDIVIDUAL_COMMENTS_FAIL = 'FETCH_NOTICE_INDIVIDUAL_COMMENTS_FAIL';
export const RESET_FETCH_NOTICE_INDIVIDUAL_COMMENTS = 'RESET_FETCH_NOTICE_INDIVIDUAL_COMMENTS';
export const PROCESS_FETCH_NOTICE_INDIVIDUAL_COMMENTS = 'PROCESS_FETCH_NOTICE_INDIVIDUAL_COMMENTS';

export const UPDATE_NOTICE_COMMENTS = 'UPDATE_NOTICE_COMMENTS';
export const UPDATE_NOTICE_COMMENTS_FAIL = 'UPDATE_NOTICE_COMMENTS_FAIL';
export const RESET_UPDATE_NOTICE_COMMENTS = 'RESET_UPDATE_NOTICE_COMMENTS';
export const PROCESS_UPDATE_NOTICE_COMMENTS = 'PROCESS_UPDATE_NOTICE_COMMENTS';

export const DELETE_NOTICE_COMMENTS = 'DELETE_NOTICE_COMMENTS';
export const DELETE_NOTICE_COMMENTS_FAIL = 'DELETE_NOTICE_COMMENTS_FAIL';
export const RESET_DELETE_NOTICE_COMMENTS = 'RESET_DELETE_NOTICE_COMMENTS';
export const PROCESS_DELETE_NOTICE_COMMENTS = 'PROCESS_DELETE_NOTICE_COMMENTS';

// Feed Back
export const ADD_FEED_BACK = 'ADD_FEED_BACK';
export const ADD_FEED_BACK_FAIL = 'ADD_FEED_BACK_FAIL';
export const RESET_ADD_FEED_BACK = 'RESET_ADD_FEED_BACK';
export const PROCESS_ADD_FEED_BACK = 'PROCESS_ADD_FEED_BACK';

// Poi Info LIST
export const POI_INFO_LIST = 'POI_LIST';
export const POI_INFO_LIST_FAIL = 'POI_LIST_FAIL';
export const RESET_POI_INFO_LIST = 'RESET_POI_LIST';
export const PROCESS_POI_INFO_LIST = 'PROCESS_POI_LIST';

// COMMODITY PROMARY LIST
export const FETCH_COMMODITY_PRIMARY_LIST = 'FETCH_COMMODITY_PRIMARY_LIST';
export const FETCH_COMMODITY_PRIMARY_LIST_FAIL = 'FETCH_COMMODITY_PRIMARY_LIST_FAIL';
export const RESET_FETCH_COMMODITY_PRIMARY_LIST = 'RESET_FETCH_COMMODITY_PRIMARY_LIST';
export const PROCESS_FETCH_COMMODITY_PRIMARY_LIST = 'PROCESS_FETCH_COMMODITY_PRIMARY_LIST';

// COMMODITY VARIETY LIST
export const FETCH_COMMODITY_VARIETY_LIST = 'FETCH_COMMODITY_VARIETY_LIST';
export const FETCH_COMMODITY_VARIETY_LIST_FAIL = 'FETCH_COMMODITY_VARIETY_LIST';
export const RESET_FETCH_COMMODITY_VARIETY_LIST = 'RESET_FETCH_COMMODITY_VARIETY_LIST';
export const PROCESS_FETCH_COMMODITY_VARIETY_LIST = 'PROCESS_FETCH_COMMODITY_VARIETY_LIST';

//PUBLIC MARKET
export const FETCH_PUBLIC_MARKET_LIST = 'FETCH_PUBLIC_MARKET_LIST';
export const FETCH_PUBLIC_MARKET_LIST_FAIL = 'FETCH_PUBLIC_MARKET_LIST_FAIL';
export const RESET_FETCH_PUBLIC_MARKET_LIST = 'RESET_FETCH_PUBLIC_MARKET_LIST';
export const PROCESS_FETCH_PUBLIC_MARKET_LIST = 'PROCESS_FETCH_PUBLIC_MARKET_LIST';

//PUBLIC MARKET DETAILS
export const FETCH_PUBLIC_MARKET_DETAILS = 'FETCH_PUBLIC_MARKET_DETAILS';
export const FETCH_PUBLIC_MARKET_DETAILS_FAIL = 'FETCH_PUBLIC_MARKET_DETAILS_FAIL';
export const RESET_FETCH_PUBLIC_MARKET_DETAILS = 'RESET_FETCH_PUBLIC_MARKET_DETAILS';
export const PROCESS_FETCH_PUBLIC_MARKET_DETAILS = 'PROCESS_FETCH_PUBLIC_MARKET_DETAILS';

//PUBLIC MARKET Comments
export const FETCH_PUBLIC_MARKET_COMMENTS = 'FETCH_PUBLIC_MARKET_COMMENTS';
export const FETCH_PUBLIC_MARKET_COMMENTS_FAIL = 'FETCH_PUBLIC_MARKET_COMMENTS_FAIL';
export const RESET_FETCH_PUBLIC_MARKET_COMMENTS = 'RESET_FETCH_PUBLIC_MARKET_COMMENTS';
export const PROCESS_FETCH_PUBLIC_MARKET_COMMENTS = 'PROCESS_FETCH_PUBLIC_MARKET_COMMENTS';

//ADD PUBLIC MARKET Comments
export const ADD_PUBLIC_MARKET_COMMENTS = 'ADD_PUBLIC_MARKET_COMMENTS';
export const ADD_PUBLIC_MARKET_COMMENTS_FAIL = 'ADD_PUBLIC_MARKET_COMMENTS_FAIL';
export const RESET_ADD_PUBLIC_MARKET_COMMENTS = 'RESET_ADD_PUBLIC_MARKET_COMMENTS';
export const PROCESS_ADD_PUBLIC_MARKET_COMMENTS = 'PROCESS_ADD_PUBLIC_MARKET_COMMENTS';

// UPDATE PUBLIC MARKET Comments
export const UPDATE_PUBLIC_MARKET_COMMENTS = 'UPDATE_PUBLIC_MARKET_COMMENTS';
export const UPDATE_PUBLIC_MARKET_COMMENTS_FAIL = 'UPDATE_PUBLIC_MARKET_COMMENTS_FAIL';
export const RESET_UPDATE_PUBLIC_MARKET_COMMENTS = 'RESET_UPDATE_PUBLIC_MARKET_COMMENTS';
export const PROCESS_UPDATE_PUBLIC_MARKET_COMMENTS = 'PROCESS_UPDATE_PUBLIC_MARKET_COMMENTS';

//DELETE PUBLIC MARKET Comments
export const DELETE_PUBLIC_MARKET_COMMENTS = 'DELETE_PUBLIC_MARKET_COMMENTS';
export const DELETE_PUBLIC_MARKET_COMMENTS_FAIL = 'DELETE_PUBLIC_MARKET_COMMENTS_FAIL';
export const RESET_DELETE_PUBLIC_MARKET_COMMENTS = 'RESET_DELETE_PUBLIC_MARKET_COMMENTS';
export const PROCESS_DELETE_PUBLIC_MARKET_COMMENTS = 'PROCESS_DELETE_PUBLIC_MARKET_COMMENTS';

//COMMODITY VARIETY BY MARKET PLACE
export const FETCH_CV_BY_PM = 'FETCH_CV_BY_PM';
export const FETCH_CV_BY_PM_FAIL = 'FETCH_CV_BY_PM_FAIL';
export const RESET_FETCH_CV_BY_PM = 'RESET_FETCH_CV_BY_PM';
export const PROCESS_FETCH_CV_BY_PM = 'PROCESS_FETCH_CV_BY_PM';

//COMMODITY VARIETY BY MARKET PLACE
export const FETCH_CV_BY_PM_DETAILS = 'FETCH_CV_BY_PM_DETAILS';
export const FETCH_CV_BY_PM_DETAILS_FAIL = 'FETCH_CV_BY_PM_DETAILS_FAIL';
export const RESET_FETCH_CV_BY_PM_DETAILS = 'RESET_FETCH_CV_BY_PM_DETAILS';
export const PROCESS_FETCH_CV_BY_PM_DETAILS = 'PROCESS_FETCH_CV_BY_PM_DETAILS';

//ADD CVBYPM COMMENT
export const ADD_CV_BY_PM_COMMENTS = 'ADD_CV_BY_PM_COMMENTS';
export const ADD_CV_BY_PM_COMMENTS_FAIL = 'ADD_CV_BY_PM_COMMENTS_FAIL';
export const RESET_ADD_CV_BY_PM_COMMENTS = 'RESET_ADD_CV_BY_PM_COMMENTS';
export const PROCESS_ADD_CV_BY_PM_COMMENTS = 'PROCESS_ADD_CV_BY_PM_COMMENTS';

//FETCH CVBYPM COMMENT
export const FETCH_CV_BY_PM_COMMENTS = 'FETCH_CV_BY_PM_COMMENTS';
export const FETCH_CV_BY_PM_COMMENTS_FAIL = 'FETCH_CV_BY_PM_COMMENTS_FAIL';
export const RESET_FETCH_CV_BY_PM_COMMENTS = 'RESET_FETCH_CV_BY_PM_COMMENTS';
export const PROCESS_FETCH_CV_BY_PM_COMMENTS = 'PROCESS_FETCH_CV_BY_PM_COMMENTS';

//UPDATE CVBYPM COMMENT
export const UPDATE_CV_BY_PM_COMMENTS = 'UPDATE_CV_BY_PM_COMMENTS';
export const UPDATE_CV_BY_PM_COMMENTS_FAIL = 'UPDATE_CV_BY_PM_COMMENTS_FAIL';
export const RESET_UPDATE_CV_BY_PM_COMMENTS = 'RESET_UPDATE_CV_BY_PM_COMMENTS';
export const PROCESS_UPDATE_CV_BY_PM_COMMENTS = 'PROCESS_UPDATE_CV_BY_PM_COMMENTS';

//DELETE CVBYPM COMMENT
export const DELETE_CV_BY_PM_COMMENTS = 'DELETE_CV_BY_PM_COMMENTS';
export const DELETE_CV_BY_PM_COMMENTS_FAIL = 'DELETE_CV_BY_PM_COMMENTS_FAIL';
export const RESET_DELETE_CV_BY_PM_COMMENTS = 'RESET_DELETE_CV_BY_PM_COMMENTS';
export const PROCESS_DELETE_CV_BY_PM_COMMENTS = 'PROCESS_DELETE_CV_BY_PM_COMMENTS';

//ADD TRADE
export const ADD_TRADE = 'ADD_TRADE';
export const ADD_TRADE_FAIL = 'ADD_TRADE_FAIL';
export const RESET_ADD_TRADE = 'RESET_ADD_TRADE';
export const PROCESS_ADD_TRADE = 'PROCESS_ADD_TRADE';

//UPDATE TRADE
export const UPDATE_TRADE = 'UPDATE_TRADE';
export const UPDATE_TRADE_FAIL = 'UPDATE_TRADE_FAIL';
export const RESET_UPDATE_TRADE = 'RESET_UPDATE_TRADE';
export const PROCESS_UPDATE_TRADE = 'PROCESS_UPDATE_TRADE';

//DELETE TRADE
export const DELETE_TRADE = 'DELETE_TRADE';
export const DELETE_TRADE_FAIL = 'DELETE_TRADE_FAIL';
export const RESET_DELETE_TRADE = 'RESET_DELETE_TRADE';
export const PROCESS_DELETE_TRADE = 'PROCESS_DELETE_TRADE';

//UPDATE TRADE LOCATION
export const UPDATE_TRADE_LOCATION = 'UPDATE_TRADE_LOCATION';
export const UPDATE_TRADE_LOCATION_FAIL = 'UPDATE_TRADE_LOCATION_FAIL';
export const RESET_UPDATE_TRADE_LOCATION = 'RESET_UPDATE_TRADE_LOCATION';
export const PROCESS_UPDATE_TRADE_LOCATION = 'PROCESS_UPDATE_TRADE_LOCATION';

//UPDATE TRADE PUBLIC
export const UPDATE_TRADE_PUBLIC = 'UPDATE_TRADE_PUBLIC';
export const UPDATE_TRADE_PUBLIC_FAIL = 'UPDATE_TRADE_PUBLIC_FAIL';
export const RESET_UPDATE_TRADE_PUBLIC = 'RESET_UPDATE_TRADE_PUBLIC';
export const PROCESS_UPDATE_TRADE_PUBLIC = 'PROCESS_UPDATE_TRADE_PUBLIC';

//UPDATE TRADE PROPERTY
export const UPDATE_TRADE_PROPERTY = 'UPDATE_TRADE_PROPERTY';
export const UPDATE_TRADE_PROPERTY_FAIL = 'UPDATE_TRADE_PROPERTY_FAIL';
export const RESET_UPDATE_TRADE_PROPERTY = 'RESET_UPDATE_TRADE_PROPERTY';
export const PROCESS_UPDATE_TRADE_PROPERTY = 'PROCESS_UPDATE_TRADE_PROPERTY';

//FETCH BRIEF TRADES LIST
export const FETCH_BRIEF_TRADE_LIST = 'FETCH_BRIEF_TRADE_LIST';
export const FETCH_BRIEF_TRADE_LIST_FAIL = 'FETCH_BRIEF_TRADE_LIST_FAIL';
export const RESET_FETCH_BRIEF_TRADE_LIST = 'RESET_FETCH_BRIEF_TRADE_LIST';
export const PROCESS_FETCH_BRIEF_TRADE_LIST = 'PROCESS_FETCH_BRIEF_TRADE_LIST';

//FETCH TRADES DETAIL
export const FETCH_TRADE_DETAIL = 'FETCH_TRADE_DETAIL';
export const FETCH_TRADE_DETAIL_FAIL = 'FETCH_TRADE_DETAIL_FAIL';
export const RESET_FETCH_TRADE_DETAIL = 'RESET_FETCH_TRADE_DETAIL';
export const PROCESS_FETCH_TRADE_DETAIL = 'PROCESS_FETCH_TRADE_DETAIL';

//FETCH FAVORITE TRADES
export const FETCH_FAVORITE_TRADE = 'FETCH_FAVORITE_TRADE';
export const FETCH_FAVORITE_TRADE_FAIL = 'FETCH_FAVORITE_TRADE_FAIL';
export const RESET_FETCH_FAVORITE_TRADE = 'RESET_FETCH_FAVORITE_TRADE';
export const PROCESS_FETCH_FAVORITE_TRADE = 'PROCESS_FETCH_FAVORITE_TRADE';

// FETCH FAVORITE CVbyPM
// export const FETCH_FAVORITE_CV_BY_PM = 'FETCH_FAVORITE_CV_BY_PM';
// export const FETCH_FAVORITE_CV_BY_PM_FAIL = 'FETCH_FAVORITE_CV_BY_PM_FAIL';
// export const RESET_FETCH_FAVORITE_CV_BY_PM = 'RESET_FETCH_FAVORITE_CV_BY_PM';
// export const PROCESS_FETCH_FAVORITE_CV_BY_PM = 'PROCESS_FETCH_FAVORITE_CV_BY_PM';

//TRADE IMAGE UPLOAD
export const UPLOAD_TRADE_IMAGE = 'UPLOAD_TRADE_IMAGE';
export const UPLOAD_TRADE_IMAGE_FAIL = 'UPLOAD_TRADE_IMAGE_FAIL';
export const RESET_UPLOAD_TRADE_IMAGE = 'RESET_UPLOAD_TRADE_IMAGE';
export const PROCESS_UPLOAD_TRADE_IMAGE = 'PROCESS_UPLOAD_TRADE_IMAGE';

//FETCH TRADE IMAGES
export const FETCH_USER_TRADE_IMAGE = 'FETCH_USER_TRADE_IMAGE';
export const FETCH_USER_TRADE_IMAGE_FAIL = 'FETCH_USER_TRADE_IMAGE_FAIL';
export const RESET_FETCH_USER_TRADE_IMAGE = 'RESET_FETCH_USER_TRADE_IMAGE';
export const PROCESS_FETCH_USER_TRADE_IMAGE = 'PROCESS_FETCH_USER_TRADE_IMAGE';

//FETCH USER TRADES
export const FETCH_USER_TRADE_LIST = 'FETCH_USER_TRADE_LIST';
export const FETCH_USER_TRADE_LIST_FAIL = 'FETCH_USER_TRADE_LIST_FAIL';
export const RESET_FETCH_USER_TRADE_LIST = 'RESET_FETCH_USER_TRADE_LIST';
export const PROCESS_FETCH_USER_TRADE_LIST = 'PROCESS_FETCH_USER_TRADE_LIST';

//FETCH USER TRADES DETAIL
export const FETCH_USER_TRADE_DETAIL = 'FETCH_USER_TRADE_DETAIL';
export const FETCH_USER_TRADE_DETAIL_FAIL = 'FETCH_USER_TRADE_DETAIL_FAIL';
export const RESET_FETCH_USER_TRADE_DETAIL = 'RESET_FETCH_USER_TRADE_DETAIL';
export const PROCESS_FETCH_USER_TRADE_DETAIL = 'PROCESS_FETCH_USER_TRADE_DETAIL';

//FETCH FAVORITE PUBLICMARKET
export const FETCH_FAVORITE_PUBLIC_MARKET = 'FETCH_FAVORITE_PUBLIC_MARKET';
export const FETCH_FAVORITE_PUBLIC_MARKET_FAIL = 'FETCH_FAVORITE_PUBLIC_MARKET_FAIL';
export const RESET_FETCH_FAVORITE_PUBLIC_MARKET = 'RESET_FETCH_FAVORITE_PUBLIC_MARKET';
export const PROCESS_FETCH_FAVORITE_PUBLIC_MARKET = 'PROCESS_FETCH_FAVORITE_PUBLIC_MARKET';

//FETCH FAVORITE COMMODITY
export const FETCH_FAVORITE_COMMODITY = 'FETCH_FAVORITE_COMMODITY';
export const FETCH_FAVORITE_COMMODITY_FAIL = 'FETCH_FAVORITE_COMMODITY_FAIL';
export const RESET_FETCH_FAVORITE_COMMODITY = 'RESET_FETCH_FAVORITE_COMMODITY';
export const PROCESS_FETCH_FAVORITE_COMMODITY = 'PROCESS_FETCH_FAVORITE_COMMODITY';

//DELETE TRADE IMAGE
export const DELETE_TRADE_IMAGE = 'DELETE_TRADE_IMAGE';
export const DELETE_TRADE_IMAGE_FAIL = 'DELETE_TRADE_IMAGE_FAIL';
export const RESET_DELETE_TRADE_IMAGE = 'RESET_DELETE_TRADE_IMAGE';
export const PROCESS_DELETE_TRADE_IMAGE = 'PROCESS_DELETE_TRADE_IMAGE';

//FETCH TRADE IMAGES
export const FETCH_TRADE_IMAGE = 'FETCH_TRADE_IMAGE';
export const FETCH_TRADE_IMAGE_FAIL = 'FETCH_TRADE_IMAGE_FAIL';
export const RESET_FETCH_TRADE_IMAGE = 'RESET_FETCH_TRADE_IMAGE';
export const PROCESS_FETCH_TRADE_IMAGE = 'PROCESS_FETCH_TRADE_IMAGE';

//FETCH DEFAULT MARKET
export const FETCH_DEFAULT_MARKET = 'FETCH_DEFAULT_MARKET';
export const FETCH_DEFAULT_MARKET_FAIL = 'FETCH_DEFAULT_MARKET_FAIL';
export const RESET_FETCH_DEFAULT_MARKET = 'RESET_FETCH_DEFAULT_MARKET';
export const PROCESS_FETCH_DEFAULT_MARKET = 'PROCESS_FETCH_DEFAULT_MARKET';

//FETCH DEFAULT SUBDISTRICT
export const FETCH_DEFAULT_SUBDISTRICT = 'FETCH_DEFAULT_SUBDISTRICT';
export const FETCH_DEFAULT_SUBDISTRICT_FAIL = 'FETCH_DEFAULT_SUBDISTRICT_FAIL';
export const RESET_FETCH_DEFAULT_SUBDISTRICT = 'RESET_FETCH_DEFAULT_SUBDISTRICT';
export const PROCESS_FETCH_DEFAULT_SUBDISTRICT = 'PROCESS_FETCH_DEFAULT_SUBDISTRICT';

//DELETE DEFAULT MARKET
export const DELETE_DEFAULT_MARKET = 'DELETE_DEFAULT_MARKET';
export const DELETE_DEFAULT_MARKET_FAIL = 'DELETE_DEFAULT_MARKET_FAIL';
export const RESET_DELETE_DEFAULT_MARKET = 'RESET_DELETE_DEFAULT_MARKET';
export const PROCESS_DELETE_DEFAULT_MARKET = 'PROCESS_DELETE_DEFAULT_MARKET';

//DELETE DEFAULT SUBDISTRICT
export const DELETE_DEFAULT_SUBDISTRICT = 'DELETE_DEFAULT_SUBDISTRICT';
export const DELETE_DEFAULT_SUBDISTRICT_FAIL = 'DELETE_DEFAULT_SUBDISTRICT_FAIL';
export const RESET_DELETE_DEFAULT_SUBDISTRICT = 'RESET_DELETE_DEFAULT_SUBDISTRICT';
export const PROCESS_DELETE_DEFAULT_SUBDISTRICT = 'PROCESS_DELETE_DEFAULT_SUBDISTRICT';

//ADD DEFAULT MARKET
export const ADD_DEFAULT_MARKET = 'ADD_DEFAULT_MARKET';
export const ADD_DEFAULT_MARKET_FAIL = 'ADD_DEFAULT_MARKET_FAIL';
export const RESET_ADD_DEFAULT_MARKET = 'RESET_ADD_DEFAULT_MARKET';
export const PROCESS_ADD_DEFAULT_MARKET = 'PROCESS_ADD_DEFAULT_MARKET';

//ADD DEFAULT SUBDISTRICT
export const ADD_DEFAULT_SUBDISTRICT = 'ADD_DEFAULT_SUBDISTRICT';
export const ADD_DEFAULT_SUBDISTRICT_FAIL = 'ADD_DEFAULT_SUBDISTRICT_FAIL';
export const RESET_ADD_DEFAULT_SUBDISTRICT = 'RESET_ADD_DEFAULT_SUBDISTRICT';
export const PROCESS_ADD_DEFAULT_SUBDISTRICT = 'PROCESS_ADD_DEFAULT_SUBDISTRICT';

// ADD COMPANY STORE
export const ADD_COMPANY_STORE = 'ADD_COMPANY_STORE';
export const ADD_COMPANY_STORE_FAIL = 'ADD_COMPANY_STORE_FAIL';
export const RESET_ADD_COMPANY_STORE = 'RESET_ADD_COMPANY_STORE';
export const PROCESS_ADD_COMPANY_STORE = 'PROCESS_ADD_COMPANY_STORE';

// UPDATE COMPANY STORE
export const UPDATE_COMPANY_STORE = ' UPDATE_COMPANY_STORE';
export const UPDATE_COMPANY_STORE_FAIL = ' UPDATE_COMPANY_STORE_FAIL';
export const RESET_UPDATE_COMPANY_STORE = 'RESET_UPDATE_COMPANY_STORE';
export const PROCESS_UPDATE_COMPANY_STORE = 'PROCESS_UPDATE_COMPANY_STORE';

//FETCH COMPANY DETAILS
export const FETCH_COMPANY_STORE_DETAILS = 'FETCH_COMPANY_STORE_DETAILS';
export const FETCH_COMPANY_STORE_DETAILS_FAIL = 'FETCH_COMPANY_STORE_DETAILS_FAIL';
export const RESET_FETCH_COMPANY_STORE_DETAILS = 'RESET_FETCH_COMPANY_STORE_DETAILS';
export const PROCESS_FETCH_COMPANY_STORE_DETAILS = 'PROCESS_FETCH_COMPANY_STORE_DETAILS';

//FETCH COMPANY TRADE LIST
export const FETCH_COMPANY_STORE_TRADE = 'FETCH_COMPANY_STORE_TRADE';
export const FETCH_COMPANY_STORE_TRADE_FAIL = 'FETCH_COMPANY_STORE_TRADE_FAIL';
export const RESET_FETCH_COMPANY_STORE_TRADE = 'RESET_FETCH_COMPANY_STORE_TRADE';
export const PROCESS_FETCH_COMPANY_STORE_TRADE = 'PROCESS_FETCH_COMPANY_STORE_TRADE';

//FETCH USER COMPANY STORE
export const FETCH_USER_COMPANY_STORE = 'FETCH_USER_COMPANY_STORE';
export const FETCH_USER_COMPANY_STORE_FAIL = 'FETCH_USER_COMPANY_STORE_FAIL';
export const RESET_FETCH_USER_COMPANY_STORE = 'RESET_FETCH_USER_COMPANY_STORE';
export const PROCESS_FETCH_USER_COMPANY_STORE = 'PROCESS_FETCH_USER_COMPANY_STORE';

//FOLLOW COMPANY STORE
export const FOLLOW_COMPANY_STORE = 'FOLLOW_COMPANY_STORE';
export const FOLLOW_COMPANY_STORE_FAIL = 'FOLLOW_COMPANY_STORE_FAIL';
export const RESET_FOLLOW_COMPANY_STORE = 'RESET_FOLLOW_COMPANY_STORE';
export const PROCESS_FOLLOW_COMPANY_STORE = 'PROCESS_FOLLOW_COMPANY_STORE';

//UPLOAD COMPANY STORE PROFILE IMAGE
export const UPLOAD_COMPANY_STORE_PROFILE_IMAGE = 'UPLOAD_COMPANY_STORE_PROFILE_IMAGE';
export const UPLOAD_COMPANY_STORE_PROFILE_IMAGE_FAIL = 'UPLOAD_COMPANY_STORE_PROFILE_IMAGE_FAIL';
export const RESET_UPLOAD_COMPANY_STORE_PROFILE_IMAGE = 'RESET_UPLOAD_COMPANY_STORE_PROFILE_IMAGE';
export const PROCESS_UPLOAD_COMPANY_STORE_PROFILE_IMAGE = 'PROCESS_UPLOAD_COMPANY_STORE_PROFILE_IMAGE';

// //FETCH USER COMPANY STORE PROFILE
// export const FETCH_USER_COMPANY_STORE_PROFILE = 'FETCH_USER_COMPANY_STORE_PROFILE';
// export const FETCH_USER_COMPANY_STORE_PROFILE_FAIL = 'FETCH_USER_COMPANY_STORE_PROFILE_FAIL';
// export const RESET_FETCH_USER_COMPANY_STORE_PROFILE = 'RESET_FETCH_USER_COMPANY_STORE_PROFILE';
// export const PROCESS_FETCH_USER_COMPANY_STORE_PROFILE = 'PROCESS_FETCH_USER_COMPANY_STORE_PROFILE';

//UPLOAD COMPANY STORE COVER IMAGE
export const UPLOAD_COMPANY_STORE_COVER_IMAGE = 'UPLOAD_COMPANY_STORE_COVER_IMAGE';
export const UPLOAD_COMPANY_STORE_COVER_IMAGE_FAIL = 'UPLOAD_COMPANY_STORE_COVER_IMAGE_FAIL';
export const RESET_UPLOAD_COMPANY_STORE_COVER_IMAGE = 'RESET_UPLOAD_COMPANY_STORE_COVER_IMAGE';
export const PROCESS_UPLOAD_COMPANY_STORE_COVER_IMAGE = 'PROCESS_UPLOAD_COMPANY_STORE_COVER_IMAGE';

//FETCH USER FOLLOW STORE
export const FETCH_USER_COMPANY_FOLLOWES_LIST = 'FETCH_USER_COMPANY_FOLLOWES_LIST';
export const FETCH_USER_COMPANY_FOLLOWES_LIST_FAIL = 'FETCH_USER_COMPANY_FOLLOWES_LIST_FAIL';
export const RESET_FETCH_USER_COMPANY_FOLLOWES_LIST = 'RESET_FETCH_USER_COMPANY_FOLLOWES_LIST';
export const PROCESS_FETCH_USER_COMPANY_FOLLOWES_LIST = 'PROCESS_FETCH_USER_COMPANY_FOLLOWES_LIST';

//FETCH CPMPANY STORE MEMBERSHIP
export const FETCH_COMPANY_STORE_MEMBERSHIP_LIST = 'FETCH_COMPANY_STORE_MEMBERSHIP_LIST';
export const FETCH_COMPANY_STORE_MEMBERSHIP_LIST_FAIL = 'FETCH_COMPANY_STORE_MEMBERSHIP_LIST_FAIL';
export const RESET_FETCH_COMPANY_STORE_MEMBERSHIP_LIST = 'RESET_FETCH_COMPANY_STORE_MEMBERSHIP_LIST';
export const PROCESS_FETCH_COMPANY_STORE_MEMBERSHIP_LIST = 'PROCESS_FETCH_COMPANY_STORE_MEMBERSHIP_LIST';

//EMAIL VERIFICATION
export const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION';
export const SEND_EMAIL_VERIFICATION_FAIL = 'SEND_EMAIL_VERIFICATION_FAIL';
export const RESET_SEND_EMAIL_VERIFICATION = 'RESET_SEND_EMAIL_VERIFICATION';
export const PROCESS_SEND_EMAIL_VERIFICATION = 'PROCESS_SEND_EMAIL_VERIFICATION';

//FETCH EMAIL VERIFICATION
export const RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION';
export const RESEND_EMAIL_VERIFICATION_FAIL = 'RESEND_EMAIL_VERIFICATION_FAIL';
export const RESET_RESEND_EMAIL_VERIFICATION = 'RESET_RESEND_EMAIL_VERIFICATION';
export const PROCESS_RESEND_EMAIL_VERIFICATION = 'PROCESS_RESEND_EMAIL_VERIFICATION';

// V3 CART
export const ADD_CART = 'ADD_CART';
export const ADD_CART_FAIL = 'ADD_CART_FAIL';
export const RESET_ADD_CART = 'RESET_ADD_CART';
export const PROCESS_ADD_CART = 'PROCESS_ADD_CART';

export const DELETE_CART = 'DELETE_CART';
export const DELETE_CART_FAIL = 'DELETE_CART_FAIL';
export const RESET_DELETE_CART = 'RESET_DELETE_CART';
export const PROCESS_DELETE_CART = 'PROCESS_DELETE_CART';

export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_FAIL = 'UPDATE_CART_FAIL';
export const RESET_UPDATE_CART = 'RESET_UPDATE_CART';
export const PROCESS_UPDATE_CART = 'PROCESS_UPDATE_CART';

export const FETCH_CART = 'FETCH_CART';
export const FETCH_CART_FAIL = 'FETCH_CART_FAIL';
export const RESET_FETCH_CART = 'RESET_FETCH_CART';
export const PROCESS_FETCH_CART = 'PROCESS_FETCH_CART';

// SAVE FOR LATER
export const SAVE_FOR_LATER = 'SAVE_FOR_LATER';
export const SAVE_FOR_LATER_FAIL = 'SAVE_FOR_LATER_FAIL';
export const RESET_SAVE_FOR_LATER = 'RESET_SAVE_FOR_LATER';
export const PROCESS_SAVE_FOR_LATER = 'PROCESS_SAVE_FOR_LATER';

export const DELETE_SAVE_FOR_LATER = 'DELETE_SAVE_FOR_LATER';
export const DELETE_SAVE_FOR_LATER_FAIL = 'DELETE_SAVE_FOR_LATER_FAIL';
export const RESET_DELETE_SAVE_FOR_LATER = 'RESET_DELETE_SAVE_FOR_LATER';
export const PROCESS_DELETE_SAVE_FOR_LATER = 'PROCESS_DELETE_SAVE_FOR_LATER';

export const MOVE_SAVE_FOR_LATER = 'MOVE_SAVE_FOR_LATER';
export const MOVE_SAVE_FOR_LATER_FAIL = 'MOVE_SAVE_FOR_LATER_FAIL';
export const RESET_MOVE_SAVE_FOR_LATER = 'RESET_MOVE_SAVE_FOR_LATER';
export const PROCESS_MOVE_SAVE_FOR_LATER = 'PROCESS_MOVE_SAVE_FOR_LATER';

export const FETCH_SAVE_FOR_LATER = 'FETCH_SAVE_FOR_LATER';
export const FETCH_SAVE_FOR_LATER_FAIL = 'FETCH_SAVE_FOR_LATER_FAIL';
export const RESET_FETCH_SAVE_FOR_LATER = 'RESET_FETCH_SAVE_FOR_LATER';
export const PROCESS_FETCH_SAVE_FOR_LATER = 'PROCESS_FETCH_SAVE_FOR_LATER';

// USER ADDRESS
export const FETCH_USER_ADDRESS = 'FETCH_USER_ADDRESS';
export const FETCH_USER_ADDRESS_FAIL = 'FETCH_USER_ADDRESS_FAIL';
export const RESET_FETCH_USER_ADDRESS = 'RESET_FETCH_USER_ADDRESS';
export const PROCESS_FETCH_USER_ADDRESS = 'PROCESS_FETCH_USER_ADDRESS';

// USER BILLING ADDRESS
export const FETCH_USER_BILLING_ADDRESS = 'FETCH_USER_BILLING_ADDRESS';
export const FETCH_USER_BILLING_ADDRESS_FAIL = 'FETCH_USER_BILLING_ADDRESS_FAIL';
export const RESET_FETCH_USER_BILLING_ADDRESS = 'RESET_FETCH_USER_BILLING_ADDRESS';
export const PROCESS_FETCH_USER_BILLING_ADDRESS = 'PROCESS_FETCH_USER_BILLING_ADDRESS';

// USER SHIPPING ADDRESS
export const FETCH_USER_SHIPPING_ADDRESS = 'FETCH_USER_SHIPPING_ADDRESS';
export const FETCH_USER_SHIPPING_ADDRESS_FAIL = 'FETCH_USER_SHIPPING_ADDRESS_FAIL';
export const RESET_FETCH_USER_SHIPPING_ADDRESS = 'RESET_FETCH_USER_SHIPPING_ADDRESS';
export const PROCESS_FETCH_USER_SHIPPING_ADDRESS = 'PROCESS_FETCH_USER_SHIPPING_ADDRESS';

export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export const ADD_USER_ADDRESS_FAIL = 'ADD_USER_ADDRESS_FAIL';
export const RESET_ADD_USER_ADDRESS = 'RESET_ADD_USER_ADDRESS';
export const PROCESS_ADD_USER_ADDRESS = 'PROCESS_ADD_USER_ADDRESS';

export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_FAIL = 'DELETE_USER_ADDRESS_FAIL';
export const RESET_DELETE_USER_ADDRESS = 'RESET_DELETE_USER_ADDRESS';
export const PROCESS_DELETE_USER_ADDRESS = 'PROCESS_DELETE_USER_ADDRESS';

export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const UPDATE_USER_ADDRESS_FAIL = 'UPDATE_USER_ADDRESS_FAIL';
export const RESET_UPDATE_USER_ADDRESS = 'RESET_UPDATE_USER_ADDRESS';
export const PROCESS_UPDATE_USER_ADDRESS = 'PROCESS_UPDATE_USER_ADDRESS';

export const FETCH_INDIVIDUAL_USER_ADDRESS = 'FETCH_INDIVIDUAL_USER_ADDRESS';
export const FETCH_INDIVIDUAL_USER_ADDRESS_FAIL = 'FETCH_INDIVIDUAL_USER_ADDRESS_FAIL';
export const RESET_FETCH_INDIVIDUAL_USER_ADDRESS = 'RESET_FETCH_INDIVIDUAL_USER_ADDRESS';
export const PROCESS_FETCH_INDIVIDUAL_USER_ADDRESS = 'PROCESS_FETCH_INDIVIDUAL_USER_ADDRESS';

export const SET_AS_DEFAULT_ADDRESS = 'SET_AS_DEFAULT_ADDRESS';
export const SET_AS_DEFAULT_ADDRESS_FAIL = 'SET_AS_DEFAULT_ADDRESS_FAIL';
export const RESET_SET_AS_DEFAULT_ADDRESS = 'RESET_SET_AS_DEFAULT_ADDRESS';
export const PROCESS_SET_AS_DEFAULT_ADDRESS = 'PROCESS_SET_AS_DEFAULT_ADDRESS';

export const USER_LAT_LONG = 'USER_LAT_LONG';
export const USER_LAT_LONG_FAIL = 'USER_LAT_LONG_FAIL';
export const RESET_USER_LAT_LONG = 'RESET_USER_LAT_LONG';
export const PROCESS_USER_LAT_LONG = 'PROCESS_USER_LAT_LONG';

// FETCH COMPANY STORE LIST
export const FETCH_COMPANY_LIST = 'FETCH_COMPANY_LIST';
export const FETCH_COMPANY_LIST_FAIL = 'FETCH_COMPANY_LIST_FAIL';
export const RESET_FETCH_COMPANY_LIST = 'RESET_FETCH_COMPANY_LIST';
export const PROCESS_FETCH_COMPANY_LIST = 'PROCESS_FETCH_COMPANY_LIST';

// CHECKOUT 
export const CART_CHECKOUT = 'CART_CHECKOUT';
export const CART_CHECKOUT_FAIL = 'CART_CHECKOUT_FAIL';
export const RESET_CART_CHECKOUT = 'RESET_CART_CHECKOUT';
export const PROCESS_CART_CHECKOUT = 'PROCESS_CART_CHECKOUT';

export const BASKET_CHECKOUT = 'BASKET_CHECKOUT';
export const BASKET_CHECKOUT_FAIL = 'BASKET_CHECKOUT_FAIL';
export const RESET_BASKET_CHECKOUT = 'RESET_BASKET_CHECKOUT';
export const PROCESS_BASKET_CHECKOUT = 'PROCESS_BASKET_CHECKOUT';

export const UPDATE_BASKET_CHECKOUT = 'UPDATE_BASKET_CHECKOUT';
export const UPDATE_BASKET_CHECKOUT_FAIL = 'UPDATE_BASKET_CHECKOUT_FAIL';
export const RESET_UPDATE_BASKET_CHECKOUT = 'RESET_UPDATE_BASKET_CHECKOUT';
export const PROCESS_UPDATE_BASKET_CHECKOUT = 'PROCESS_UPDATE_BASKET_CHECKOUT';

export const UPDATE_CART_CHECKOUT = 'UPDATE_CART_CHECKOUT';
export const UPDATE_CART_CHECKOUT_FAIL = 'UPDATE_CART_CHECKOUT_FAIL';
export const RESET_UPDATE_CART_CHECKOUT = 'RESET_UPDATE_CART_CHECKOUT';
export const PROCESS_UPDATE_CART_CHECKOUT = 'PROCESS_UPDATE_CART_CHECKOUT';

// PLACE ORDER
export const PLACE_BASKET_ORDER = 'PLACE_BASKET_ORDER';
export const PLACE_BASKET_ORDER_FAIL = 'PLACE_BASKET_ORDER_FAIL';
export const RESET_PLACE_BASKET_ORDER = 'RESET_PLACE_BASKET_ORDER';
export const PROCESS_PLACE_BASKET_ORDER = 'PROCESS_PLACE_BASKET_ORDER';

export const PLACE_CART_ORDER = 'PLACE_CART_ORDER';
export const PLACE_CART_ORDER_FAIL = 'PLACE_CART_ORDER_FAIL';
export const RESET_PLACE_CART_ORDER = 'RESET_PLACE_CART_ORDER';
export const PROCESS_PLACE_CART_ORDER = 'PROCESS_PLACE_CART_ORDER';

export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';
export const RESET_ORDER_LIST = 'RESET_ORDER_LIST';
export const PROCESS_ORDER_LIST = 'PROCESS_ORDER_LIST';

export const ORDER_DETAIL = 'ORDER_DETAIL';
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL';
export const RESET_ORDER_DETAIL = 'RESET_ORDER_DETAIL';
export const PROCESS_ORDER_DETAIL = 'PROCESS_ORDER_DETAIL';

// TRADE INQUIRY
export const ADD_TRADE_INQUIRY = 'ADD_TRADE_INQUIRY';
export const ADD_TRADE_INQUIRY_FAIL = 'ADD_TRADE_INQUIRY_FAIL';
export const RESET_ADD_TRADE_INQUIRY = 'RESET_ADD_TRADE_INQUIRY';
export const PROCESS_ADD_TRADE_INQUIRY = 'PROCESS_ADD_TRADE_INQUIRY';

// REGISTER-NOTIFICATION
export const REGISTER_FOR_NOTIFICATION = 'REGISTER_FOR_NOTIFICATION';
export const REGISTER_FOR_NOTIFICATION_FAIL = 'REGISTER_FOR_NOTIFICATION_FAIL';
export const RESET_REGISTER_FOR_NOTIFICATION = 'RESET_REGISTER_FOR_NOTIFICATION';
export const PROCESS_REGISTER_FOR_NOTIFICATION = 'PROCESS_REGISTER_FOR_NOTIFICATION';

// DEREGISTER-NOTIFICATION
export const DEREGISTER_FOR_NOTIFICATION = 'DEREGISTER_FOR_NOTIFICATION';
export const DEREGISTER_FOR_NOTIFICATION_FAIL = 'DEREGISTER_FOR_NOTIFICATION_FAIL';
export const RESET_DEREGISTER_FOR_NOTIFICATION = 'RESET_DEREGISTER_FOR_NOTIFICATION';
export const PROCESS_DEREGISTER_FOR_NOTIFICATION = 'PROCESS_DEREGISTER_FOR_NOTIFICATION';