import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
    ADD_DEAL_MESSAGE,
    UPDATE_DEAL_MESSAGE,
    DELETE_DEAL_MESSAGE
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';

const initialState = {
    reLoad: false,
    _addDealProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteDealProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualDealProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateIndividualDealProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchBriefDealProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
}

export default function deals(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.ADD_DEAL:
            toast.success(ADD_DEAL_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _addDealProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_DEAL_FAIL:
            return {
                ...state,
                _addDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_DEAL:
            return {
                ...state,
                _addDealProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_DEAL:
            return {
                ...state,
                _addDealProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_DEAL:
            toast.success(DELETE_DEAL_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _deleteDealProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                },
            }

        case CallerConstants.DELETE_DEAL_FAIL:
            return {
                ...state,
                _deleteDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_DEAL:
            return {
                ...state,
                _deleteDealProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_DEAL:
            return {
                ...state,
                _deleteDealProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_INDIVIDUAL_DEAL:
            return {
                ...state,
                _fetchIndividualDealProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_INDIVIDUAL_DEAL_FAIL:
            return {
                ...state,
                _fetchIndividualDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_INDIVIDUAL_DEAL:
            return {
                ...state,
                _fetchIndividualDealProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_INDIVIDUAL_DEAL:
            return {
                ...state,
                _fetchIndividualDealProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.PAGE_NOT_FOUND:
            return {
                ...state,
                _fetchIndividualDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };

        case CallerConstants.UPDATE_INDIVIDUAL_DEAL:
            toast.success(UPDATE_DEAL_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _updateIndividualDealProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_INDIVIDUAL_DEAL_FAIL:
            return {
                ...state,
                _updateIndividualDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_INDIVIDUAL_DEAL:
            return {
                ...state,
                _updateIndividualDealProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_DEAL:
            return {
                ...state,
                _updateIndividualDealProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_BRIEF_DEAL:
            return {
                ...state,
                reLoad: false,
                _fetchBriefDealProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_BRIEF_DEAL_FAIL:
            return {
                ...state,
                _fetchBriefDealProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_BRIEF_DEAL:
            return {
                ...state,
                _fetchBriefDealProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_BRIEF_DEAL:
            return {
                ...state,
                _fetchBriefDealProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.RELOAD_DEAL: {
            return {
                ...state,
                reLoad: true
            }
        }
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    loader: false,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    loader: false,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}