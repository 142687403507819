import {
  STATE_LIST,
  STATE_LIST_FAIL,
  RESET_STATE_LIST,
  PROCESS_STATE_LIST,
  INVALID_TOKEN,
  RESET_INVALID_TOKEN,
  INVALID_API_URL,
  RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
  _fetchStateProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  }
}
export default function fetchState(state = initialState, action) {
  switch (action.type) {

    case STATE_LIST:
      return {
        ...state,
        _fetchStateProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case STATE_LIST_FAIL:
      return {
        ...state,
        _fetchStateProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case RESET_STATE_LIST:
      return {
        ...state,
        _fetchStateProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case PROCESS_STATE_LIST:
      return {
        ...state,
        _fetchStateProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          data: {}
        }
      }
    case RESET_INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          data: {}
        }
      }
    case INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    case RESET_INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    default:
      return state;
  }
}