import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
    ADD_DEAL_NOTE_MESSAGE,
    UPDATE_DEAL_NOTE_MESSAGE,
    DELETE_DEAL_NOTE_MESSAGE
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';

const initialState = {
    reLoad: false,
    _addDealNoteProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteDealNoteProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchDealAllNoteProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualDealNoteProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateIndividualDealNoteProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchNotesListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
}

export default function notes(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.ADD_DEAL_NOTES:
            toast.success(ADD_DEAL_NOTE_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _addDealNoteProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_DEAL_NOTES_FAIL:
            return {
                ...state,
                _addDealNoteProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_DEAL_NOTES:
            return {
                ...state,
                _addDealNoteProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_DEAL_NOTES:
            return {
                ...state,
                _addDealNoteProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.DELETE_DEAL_NOTES:
            toast.success(DELETE_DEAL_NOTE_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _deleteDealNoteProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                },
            };
        case CallerConstants.DELETE_DEAL_NOTES_FAIL:
            return {
                ...state,
                _deleteDealNoteProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_DEAL_NOTES:
            return {
                ...state,
                _deleteDealNoteProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_DEAL_NOTES:
            return {
                ...state,
                _deleteDealNoteProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_DEAL_ALL_NOTES:
            return {
                ...state,
                _fetchDealAllNoteProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEAL_ALL_NOTES_FAIL:
            return {
                ...state,
                _fetchDealAllNoteProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEAL_ALL_NOTES:
            return {
                ...state,
                _fetchDealAllNoteProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEAL_ALL_NOTES:
            return {
                ...state,
                _fetchDealAllNoteProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_DEAL_INDIVIDUAL_NOTES:
            return {
                ...state,
                _fetchIndividualDealNoteProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEAL_INDIVIDUAL_NOTES_FAIL:
            return {
                ...state,
                _fetchIndividualDealNoteProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEAL_INDIVIDUAL_NOTES:
            return {
                ...state,
                _fetchIndividualDealNoteProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEAL_INDIVIDUAL_NOTES:
            return {
                ...state,
                _fetchIndividualDealNoteProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_DEAL_INDIVIDUAL_NOTES:
            toast.success(UPDATE_DEAL_NOTE_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _updateIndividualDealNoteProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_DEAL_INDIVIDUAL_NOTES_FAIL:
            return {
                ...state,
                _updateIndividualDealNoteProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_DEAL_INDIVIDUAL_NOTES:
            return {
                ...state,
                _updateIndividualDealNoteProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_DEAL_INDIVIDUAL_NOTES:
            return {
                ...state,
                _updateIndividualDealNoteProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_NOTES_LIST:
            return {
                ...state,
                _fetchNotesListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_NOTES_LIST_FAIL:
            return {
                ...state,
                _fetchNotesListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_NOTES_LIST:
            return {
                ...state,
                _fetchNotesListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_NOTES_LIST:
            return {
                ...state,
                _fetchNotesListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    loader: false,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}