import {
  SEND_OTP,
  SEND_OTP_FAIL,
  RESET_SEND_OTP,
  CHECK_OTP,
  CHECK_OTP_FAIL,
  RESET_CHECK_OTP,
  PASSWORD_CHECK_OTP,
  PASSWORD_CHECK_OTP_FAIL,
  RESET_PASSWORD_CHECK_OTP,
  PASSWORD_SEND_OTP,
  PASSWORD_SEND_OTP_FAIL,
  RESET_PASSWORD_SEND_OTP,
  INVALID_API_URL,
  RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
  _sendOTPProcess: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _checkOTPProcess: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _sendPasswordOTPProcess: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _checkPasswordOTPProcess: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  }
};

export default function fetchAllApi(state = initialState, action) {
  switch (action.type) {

    case SEND_OTP:
      return {
        ...state,
        _sendOTPProcess: {
          status: ProcessTypes.SUCCESS,
          data: action.payload
        }
      };
    case SEND_OTP_FAIL:
      return {
        ...state,
        _sendOTPProcess: {
          status: ProcessTypes.FAIL,
          data: action.payload
        }
      };
    case RESET_SEND_OTP:
      return {
        ...state,
        _sendOTPProcess: {
          status: ProcessTypes.IDLE,
          data: {}
        }
      };


    case CHECK_OTP:
      return {
        ...state,
        _checkOTPProcess: {
          status: ProcessTypes.SUCCESS,
          data: action.payload
        },
      };
    case CHECK_OTP_FAIL:
      return {
        ...state,
        _checkOTPProcess: {
          status: ProcessTypes.FAIL,
          data: action.payload
        },
      };
    case RESET_CHECK_OTP: {
      return {
        ...state,
        _checkOTPProcess: {
          status: ProcessTypes.IDLE,
          data: {}
        }
      };
    }
    case PASSWORD_CHECK_OTP:
      return {
        ...state,
        _checkPasswordOTPProcess: {
          status: ProcessTypes.SUCCESS,
          data: action.payload
        },
      };
    case PASSWORD_CHECK_OTP_FAIL:
      return {
        ...state,
        _checkPasswordOTPProcess: {
          status: ProcessTypes.FAIL,
          data: action.payload
        },
      };
    case RESET_PASSWORD_CHECK_OTP: {
      return {
        ...state,
        _checkPasswordOTPProcess: {
          status: ProcessTypes.IDLE,
          data: {}
        }
      };
    }
    case PASSWORD_SEND_OTP:
      return {
        ...state,
        _sendPasswordOTPProcess: {
          status: ProcessTypes.SUCCESS,
          data: action.payload
        }
      };
    case PASSWORD_SEND_OTP_FAIL:
      return {
        ...state,
        _sendPasswordOTPProcess: {
          status: ProcessTypes.FAIL,
          data: action.payload
        }
      };
    case RESET_PASSWORD_SEND_OTP:
      return {
        ...state,
        _sendPasswordOTPProcess: {
          status: ProcessTypes.IDLE,
          data: {}
        }
      };

    case INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    case RESET_INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }

    default:
      return state;
  }
}