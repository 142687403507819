import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    _fetchUserAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserBillingAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchUserShippingAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteUserAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addUserAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateUserAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualUserAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _setAsDefaulteAddressProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    },
};
export default function userAddress(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.FETCH_USER_ADDRESS:
            return {
                ...state,
                _fetchUserAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_ADDRESS_FAIL:
            return {
                ...state,
                _fetchUserAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_ADDRESS:
            return {
                ...state,
                _fetchUserAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_ADDRESS:
            return {
                ...state,
                _fetchUserAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.FETCH_USER_BILLING_ADDRESS:
            return {
                ...state,
                _fetchUserBillingAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_BILLING_ADDRESS_FAIL:
            return {
                ...state,
                _fetchUserBillingAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_BILLING_ADDRESS:
            return {
                ...state,
                _fetchUserBillingAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_BILLING_ADDRESS:
            return {
                ...state,
                _fetchUserBillingAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_USER_SHIPPING_ADDRESS:
            return {
                ...state,
                _fetchUserShippingAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_USER_SHIPPING_ADDRESS_FAIL:
            return {
                ...state,
                _fetchUserShippingAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_USER_SHIPPING_ADDRESS:
            return {
                ...state,
                _fetchUserShippingAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_USER_SHIPPING_ADDRESS:
            return {
                ...state,
                _fetchUserShippingAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.DELETE_USER_ADDRESS:
            return {
                ...state,
                _deleteUserAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.DELETE_USER_ADDRESS_FAIL:
            return {
                ...state,
                _deleteUserAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_USER_ADDRESS:
            return {
                ...state,
                _deleteUserAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_USER_ADDRESS:
            return {
                ...state,
                _deleteUserAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.ADD_USER_ADDRESS:
            return {
                ...state,
                _addUserAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.ADD_USER_ADDRESS_FAIL:
            return {
                ...state,
                _addUserAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_USER_ADDRESS:
            return {
                ...state,
                _addUserAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_USER_ADDRESS:
            return {
                ...state,
                _addUserAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.UPDATE_USER_ADDRESS:
            return {
                ...state,
                _updateUserAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_USER_ADDRESS_FAIL:
            return {
                ...state,
                _updateUserAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_USER_ADDRESS:
            return {
                ...state,
                _updateUserAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_USER_ADDRESS:
            return {
                ...state,
                _updateUserAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_INDIVIDUAL_USER_ADDRESS:
            return {
                ...state,
                _fetchIndividualUserAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_INDIVIDUAL_USER_ADDRESS_FAIL:
            return {
                ...state,
                _fetchIndividualUserAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_INDIVIDUAL_USER_ADDRESS:
            return {
                ...state,
                _fetchIndividualUserAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_INDIVIDUAL_USER_ADDRESS:
            return {
                ...state,
                _fetchIndividualUserAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.SET_AS_DEFAULT_ADDRESS:
            return {
                ...state,
                _setAsDefaulteAddressProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.SET_AS_DEFAULT_ADDRESS_FAIL:
            return {
                ...state,
                _setAsDefaulteAddressProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_SET_AS_DEFAULT_ADDRESS:
            return {
                ...state,
                _setAsDefaulteAddressProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_SET_AS_DEFAULT_ADDRESS:
            return {
                ...state,
                _setAsDefaulteAddressProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        // case INVALID_API_URL:
        //     return {
        //         ...state,
        //         _invalidApiUrl: {
        //             status: ProcessTypes.INVALID_API_URL,
        //             loader: false,
        //             data: {}
        //         }
        //     };
        // case RESET_INVALID_API_URL:
        //     return {
        //         ...state,
        //         _invalidApiUrl: {
        //             status: ProcessTypes.RESET_INVALID_API_URL,
        //             loader: false,
        //             data: {}
        //         }
        //     };
        default:
            return state;
    }
}