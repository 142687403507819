import {
    ADD_FEED_BACK,
    ADD_FEED_BACK_FAIL,
    PROCESS_ADD_FEED_BACK,
    RESET_ADD_FEED_BACK,
    INVALID_API_URL,
    RESET_INVALID_API_URL,
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
    _addFeedBackProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
}
export default function addUserFeedBack(state = initialState, action) {
    switch (action.type) {
        case ADD_FEED_BACK:
            return {
                ...state,
                _addFeedBackProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case ADD_FEED_BACK_FAIL:
            return {
                ...state,
                _addFeedBackProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case RESET_ADD_FEED_BACK:
            return {
                ...state,
                _addFeedBackProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case PROCESS_ADD_FEED_BACK:
            return {
                ...state,
                _addFeedBackProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };

        default:
            return state;
    }
}