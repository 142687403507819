import { combineReducers } from 'redux';
import { LOGOUT } from '../constants/api/CallerConstants';
import user from './auth/User';
import api from './auth/Auth';
import country from './core/Country';
import states from './core/States';
import districts from './core/Districts';
import subDistrict from './core/SubDistricts';
import contacts from './contacts/Contacts';
import companies from './companies/Companies';
import deals from './deals/Deals';
import notes from './notes/Notes';
import assets from './assets/Assets';
import tasks from './tasks/Tasks';
import news from './news/News';
import notices from './notices/Notices';
import types from './core/AllTypes';
import favourites from './core/Favourites';
import feedBack from './core/Feedback';
import poiInfo from './core/PoiInfoType';
import commodity from './commodity/Commodity';
import trade from './trade/Trade';
import ip2Default from './core/Ip2default';
import store from './companiesstore/CompaniesStore';
import APIStatus from './core/APIStatus';
import cart from './cart/Cart';
import userAddress from './useraddress/userAddress';
import geocoding from './core/geocoding';
import order from './order/Order';
import notification from './notification/Notification';

const appReducer = combineReducers({
    user,
    api,
    country,
    states,
    districts,
    subDistrict,
    contacts,
    companies,
    deals,
    notes,
    assets,
    tasks,
    news,
    notices,
    types,
    favourites,
    feedBack,
    poiInfo,
    commodity,
    trade,
    ip2Default,
    store,
    APIStatus,
    cart,
    userAddress,
    geocoding,
    order,
    notification
});
const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;