import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
    ADD_DEAL_TASK_MESSAGE,
    UPDATE_DEAL_TASK_MESSAGE,
    DELETE_DEAL_TASK_MESSAGE,
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';

const initialState = {
    reLoad: false,
    _addDealTasksProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteDealTasksProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchDealAllTasksProcess:
    {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualDealTasksProcess:
    {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateIndividualDealTasksProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchTasksListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
}

export default function tasks(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.ADD_DEAL_TASKS:
        toast.success(ADD_DEAL_TASK_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _addDealTasksProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                },
            };
        case CallerConstants.ADD_DEAL_TASKS_FAIL:
            return {
                ...state,
                _addDealTasksProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_ADD_DEAL_TASKS:
            return {
                ...state,
                _addDealTasksProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_ADD_DEAL_TASKS:
            return {
                ...state,
                _addDealTasksProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.DELETE_DEAL_TASKS:
        toast.success(DELETE_DEAL_TASK_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _deleteDealTasksProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                },
            };
        case CallerConstants.DELETE_DEAL_TASKS_FAIL:
            return {
                ...state,
                _deleteDealTasksProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_DELETE_DEAL_TASKS:
            return {
                ...state,
                _deleteDealTasksProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_DELETE_DEAL_TASKS:
            return {
                ...state,
                _deleteDealTasksProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_DEAL_ALL_TASKS:
            return {
                ...state,
                _fetchDealAllTasksProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEAL_ALL_TASKS_FAIL:
            return {
                ...state,
                _fetchDealAllTasksProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEAL_ALL_TASKS:
            return {
                ...state,
                _fetchDealAllTasksProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEAL_ALL_TASKS:
            return {
                ...state,
                _fetchDealAllTasksProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_DEAL_INDIVIDUAL_TASKS:
            return {
                ...state,
                _fetchIndividualDealTasksProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_DEAL_INDIVIDUAL_TASKS_FAIL:
            return {
                ...state,
                _fetchIndividualDealTasksProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_DEAL_INDIVIDUAL_TASKS:
            return {
                ...state,
                _fetchIndividualDealTasksProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_DEAL_INDIVIDUAL_TASKS:
            return {
                ...state,
                _fetchIndividualDealTasksProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.UPDATE_DEAL_INDIVIDUAL_TASKS:
        toast.success(UPDATE_DEAL_TASK_MESSAGE);
            return {
                ...state,
                reLoad: true,
                _updateIndividualDealTasksProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.UPDATE_DEAL_INDIVIDUAL_TASKS_FAIL:
            return {
                ...state,
                _updateIndividualDealTasksProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_UPDATE_DEAL_INDIVIDUAL_TASKS:
            return {
                ...state,
                _updateIndividualDealTasksProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_UPDATE_DEAL_INDIVIDUAL_TASKS:
            return {
                ...state,
                _updateIndividualDealTasksProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };

        case CallerConstants.FETCH_TASKS_LIST:
            return {
                ...state,
                _fetchTasksListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.FETCH_TASKS_LIST_FAIL:
            return {
                ...state,
                _fetchTasksListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        case CallerConstants.RESET_FETCH_TASKS_LIST:
            return {
                ...state,
                _fetchTasksListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.PROCESS_FETCH_TASKS_LIST:
            return {
                ...state,
                _fetchTasksListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}