import {
  COUNTRY_LIST,
  COUNTRY_LIST_FAIL,
  PROCESS_COUNTRY_LIST,
  RESET_COUNTRY_LIST,
  INVALID_TOKEN,
  RESET_INVALID_TOKEN,
  INVALID_API_URL,
  RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
const initialState = {
  _fetchCountryProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  }
}

export default function fetchCountry(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_LIST:
      return {
        ...state,
        _fetchCountryProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case COUNTRY_LIST_FAIL:
      return {
        ...state,
        _fetchCountryProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case RESET_COUNTRY_LIST:
      return {
        ...state,
        _fetchCountryProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case PROCESS_COUNTRY_LIST:
      return {
        ...state,
        _fetchCountryProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          data: {}
        }
      }
    case RESET_INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          data: {}
        }
      }
    case INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    case RESET_INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    default:
      return state;
  }
}