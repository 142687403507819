import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import CacheBuster from './Cachesbuster';
const DefaultLayout = React.lazy(() => import('./components/defaultlayout/DefaultLayout'));
const GlobalFonts = React.lazy(() => import('./fonts/fonts'));
class App extends Component {
	render() {
		return (
			<>
				<CacheBuster>
					{({ loading, isLatestVersion, refreshCacheAndReload }) => {
						if (loading) return null;
						if (!loading && !isLatestVersion) {
							refreshCacheAndReload();
						}
						return (
							<Router>
								<React.Suspense fallback={<div className='bg-white' />}>
									<GlobalFonts />
									<Switch>
										<Route path='/' render={props => <DefaultLayout {...props} />} />
									</Switch>
								</React.Suspense>
							</Router>
						);
					}}
				</CacheBuster >
				<ToastContainer position='top-right' autoClose={2500} hideProgressBar={true} />
			</>
		);
	}
}

export default App;