import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
  UPDATE_COMPANY_MESSAGE,
  ADD_COMPANY_MESSAGE,
  DELETE_COMPANY_IMAGE_MESSAGE,
  DELETE_COMPANY_MESSAGE
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';

const initialState = {
  reLoad: false,
  _addCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchAllCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchBriefCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchIndividualCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateIndividualCompanyProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchCompaniesMinProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _addCompanyProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteCompanyProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  }
}

export default function Company(state = initialState, action) {
  switch (action.type) {
    case CallerConstants.ADD_COMPANY:
      toast.success(ADD_COMPANY_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _addCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.ADD_COMPANY_FAIL:
      return {
        ...state,
        _addCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_ADD_COMPANY:
      return {
        ...state,
        _addCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_ADD_COMPANY:
      return {
        ...state,
        _addCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_ALL_COMPANY:
      return {
        ...state,
        _fetchAllCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_ALL_COMPANY_FAIL:
      return {
        ...state,
        _fetchAllCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_ALL_COMPANY:
      return {
        ...state,
        _fetchAllCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_ALL_COMPANY:
      return {
        ...state,
        _fetchAllCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_BRIEF_COMPANY:
      return {
        ...state,
        reLoad: false,
        _fetchBriefCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_BRIEF_COMPANY_FAIL:
      return {
        ...state,
        _fetchBriefCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_BRIEF_COMPANY:
      return {
        ...state,
        _fetchBriefCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_BRIEF_COMPANY:
      return {
        ...state,
        _fetchBriefCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.DELETE_COMPANY:
      toast.success(DELETE_COMPANY_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _deleteCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        },
      };
    case CallerConstants.DELETE_COMPANY_FAIL:
      return {
        ...state,
        _deleteCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_COMPANY:
      return {
        ...state,
        _deleteCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_DELETE_COMPANY:
      return {
        ...state,
        _deleteCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.FETCH_INDIVIDUAL_COMPANY:
      return {
        ...state,
        _fetchIndividualCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_INDIVIDUAL_COMPANY_FAIL:
      return {
        ...state,
        _fetchIndividualCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_INDIVIDUAL_COMPANY:
      return {
        ...state,
        _fetchIndividualCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_INDIVIDUAL_COMPANY:
      return {
        ...state,
        _fetchIndividualCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.PAGE_NOT_FOUND: {
      return {
        ...state,
        _fetchIndividualCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    }
    case CallerConstants.UPDATE_INDIVIDUAL_COMPANY:
      toast.success(UPDATE_COMPANY_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _updateIndividualCompanyProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_INDIVIDUAL_COMPANY_FAIL:
      return {
        ...state,
        _updateIndividualCompanyProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPDATE_INDIVIDUAL_COMPANY:
      return {
        ...state,
        _updateIndividualCompanyProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_COMPANY:
      return {
        ...state,
        _updateIndividualCompanyProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_COMPANIES_MIN:
      return {
        ...state,
        _fetchCompaniesMinProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_COMPANIES_MIN_FAIL:
      return {
        ...state,
        _fetchCompaniesMinProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_COMPANIES_MIN:
      return {
        ...state,
        _fetchCompaniesMinProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_COMPANIES_MIN:
      return {
        ...state,
        _fetchCompaniesMinProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.UPLOAD_COMPANY_PROFILE_IMAGE:
      return {
        ...state,
        _addCompanyProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPLOAD_COMPANY_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _addCompanyProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPLOAD_COMPANY_PROFILE_IMAGE:
      return {
        ...state,
        _addCompanyProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };

    case CallerConstants.PROCESS_ADD_COMPANY_IMAGE:
      return {
        ...state,
        _addCompanyProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.DELETE_COMPANY_PROFILE_IMAGE:
      toast.success(DELETE_COMPANY_IMAGE_MESSAGE, { icon: false });
      return {
        reLoad: true,
        ...state,
        _deleteCompanyProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.DELETE_COMPANY_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _deleteCompanyProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_COMPANY_PROFILE_IMAGE:
      return {
        ...state,
        _deleteCompanyProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_DELETE_COMPANY_PROFILE_IMAGE:
      return {
        ...state,
        _deleteCompanyProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          loader: false,
          data: {}
        }
      }
    }
    case CallerConstants.RESET_INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          loader: false,
          data: {}
        }
      }
    }
    case CallerConstants.INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    case CallerConstants.RESET_INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    default:
      return state;
  }
}