import {
	POI_INFO_LIST,
	POI_INFO_LIST_FAIL,
	RESET_POI_INFO_LIST,
	PROCESS_POI_INFO_LIST,
	INVALID_API_URL,
	RESET_INVALID_API_URL
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
	_fetchPoiInfoProcess: {
		status: ProcessTypes.IDLE,
		loader: false,
		data: {}
	},
	_invalidApiUrl: {
		status: ProcessTypes.IDLE,
		data: {}
	}
};

export default function fetchPoiInfoType(state = initialState, action) {
	switch (action.type) {
		case POI_INFO_LIST:
			return {
				...state,
				_fetchPoiInfoProcess: {
					status: ProcessTypes.SUCCESS,
					loader: false,
					data: action.payload
				}
			};
		case POI_INFO_LIST_FAIL:
			return {
				...state,
				_fetchPoiInfoProcess: {
					status: ProcessTypes.FAIL,
					loader: false,
					data: action.payload
				}
			};
		case RESET_POI_INFO_LIST:
			return {
				...state,
				_fetchPoiInfoProcess: {
					status: ProcessTypes.IDLE,
					loader: false,
					data: {}
				}
			};
		case PROCESS_POI_INFO_LIST:
			return {
				...state,
				_fetchPoiInfoProcess: {
					status: ProcessTypes.PROCESSING,
					loader: true,
					data: {}
				}
			};
		case INVALID_API_URL:
			return {
				...state,
				_invalidApiUrl: {
					status: ProcessTypes.INVALID_API_URL,
					loader: false,
					data: {}
				}
			};
		case RESET_INVALID_API_URL:
			return {
				...state,
				_invalidApiUrl: {
					status: ProcessTypes.RESET_INVALID_API_URL,
					loader: false,
					data: {}
				}
			};
		default:
			return state;
	}
}