import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    _registarionForNotification: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deRegistarionForNotification: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
};

export default function Notification(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.REGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _registarionForNotification: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.REGISTER_FOR_NOTIFICATION_FAIL: {
            return {
                ...state,
                _registarionForNotification: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_REGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _registarionForNotification: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_REGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _registarionForNotification: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.DEREGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _deRegistarionForNotification: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.DEREGISTER_FOR_NOTIFICATION_FAIL: {
            return {
                ...state,
                _deRegistarionForNotification: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_DEREGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _deRegistarionForNotification: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_DEREGISTER_FOR_NOTIFICATION: {
            return {
                ...state,
                _deRegistarionForNotification: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}