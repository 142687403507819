import {
  FAVOURITE,
  FAVOURITE_FAIL,
  PROCESS_FAVOURITE,
  RESET_FAVOURITE,
  INVALID_API_URL,
  RESET_INVALID_API_URL,
  INVALID_TOKEN,
  RESET_INVALID_TOKEN
} from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
  reLoad: false,
  _setFavouritesProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  }
}

export default function setFavourites(state = initialState, action) {
  switch (action.type) {
    case FAVOURITE:
      return {
        ...state,
        _setFavouritesProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case FAVOURITE_FAIL:
      return {
        ...state,
        _setFavouritesProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case RESET_FAVOURITE:
      return {
        ...state,
        _setFavouritesProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case PROCESS_FAVOURITE:
      return {
        ...state,
        _setFavouritesProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          data: {}
        }
      }
    case RESET_INVALID_TOKEN:
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          data: {}
        }
      }
    case INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    case RESET_INVALID_API_URL:
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    default:
      return state;
  }
}