import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';

const initialState = {
    reLoad: false,
    reLoadFavouriteNews: false,
    _fetchNewsListProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchIndividualNewsProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchNewsCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _addNewsCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _deleteNewsCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _updateNewsCommentProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _fetchFavouriteNewsProcess: {
        status: ProcessTypes.IDLE,
        loader: false,
        data: {}
    },
    _invalidToken: {
        status: ProcessTypes.IDLE,
        data: {}
    },
    _invalidApiUrl: {
        status: ProcessTypes.IDLE,
        data: {}
    }
};

export default function fetchNews(state = initialState, action) {
    switch (action.type) {
        case CallerConstants.FETCH_NEWS_LIST: {
            return {
                ...state,
                _fetchNewsListProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_NEWS_LIST_FAIL: {
            return {
                ...state,
                _fetchNewsListProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_NEWS_LIST: {
            return {
                ...state,
                _fetchNewsListProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_NEWS_LIST:
            return {
                ...state,
                _fetchNewsListProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.UPDATE_FAVOURITE_NEWS:
            if (state._fetchNewsListProcess.data.results !== undefined)
                state._fetchNewsListProcess.data.results.map(data => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        if (action.payload.response === 204) {
                            data.starred = false;
                        }
                        else if (action.payload.response === 200) {
                            data.starred = true;
                        }
                    }
                    return null
                })
            if (state._fetchFavouriteNewsProcess.data.results !== undefined)
                state._fetchFavouriteNewsProcess.data.results.map((data, index) => {
                    if (data.id.toString() === action.payload.id.toString()) {
                        state._fetchFavouriteNewsProcess.data.results.splice(index, 1);
                    }
                    return null
                })
            return {
                ...state
            };
        case CallerConstants.FETCH_INDIVIDUAL_NEWS: {
            return {
                ...state,
                _fetchIndividualNewsProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_INDIVIDUAL_NEWS_FAIL: {
            return {
                ...state,
                _fetchIndividualNewsProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_INDIVIDUAL_NEWS: {
            return {
                ...state,
                _fetchIndividualNewsProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_INDIVIDUAL_NEWS: {
            return {
                ...state,
                _fetchIndividualNewsProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.UPDATE_FAVOURITE_INDIVIDUAL_NEWS:
            if (state._fetchIndividualNewsProcess.data !== undefined)
                if (state._fetchIndividualNewsProcess.data.id.toString() === action.payload.id.toString()) {
                    if (action.payload.response === 204) {
                        state._fetchIndividualNewsProcess.data.starred = false;
                        state._fetchIndividualNewsProcess.data.stars = state._fetchIndividualNewsProcess.data.stars - 1;
                    }
                    else if (action.payload.response === 200) {
                        state._fetchIndividualNewsProcess.data.starred = true;
                        state._fetchIndividualNewsProcess.data.stars = state._fetchIndividualNewsProcess.data.stars + 1;
                    }
                }
            return {
                ...state
            };
        case CallerConstants.PAGE_NOT_FOUND: {
            return {
                ...state,
                _fetchIndividualNewsProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_NEWS_COMMENTS_LIST: {
            return {
                ...state,
                reLoad: false,
                _fetchNewsCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FETCH_NEWS_COMMENTS_LIST_FAIL: {
            return {
                ...state,
                _fetchNewsCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FETCH_NEWS_COMMENTS_LIST: {
            return {
                ...state,
                _fetchNewsCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_FETCH_NEWS_COMMENTS_LIST: {
            return {
                ...state,
                _fetchNewsCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.ADD_NEWS_COMMENTS: {
            state._fetchIndividualNewsProcess.data.comments = state._fetchIndividualNewsProcess.data.comments + 1;
            return {
                ...state,
                reLoad: true,
                _addNewsCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.ADD_NEWS_COMMENTS_FAIL: {
            return {
                ...state,
                _addNewsCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_ADD_NEWS_COMMENTS: {
            return {
                ...state,
                _addNewsCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_ADD_NEWS_COMMENTS: {
            return {
                ...state,
                _addNewsCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.DELETE_NEWS_COMMENTS: {
            state._fetchIndividualNewsProcess.data.comments = state._fetchIndividualNewsProcess.data.comments - 1;
            return {
                ...state,
                reLoad: true,
                _deleteNewsCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.DELETE_NEWS_COMMENTS_FAIL: {
            return {
                ...state,
                _deleteNewsCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_DELETE_NEWS_COMMENTS: {
            return {
                ...state,
                _deleteNewsCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_DELETE_NEWS_COMMENTS: {
            return {
                ...state,
                _deleteNewsCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.UPDATE_NEWS_COMMENTS: {
            return {
                ...state,
                reLoad: true,
                _updateNewsCommentProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.UPDATE_NEWS_COMMENTS_FAIL: {
            return {
                ...state,
                _updateNewsCommentProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_UPDATE_NEWS_COMMENTS: {
            return {
                ...state,
                _updateNewsCommentProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: {}
                }
            };
        }
        case CallerConstants.PROCESS_UPDATE_NEWS_COMMENTS: {
            return {
                ...state,
                _updateNewsCommentProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        }
        case CallerConstants.FAVOURITE_NEWS_SEARCH: {
            return {
                ...state,
                reLoadFavouriteNews: false,
                _fetchFavouriteNewsProcess: {
                    status: ProcessTypes.SUCCESS,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.FAVOURITE_NEWS_SEARCH_FAIL: {
            return {
                ...state,
                _fetchFavouriteNewsProcess: {
                    status: ProcessTypes.FAIL,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.RESET_FAVOURITE_NEWS_SEARCH: {
            return {
                ...state,
                _fetchFavouriteNewsProcess: {
                    status: ProcessTypes.IDLE,
                    loader: false,
                    data: action.payload
                }
            };
        }
        case CallerConstants.PROCESS_FAVOURITE_NEWS_SEARCH:
            return {
                ...state,
                _fetchFavouriteNewsProcess: {
                    status: ProcessTypes.PROCESSING,
                    loader: true,
                    data: {}
                }
            };
        case CallerConstants.RELOAD_FAVOURITE_NEWS: {
            return {
                ...state,
                reLoadFavouriteNews: true
            }
        }
        case CallerConstants.INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.RESET_INVALID_TOKEN:
            return {
                ...state,
                _invalidToken: {
                    status: ProcessTypes.RESET_INVALID_TOKEN,
                    data: {}
                }
            }
        case CallerConstants.INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        case CallerConstants.RESET_INVALID_API_URL:
            return {
                ...state,
                _invalidApiUrl: {
                    status: ProcessTypes.RESET_INVALID_API_URL,
                    loader: false,
                    data: {}
                }
            };
        default:
            return state;
    }
}