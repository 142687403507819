import * as CallerConstants from '../../constants/api/CallerConstants';
import * as ProcessTypes from '../../constants/api/ProcessTypes';
import {
  UPDATE_CONTACT_MESSAGE,
  ADD_CONTACT_MESSAGE,
  DELETE_CONTACT_IMAGE_MESSAGE,
  DELETE_CONTACT_MESSAGE
} from '../../constants/ToastConstant';
import { toast } from 'react-toastify';
const initialState = {
  reLoad: false,
  _addContactsProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchBriefContactsProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteContactsProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchIndividualContactProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _updateIndividualContactProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchContactsMinProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _addContactProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _deleteContactProfileImage: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _fetchIndividualContactTimeLineProcess: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidToken: {
    status: ProcessTypes.IDLE,
    loader: false,
    data: {}
  },
  _invalidApiUrl: {
    status: ProcessTypes.IDLE,
    data: {}
  },
}

export default function addContacts(state = initialState, action) {
  switch (action.type) {
    case CallerConstants.ADD_CONTACTS:
      toast.success(ADD_CONTACT_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _addContactsProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.ADD_CONTACTS_FAIL:
      return {
        ...state,
        _addContactsProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_ADD_CONTACTS:
      return {
        ...state,
        _addContactsProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };

    case CallerConstants.PROCESS_ADD_CONTACT:
      return {
        ...state,
        _addContactsProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_ALL_CONTACTS:
      return {
        ...state,
        _fetchAllContactsProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_ALL_CONTACTS_FAIL:
      return {
        ...state,
        _fetchAllContactsProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_ALL_CONTACTS:
      return {
        ...state,
        _fetchAllContactsProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_ALL_CONTACTS:
      return {
        ...state,
        _fetchAllContactsProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_BRIEF_CONTACTS:

      return {
        ...state,
        reLoad: false,
        _fetchBriefContactsProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_BRIEF_CONTACTS_FAIL:
      return {
        ...state,
        _fetchBriefContactsProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_BRIEF_CONTACTS:
      return {
        ...state,
        _fetchBriefContactsProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_BRIEF_CONTACTS:
      return {
        ...state,
        _fetchBriefContactsProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.DELETE_CONTACT:
      toast.success(DELETE_CONTACT_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _deleteContactsProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.DELETE_CONTACT_FAIL:
      return {
        ...state,
        _deleteContactsProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_CONTACT:
      return {
        ...state,
        _deleteContactsProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_DELETE_CONTACT:
      return {
        ...state,
        _deleteContactsProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_INDIVIDUAL_CONTACT:
      return {
        ...state,
        _fetchIndividualContactProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_INDIVIDUAL_CONTACT_FAIL:
      return {
        ...state,
        _fetchIndividualContactProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_INDIVIDUAL_CONTACT:
      return {
        ...state,
        _fetchIndividualContactProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_INDIVIDUAL_CONTACT:
      return {
        ...state,
        _fetchIndividualContactProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.PAGE_NOT_FOUND: {
      return {
        ...state,
        _fetchIndividualContactProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    }
    case CallerConstants.UPDATE_INDIVIDUAL_CONTACT:
      toast.success(UPDATE_CONTACT_MESSAGE, { icon: false });
      return {
        ...state,
        reLoad: true,
        _updateIndividualContactProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPDATE_INDIVIDUAL_CONTACT_FAIL:
      return {
        ...state,
        _updateIndividualContactProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPDATE_INDIVIDUAL_CONTACT:
      return {
        ...state,
        _updateIndividualContactProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_UPDATE_INDIVIDUAL_CONTACT:
      return {
        ...state,
        _updateIndividualContactProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_CONTACTS_MIN:
      return {
        ...state,
        _fetchContactsMinProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_CONTACTS_MIN_FAIL:
      return {
        ...state,
        _fetchContactsMinProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_CONTACTS_MIN:
      return {
        ...state,
        _fetchContactsMinProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_CONTACTS_MIN:
      return {
        ...state,
        _fetchContactsMinProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.UPLOAD_CONTACT_PROFILE_IMAGE:
      // toast.success(ADD_CONTACT_IMAGE_MESSAGE);
      return {
        ...state,
        _addContactProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.UPLOAD_CONTACT_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _addContactProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_UPLOAD_CONTACT_PROFILE_IMAGE:
      return {
        ...state,
        _addContactProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_ADD_CONTACT_IMAGE:
      return {
        ...state,
        _addContactProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.DELETE_CONTACT_PROFILE_IMAGE:
      toast.success(DELETE_CONTACT_IMAGE_MESSAGE, { icon: false });
      return {
        reLoad: true,
        ...state,
        _deleteContactProfileImage: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.DELETE_CONTACT_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        _deleteContactProfileImage: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_DELETE_CONTACT_PROFILE_IMAGE:
      return {
        ...state,
        _deleteContactProfileImage: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_DELETE_CONTACT_PROFILE_IMAGE:
      return {
        ...state,
        _deleteContactProfileImage: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };

    case CallerConstants.FETCH_INDIVIDUAL_CONTACT_TIMELINE:
      return {
        ...state,
        _fetchIndividualContactTimeLineProcess: {
          status: ProcessTypes.SUCCESS,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.FETCH_INDIVIDUAL_CONTACT_TIMELINE_FAIL:
      return {
        ...state,
        _fetchIndividualContactTimeLineProcess: {
          status: ProcessTypes.FAIL,
          loader: false,
          data: action.payload
        }
      };
    case CallerConstants.RESET_FETCH_INDIVIDUAL_CONTACT_TIMELINE:
      return {
        ...state,
        _fetchIndividualContactTimeLineProcess: {
          status: ProcessTypes.IDLE,
          loader: false,
          data: {}
        }
      };
    case CallerConstants.PROCESS_FETCH_INDIVIDUAL_CONTACT_TIMELINE:
      return {
        ...state,
        _fetchIndividualContactTimeLineProcess: {
          status: ProcessTypes.PROCESSING,
          loader: true,
          data: {}
        }
      };
    case CallerConstants.RELOAD_CONTACT: {
      return {
        ...state,
        reLoad: true
      }
    }
    case CallerConstants.INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.INVALID_TOKEN,
          data: {}
        }
      }
    }
    case CallerConstants.RESET_INVALID_TOKEN: {
      return {
        ...state,
        _invalidToken: {
          status: ProcessTypes.RESET_INVALID_TOKEN,
          data: {}
        }
      }
    }
    case CallerConstants.INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    case CallerConstants.RESET_INVALID_API_URL: {
      return {
        ...state,
        _invalidApiUrl: {
          status: ProcessTypes.RESET_INVALID_API_URL,
          loader: false,
          data: {}
        }
      };
    }
    default:
      return state;
  }
}